const Fuse = require("fuse.js").default

exports.Search = (query, responses) => {
  if (responses) {
    let results = []
    if (query.indexOf("#") == 0) {
      // Looking for tagged answers
      responses.forEach(row => {
        row.tags.forEach(function (tag) {
          if (tag.name == query.replace("#", "")) {
            results[row.id] = {
              score: 1,
              answer: row,
            }
          }
        })
      })
    } else {
      let words = query.split(" ")
      let resultIds = []
      // Fuzzy search
      const options = {
        includeScore: true,
        ignoreLocation: true,
        includeMatches: true,
        findAllMatches: true,
        keys: [
          { name: "shortcut", weight: 2 },
          { name: "name", weight: 2 },
          { name: "variants.full_text", weight: 2 },
          { name: "folders.name", weight: 1 },
        ],
      }

      if (words.length > 1) {
        words.push(query)
      }

      words.forEach(word => {
        word = word.toString().toLowerCase()
        responses.forEach(response => {
          if (new RegExp("\\b" + word + "\\b").test(response.name.toString().toLowerCase())) {
            if (resultIds.indexOf(response.uuid) >= 0) {
              results.forEach(result => {
                if (result.response.uuid == response.uuid) {
                  result.score += word.split(" ").length * word.split(" ").length * word.split(" ").length * 8
                }
              })
            } else {
              results.push({
                score: word.split(" ").length * word.split(" ").length * word.split(" ").length * 8,
                response: response,
              })
              resultIds.push(response.uuid)
            }
          }
          if (response.folder) {
            if (
              response.folder.name
                .toString()
                .toLowerCase()
                .indexOf(word) >= 0
            ) {
              if (resultIds.indexOf(response.uuid) >= 0) {
                results.forEach(result => {
                  if (result.response.uuid == response.uuid) {
                    result.score += 1
                  }
                })
                //results[response.uuid].score += 1;
              } else {
                results.push({
                  score: 1,
                  response: response,
                })
                resultIds.push(response.uuid)
              }
            }
          }
          if (response.shortcut) {
            let perfectMatch = response.shortcut.toString().toLowerCase() == word
            let match =
              response.shortcut
                .toString()
                .toLowerCase()
                .indexOf(word) >= 0
            if (perfectMatch || match) {
              if (resultIds.indexOf(response.uuid) >= 0) {
                results.forEach(result => {
                  if (result.response.uuid == response.uuid) {
                    result.score += perfectMatch ? 200 : 1.5
                  }
                })
                //results[response.uuid].score += 1;
              } else {
                results.push({
                  score: perfectMatch ? 200 : 1.5,
                  response: response,
                })
                resultIds.push(response.uuid)
              }
            }
          }
          response.variants.forEach(variant => {
            if (variant.full_text) {
              let match =
                variant.full_text
                  .toString()
                  .toLowerCase()
                  .indexOf(word) >= 0

              if (match) {
                if (resultIds.indexOf(response.uuid) >= 0) {
                  results.forEach(result => {
                    if (result.response.uuid == response.uuid) {
                      result.score += word.split(" ").length * word.split(" ").length
                    }
                  })
                  //results[response.uuid].score += 1;
                } else {
                  results.push({
                    score: word.split(" ").length * word.split(" ").length,
                    response: response,
                  })
                  resultIds.push(response.uuid)
                }
              }
            }
          })
        })
      })

      const fuse = new Fuse(responses, options)
      const fuseResult = fuse.search(query)

      fuseResult.forEach(result => {
        if (result.score < 0.4) {
          if (resultIds.indexOf(result.item.uuid) >= 0) {
            results.forEach(row => {
              if (result.item.uuid == row.uuid) {
                row.score *= 1 - result.score
              }
            })
          } else {
            results.push({
              score: 1 - result.score,
              response: result.item,
            })
          }
        }
      })
    }

    results.sort((a, b) => {
      return b.score - a.score
    })

    return results.slice(0, 10)
  }
  return []
}
