<template>
  <div class="editresponse bg-body-bg vh-100">
    <b-modal
      @ok="back(true)"
      @cancel="$bvModal.hide('confirm')"
      cancel-variant="background rounded-sm px-4"
      ok-variant="danger rounded-sm px-4"
      footer-border-variant="0"
      hide-header
      id="confirm"
      cancel-title="Stay here"
      ok-title="Leave"
    >
      <template #modal-header-close>
        <b-icon icon="x"></b-icon>
      </template>
      <div class="px-4 pt-5 text-center">
        <h3>Are you sure you want to leave?</h3>
        You have unsaved changes that will <b>not be saved</b> if you leave.
      </div>
    </b-modal>
    <transition name="slideup">
      <!-- <div v-if="isDirty" class="p-2 text-white rounded unsaved bg-warning d-flex align-items-center justify-content-center">You have unsaved changes</div> -->
      <b-toast id="savechanges" toaster="b-toaster-top-right" variant="warning" auto-hide-delay="5000" no-close-button solid>
        <template #toast-title></template>
        You have unsaved changes
      </b-toast>
    </transition>
    <section class="p-4 navbar d-flex align-items-center justify-content-between border-bottom border-border" :class="{ 'mt-3': this.$root.isElectron() && this.$root.isMac() }">
      <b-button @click="back()" class="px-1 py-0 mr-3" variant="hover">
        <b-icon icon="arrow-left"></b-icon>
      </b-button>
      <b-button :disabled="loading" @click="save" class="flex-shrink-0 mr-3 h-100" variant="primary" :class="{ 'px-6': loading, 'px-3': !loading }">
        <img v-show="loading" class="loader" src="@/common/assets/img/loader_white.svg" width="13" alt="" />
        <span v-show="!loading">Save Response</span>
      </b-button>
      <b-input autofocus class="name border-0 p-2 px-3/Users/michel/typedesk/typedesk-native-v3/dist/index.html mr-3" v-model="name" placeholder="Canned Response Name" type="text"></b-input>
      <b-dropdown v-if="!uuid || is_owned" ref="folderDropdown" boundary="window" right variant="hover" class="h-100" toggle-class="px-3 mr-3 h-100 text-decoration-none" no-caret>
        <template #button-content>
          <span class="d-flex align-items-center">
            <b-icon class="mr-2" icon="folder"></b-icon>
            <span v-if="folder">{{ folder.name }}</span>
            <span v-else>Select a folder</span>
          </span>
        </template>
        <b-dropdown-item v-for="folder in dropdownFolders" :key="folder.uuid" @click="selectFolder(folder)"
          ><span class="d-flex align-items-center">
            {{ folder.name }}
            <span v-if="folder.team" class="ml-2 badge badge-primary-light">Team</span>
          </span></b-dropdown-item
        >
        <b-dropdown-divider v-if="$store.state.folders && $store.state.folders.length"></b-dropdown-divider>
        <b-dropdown-form>
          <section v-show="showFolderInput">
            <b-input
              v-show="showFolderInput"
              v-model="newFolderName"
              @keydown.stop.prevent.esc="showFolderInput = false"
              ref="folderinput"
              class="my-2"
              @keydown.enter.stop.prevent="createFolder()"
              placeholder="Folder name"
            ></b-input>
            <b-button variant="link text-decoration-none" class="px-0" @click="createFolder()"
              ><span class="d-flex align-items-center">
                <b-icon class="mr-2" icon="arrow-return-left"></b-icon>
                Save - Press Enter</span
              ></b-button
            >
          </section>
          <section v-show="!showFolderInput">
            <b-button variant="link text-decoration-none" class="px-0" @click="showFolderInput = true"
              ><span class="d-flex align-items-center">
                <b-icon class="mr-2" icon="folder-plus"></b-icon>
                Create folder</span
              ></b-button
            >
          </section>
        </b-dropdown-form>
      </b-dropdown>
    </section>
    <div class="editor-wrapper">
      <div class="overflow-hidden h-100 flex-grow-1">
        <section class="editor-editable">
          <Editor :key="currentVariant.temp_id" :currentValue="currentValue" @change="change(currentVariant.temp_id, $event)" />
        </section>
      </div>
      <section class="sidebar">
        <Sidebar
          :variants="variants"
          :plainText="plainText"
          @addVariant="createVariant"
          @selectVariant="selectVariant"
          @updateVariantName="updateVariantName"
          @updateVariants="updateVariants"
          @duplicateVariant="duplicateVariant"
          @deleteVariant="deleteVariant"
          @setPlainText="plainText = $event"
          @setPrivateNote="privateNote = $event"
          @changeShortcut="shortcut = $event"
          :shortcut="shortcut"
          :privateNote="privateNote"
          :uuid="uuid"
        />
      </section>
    </div>
    <!-- <b-alert v-model="showError" class="m-0 position-fixed fixed-bottom rounded-0" style="z-index: 2000" variant="warning" dismissible fade>
      {{ error }}
      <template #dismiss>
        <b-icon icon="x"></b-icon>
      </template>
    </b-alert> -->
  </div>
</template>

<script>
import Editor from "@/common/components/Editor/Editor.vue"
import Sidebar from "@/common/components/Editor/Sidebar.vue"
import { post } from "@/common/api"
import { v4 as uuidv4 } from "uuid"
let originalHash
export default {
  data() {
    return {
      showFolderInput: false,
      newFolderName: null,
      uuid: null,
      name: null,
      folder: null,
      plainText: false,
      privateNote: null,
      shortcut: null,
      is_owned: false,
      isDirty: false,
      currentValue: null,
      variants: [],
      loading: false,
    }
  },
  components: {
    Editor,
    Sidebar,
  },
  computed: {
    currentVariant() {
      return this.variants.find(v => {
        return v.selected
      })
    },
    dropdownFolders() {
      let folders = this.$store.state.folders.owned.concat(this.$store.state.folders.team)
      this.$store.state.folders.shared.forEach(f => {
        if (f.users.some(e => e.uuid == this.$store.state.user.uuid && (e.pivot.role == "editor" || e.pivot.role == "admin"))) {
          folders.push(f)
        }
      })
      return folders
    },
  },
  watch: {
    showFolderInput(newValue) {
      if (newValue == true) {
        this.$nextTick(() => {
          this.$refs.folderinput.focus()
        })
      }
    },
  },
  methods: {
    selectFolder(folder) {
      this.folder = folder
    },
    createFolder() {
      this.folder = {
        name: this.newFolderName,
      }
      this.showFolderInput = false
      this.$refs.folderDropdown.hide()
    },
    back(force) {
      let response = null
      if (this.uuid) {
        response = this.uuid
      }
      // if (this.folder) {
      //   this.$router.push({
      //     name: "Folder",
      //     params: {
      //       uuid: this.folder.uuid,
      //       response,
      //     },
      //   })
      // } else {
      if (!this.isDirty || force) {
        if (window.from.name == "Folder") {
          this.$router.push({
            name: "Folder",
            params: {
              uuid: window.from.params.uuid,
              response,
            },
          })
        } else {
          this.$router.push({
            name: "Responses",
            params: {
              response,
            },
          })
        }
      } else {
        this.$bvModal.show("confirm")
      }
      // }
    },
    selectVariant(variant) {
      this.variants.map(row => {
        row.selected = false
        if (Object.is(row, variant)) {
          row.selected = true
          this.currentValue = row.content
        }
      })
    },
    change(id, e) {
      this.variants.map(row => {
        if (row.temp_id == id) {
          row.content = e
        }
      })
    },
    createVariant() {
      const variant = {
        name: "Variant " + (this.variants.length + 1),
        selected: false,
        content: JSON.stringify([{ type: "paragraph", children: [{ text: "" }] }]),
        temp_id: uuidv4(),
      }
      this.variants.push(variant)
      this.selectVariant(variant)
    },
    duplicateVariant(variant) {
      let variantCopy = JSON.parse(JSON.stringify(variant))
      variantCopy.temp_id = uuidv4()
      variantCopy.name += " (copy)"
      this.variants.push(variantCopy)
      this.selectVariant(variantCopy)
    },
    updateVariantName(name) {
      this.currentVariant.name = name
    },
    updateVariants(variants) {
      this.variants = variants
      // this.selectVariant(variants[variants.length])
    },
    deleteVariant(variant) {
      this.variants = this.variants.filter(e => {
        return !Object.is(e, variant)
      })
      this.variants[0].selected = true
    },
    save() {
      this.loading = true
      if (!this.name) {
        this.name = "New Canned Response"
      }
      const { uuid, name, variants, shortcut, folder, plainText, privateNote } = this
      this.error = null
      post("response/save", {
        uuid,
        name,
        variants,
        plainText,
        shortcut,
        privateNote,
        folder,
      })
        .then(ret => {
          if (!this.$route.params.uuid) {
            // this.$gtm.trackEvent({
            //   event: "createResponse", // Event type [default = 'interaction'] (Optional)
            //   client_id: process.env.VUE_APP_CLIENT_ID,
            //   user: this.$store.state.user.uuid,
            // })
          }
          this.$store.dispatch("response", ret.data).then(() => {
            this.uuid = ret.data.uuid
            this.folder = ret.data.folder
            this.back(true)
          })
        })
        .catch(e => {
          this.loading = false
          if (e.response && e.response.data && e.response.data.message) {
            if (e.response.data.errors) {
              this.$root.$emit("error", Object.values(e.response.data.errors).join(" "))
            } else {
              this.$root.$emit("error", e.response.data.message)
            }
          } else {
            if (e.response && e.response.statusText) {
              this.$root.$emit("error", "We haven't been able to save your canned response: " + e.response.statusText)
            } else {
              this.$root.$emit("error", "We haven't been able to save your canned response: " + e)
            }
          }
        })
    },
    hideFolderInput() {
      this.showFolderInput = false
    },
  },
  created() {
    let val = JSON.stringify([{ type: "paragraph", id: uuidv4(), children: [{ text: "" }] }])
    this.currentValue = val
    this.variants = [
      {
        name: "Default",
        selected: true,
        content: this.currentValue,
        temp_id: uuidv4(),
      },
    ]

    if (this.$route.params.uuid) {
      // Edit mode
      let responses = this.$store.state.responses
      let response = responses.find(elm => {
        return elm.uuid == this.$route.params.uuid
      })

      let { name, uuid, shortcut, variants, folder, plain_text, is_owned, private_note } = response

      this.name = name
      this.is_owned = is_owned
      this.uuid = uuid
      this.plainText = Boolean(plain_text)
      this.privateNote = private_note
      if (folder) {
        this.folder = this.$store.state.folders.owned.concat(this.$store.state.folders.team).find(f => f.uuid == folder.uuid)
      }

      let variantsO = JSON.parse(JSON.stringify(variants.slice()))
      variantsO.forEach(v => {
        v.selected = false
        v.temp_id = uuidv4()
      })
      this.variants = variantsO

      this.shortcut = shortcut
      if (this.variants.length) {
        this.variants[0].selected = true
        this.currentValue = this.variants[0].content
      }
    } else {
      if (this.$route.params.folder) {
        this.folder = this.$store.state.folders.owned.concat(this.$store.state.folders.team).find(f => f.uuid == this.$route.params.folder)
      }
    }

    // Set selected to false on all variants to get the hash
    let originalVariants = JSON.parse(JSON.stringify(this.variants))
    originalVariants.map(e => (e.selected = false))
    originalHash = JSON.stringify(originalVariants).hash()
    this.$root.$on("bv::dropdown::hide", () => {
      this.showFolderInput = false
    })

    this.$watch("name", () => {
      this.isDirty = true
      this.$bvToast.show("savechanges")
    })
    this.$watch(
      "variants",
      newValue => {
        let variants = JSON.parse(JSON.stringify(newValue))
        variants.map(e => (e.selected = false))
        let hash = JSON.stringify(variants).hash()
        if (hash !== originalHash) {
          this.isDirty = true
          this.$bvToast.show("savechanges")
        }
      },
      { deep: true }
    )
    this.$watch("folder", () => {
      this.isDirty = true
      this.$bvToast.show("savechanges")
    })
    this.$watch("privateNote", () => {
      this.isDirty = true
      this.$bvToast.show("savechanges")
    })
    this.$watch("shortcut", () => {
      this.isDirty = true
      this.$bvToast.show("savechanges")
    })
  },
  beforeDestroy() {
    this.$root.$off("bv::dropdown::hide", this.hideFolderInput)
  },
}
</script>

<style lang="scss" scoped>
@import "@/common/assets/css/mixins.scss";

.editresponse {
  display: grid;
  grid-template-rows: min-content 1fr;
  &.dirty {
    grid-template-rows: min-content auto;
  }
  grid-template-columns: auto;
  overflow: hidden;
}
.editor-editable {
  overflow: auto;
  height: 100%;
}
::v-deep .sidebar {
  width: 450px;
  @include media-breakpoint-down(md) {
    width: 300px;
  }
}
.editor-wrapper {
  display: flex;
  // grid-template-columns: auto 450px;
  // @include media-breakpoint-down(md) {
  //   grid-template-columns: auto 300px;
  // }
  overflow: auto;
  height: 100%;
}
.unsaved {
  bottom: 20px;
  opacity: 0.7;
  width: 400px;
  left: calc(50% - 200px);
  user-select: none;
  position: fixed;
  z-index: 200;
}
// .navbar {
//   grid-area: navbar;
// }
// .editor {
//   grid-area: editor;
// }
// .sidebar {
//   grid-area: sidebar;
// }
.name {
  background: var(--color-background);
}
::v-deep .dropdown-menu {
  min-width: 250px;
  max-height: 400px;
  overflow: auto;
}
</style>
