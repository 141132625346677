<template>
  <div class="login bg-body-bg vw-100 vh-100 d-flex align-items-start justify-content-center">
    <div class="rounded form bg-body-bg d-flex align-items-center justify-content-center">
      <transition name="fade" mode="out-in" duration="300">
        <section key="login" v-if="form == 'login'">
          <div class="row">
            <div class="mx-auto col-8">
              <img width="200" class="mx-auto mb-5 d-block visible-light mw-100" src="@/common/assets/img/logo.svg" alt="" />
              <img width="200" class="mx-auto mb-5 d-block visible-dark" src="@/common/assets/img/logo_dark.svg" alt="" />
            </div>
          </div>
          <b-button @click.stop="$root.$emit('loginWithGoogle')" block variant="blue" size="lg" class="d-flex align-items-center justify-content-center">
            <img class="mr-4" src="@/common/assets/img/logo_google.svg" height="16" alt="" />
            Continue with Google</b-button
          >
          <!-- <b-button v-if="isMac()" @click.stop="$root.$emit('loginWithApple')" block variant="dark" size="lg" class="d-flex align-items-center justify-content-center">
            <img class="mr-4" src="@/common/assets/img/logo_apple.svg" height="16" alt="" />
            Sign in with Apple</b-button
          > -->
          <p class="my-3 text-center text-default-lighter"><i>or</i></p>
          <form @submit.stop.prevent="login()">
            <p class="text-center text-danger" v-if="error">{{ error }}</p>
            <section v-show="loading">
              <div class="p-5 text-center w-100 display-flex align-items-center justify-content-center">
                <img width="16" src="@/common/assets/img/loader_dark.svg" />
              </div>
            </section>
            <section v-show="!loading">
              <b-input autofocus :disabled="loading" required class="my-3" v-model="email" type="email" placeholder="Email"></b-input>
              <b-input required class="my-3" :disabled="loading" v-model="password" type="password" placeholder="Password"></b-input>
              <div class="text-right">
                <a tabindex="-1" @click="openResetPasswordPage()" class="p-0 text-default-lighter">
                  Forgot your password
                  <b-icon icon="arrow-right-short"></b-icon>
                </a>
              </div>
              <b-button :disabled="loading" type="submit" size="lg" block variant="primary" class="mt-4">
                <img v-show="loading" width="16" src="@/common/assets/img/loader_white.svg" alt="" />
                <span v-show="!loading">Log In</span>
              </b-button>
            </section>
            <p class="mt-4 text-center text-muted" v-if="slow">Loading account data...</p>
            <p class="mt-4 text-center">
              Don't have an account?
              <b-button @click="openSignup()" variant="ghost" class="p-0 my-0 text-primary"> Create your account today</b-button>
            </p>
          </form>
        </section>
        <section key="signup" v-else-if="form == 'signup'">
          <div class="pb-4 text-center">
            <img width="48" class="mx-auto mb-3 d-block" src="@/common/assets/img/logo_icon.svg" alt="" />
            <h2>Welcome to typedesk</h2>
            <p>Test out all the features of typedesk free for 30 days! No credit card required.</p>
          </div>
          <b-button @click.stop="$root.$emit('loginWithGoogle')" block variant="blue" size="lg" class="d-flex align-items-center justify-content-center">
            <img class="mr-4" src="@/common/assets/img/logo_google.svg" height="16" alt="" />
            Continue with Google</b-button
          >
          <p class="my-4 text-center text-default-lighter"><i>or</i></p>
          <form @submit.stop.prevent="signup()" action="">
            <p class="text-center text-danger" v-if="error">{{ error }}</p>
            <!-- <label for="">Email address</label> -->
            <b-input :disabled="loading" autofocus required class="my-3" v-model="email" type="email" placeholder="Enter your email"></b-input>
            <section class="mt-4">
              <!-- <label for="">Choose a password</label> -->
              <b-input :disabled="loading" required class="my-2" v-model="password" type="password" placeholder="Choose a password"></b-input>
              <!-- <label for="">Confirm password</label> -->
              <b-input :disabled="loading" required class="my-2" v-model="password_confirm" type="password" placeholder="Confirm your password"></b-input>
              <div class="text-right text-muted small">Min. 8 chars</div>
            </section>
            <section class="mt-4">
              <b-input name="full_name" required class="my-3" v-model="name" type="text" placeholder="Your first name"></b-input>
            </section>
            <div class="text-right text-muted small">Used to customize your canned responses</div>
            <b-button :disabled="loading" type="submit" size="lg" block variant="primary" class="mt-4">
              <img v-show="loading" width="16" src="@/common/assets/img/loader_white.svg" alt="" />
              <span v-show="!loading">Create my account</span>
            </b-button>
            <p class="mt-4 text-center" v-if="slow">If you are importing data from your previous account, this process sometimes takes a few seconds...</p>
          </form>
          <div class="mt-4 text-center cursor-pointer text-muted" @click="form = 'login'">Click here if you already have an account</div>
        </section>
        <section key="passcode" v-else-if="form == 'code'">
          <div class="mb-5 row align-items-center">
            <div class="text-center col">
              <div class="d-flex justify-content-center">
                <img class="my-4 visible-light" src="@/common/assets/img/devices_icon.svg" width="128" alt="" />
                <img class="my-4 visible-dark" src="@/common/assets/img/devices_icon_dark.svg" width="128" alt="" />
              </div>
              <h3 class="m-0 text-center">New device detected</h3>
              <p class="mt-3 mb-0 text-center">We've sent a verification code to your email, it should be there within a minute. Please enter the code received below.</p>
            </div>
          </div>
          <form @submit.stop.prevent="checkCode()" action="">
            <div class="mt-3 alert alert-danger" v-if="error">
              <p class="m-0 text-center text-white">{{ error }}</p>
            </div>
            <b-input required class="my-3" v-model="code" type="text" placeholder="Enter your verification code"></b-input>
            <b-button :disabled="loading" type="submit" size="lg" block variant="primary" class="my-4 d-flex align-items-center justify-content-center">
              <img v-show="loading" width="16" src="@/common/assets/img/loader_white.svg" alt="" />
              <span v-show="!loading"
                >Continue
                <b-icon icon="arrow-right"></b-icon>
              </span>
            </b-button>
            <p class="text-center alert alert-secondary" v-if="hasRetried">
              We are sending another code to your inbox. Please double check your spam folder and reach out if you're experiencing issues.
            </p>
            <b-button v-else variant="hover" class="text-center w-100 text-default-light" :disabled="delay > 0" @click="login(true)"
              >I haven't received a code <span v-if="delay > 0">(wait {{ delay }}s)</span></b-button
            >
          </form>
        </section>
        <section key="code" v-if="form == 'password'">
          <div class="mb-5 row align-items-center">
            <div class="col-2">
              <b-icon icon="arrow-left" class="cursor-pointer" fontScale="1.2" @click="form = 'login'"></b-icon>
            </div>
            <div class="col">
              <p class="m-0 text-center">Reset your password</p>
            </div>
            <div class="col-2"></div>
          </div>
          <form @submit.stop.prevent="resetPassword()" action="">
            <p class="text-center text-danger" v-if="error">{{ error }}</p>
            <!-- <label for="">Email address</label> -->
            <b-input required class="my-3" v-model="email" type="email" placeholder="Enter your email"></b-input>
            <p class="my-4 text-center" v-if="passwordResetConfirm">Your password reset link has been sent by email.</p>
            <p v-else class="my-4 text-center text-danger">{{ passwordResetError }}</p>
            <b-button :disabled="loading" type="submit" size="lg" block variant="primary" class="my-4">
              <img v-show="loading" width="16" src="@/common/assets/img/loader_white.svg" alt="" />
              <span v-show="!loading">Reset my password</span>
            </b-button>
          </form>
        </section>
        <section key="invite" v-if="form == 'invite'">
          <div v-show="loading" class="mb-3">
            <div class="d-flex align-items-center justify-content-center">
              <img width="16" src="@/common/assets/img/loader_dark.svg" alt="" />
            </div>
          </div>
          <section v-show="!loading">
            <div class="d-flex flex-column align-items-center">
              <lottie-animation :width="192" :height="192" :json="animation" />
              <div class="text-center">
                <h2 class="my-3">
                  Welcome<span v-if="name && name.length"> {{ name }}</span
                  >! Let's invite your team.
                </h2>
                <p>The more the merrier. Invite up to 5 users to create and share canned responses.</p>
              </div>
            </div>
            <invite-users @done="exit()" />
            <div class="mt-2 text-center justify-content-center d-flex">
              <b-button @click="exit()" class="px-0 text-decoration-none" variant="link"
                >Skip
                <b-icon icon="arrow-right-short"></b-icon>
              </b-button>
            </div>
          </section>
        </section>
      </transition>
    </div>
  </div>
</template>

<script>
import { post, get } from "@/common/api"
// import store from "@/common/store"
import InviteUsers from "@/common/components/InviteUsers"
import LottieAnimation from "@/common/components/LottieAnimation.vue" // import lottie-vuejs

const { detect } = require("detect-browser")
let timeout = null,
  interval = null
export default {
  data() {
    return {
      animation: require("@/common/assets/lottie/invite.json"),
      loading: false,
      email: "",
      password: "",
      name: null,
      code: null,
      password_confirm: "",
      hasRetried: false,
      error: false,
      form: "login",
      slow: false,
      passwordResetError: null,
      passwordResetConfirm: "",
      delay: 30,
      ignoreToken: false,
    }
  },
  components: {
    InviteUsers,
    LottieAnimation,
  },
  created() {
    // await window.store.restored
    console.log(this.$route.params)

    const url = new URL(window.location.href)
    if (this.$route.query.error && this.$route.query.error == "googlecallback") {
      this.error = "Unfortunately, something went wrong and we can't perform your request right now. Please log in or sign up using your email / password."
      return
    }
    if (this.$route.query.next && this.$route.query.token && this.$store.state.user && this.$store.state.user.access_token == this.$route.query.token) {
      window.location = url.origin + this.$route.query.next
      return false
    }
    if (this.$route.query.token) {
      this.loginWithGoogle()
      return
    }
    if (this.$route.query.email) {
      this.email = decodeURI(this.$route.query.email)
    }
    if (this.$route.query.type == "signup") {
      this.form = "signup"
      return
    }
    setTimeout(() => {
      if (!this.$route.params.next && !this.$route.query.next && this.$store.state.user && !url.searchParams.get("client_id")) {
        this.$router.push({ name: "Responses" })
      }
    }, 500)
  },
  watch: {
    form() {
      this.error = null
    },
    $route() {
      if (this.$route.query.token) {
        this.loginWithGoogle()
      }
    },
  },
  methods: {
    async loginWithGoogle() {
      this.loading = true
      await window.store.restored
      this.$store.dispatch("user", { access_token: decodeURI(this.$route.query.token) }).then(async () => {
        console.log("Loading User Infos")
        await this.loadUserInfos()
        if (this.$route.query.new) {
          // this.$gtm.trackEvent({
          //   event: "signup", // Event type [default = 'interaction'] (Optional)
          //   client_id: process.env.VUE_APP_CLIENT_ID,
          //   user: this.$store.state.user.uuid,
          // })
          this.loading = false
          this.form = "invite"
        } else {
          // this.$gtm.trackEvent({
          //   event: "login", // Event type [default = 'interaction'] (Optional)
          //   client_id: process.env.VUE_APP_CLIENT_ID,
          //   user: this.$store.state.user.uuid,
          // })
          console.log("Loading Session")
          this.openSession()
          this.exit()
        }
      })
    },
    openResetPasswordPage() {
      window.location = process.env.VUE_APP_APP_URL + "password/lost"
    },
    checkCode() {
      this.loading = true
      post("login/check", {
        email: this.email,
        code: this.code,
        device_id: this.$store.state.device_id,
      })
        .then(ret => {
          clearInterval(interval)
          this.$store.dispatch("user", { access_token: ret.data }).then(async () => {
            // Handle browser extension login
            const url = new URL(window.location.href)
            if (url.searchParams.get("client_id") && url.searchParams.get("client_id") == 4) {
              window.location = process.env.VUE_APP_APP_URL + "extension/login?loading=true&token=" + ret.data
              return
            }
            await this.openSession()
            this.exit()
          })
        })
        .catch(e => {
          this.loading = false
          this.error = e.response.data
        })
    },
    loadUserInfos() {
      return new Promise(res => {
        get("me")
          .then(ret => {
            let user = this.$store.state.user
            this.$store.dispatch("user", Object.assign(user, ret.data))
            res()
          })
          .catch(e => {
            window.postMessage("logout", "*")
            this.ignoreToken = true
            console.log(e)
            this.loading = false
            this.error = "We haven't been able to open your session. Please login again."
          })
      })
    },
    async openSession() {
      await window.store.restored
      return new Promise(resolve => {
        this.loading = true
        this.$root
          .openSession()
          .then(() => {
            // this.$root.$emit("login")
            // if (this.$store.state.user && !this.$store.state.user.props.some(e => e.name == "tutorial_seen")) {
            //   this.$gtag.event("signup")
            // } else {
            //   this.$gtag.event("login")
            // }
            if (this.$route.query.next && this.$route.query.token) {
              let url = new URL(window.location.href)
              window.location = url.origin + this.$route.query.next
              return
            }
            if (this.$route.query.next) {
              this.$root.$emit("autologin", { next: this.$route.query.next })
              return
            }
            if (this.$route.params.next) {
              this.$router.push(this.$route.params.next)
              return
            }
            if (process.env.VUE_APP_CLIENT_ID == 4 && this.$route.query.token) {
              window.location = process.env.VUE_APP_APP_URL + "extension/login/success"
              // window.close()
              return
            }
            if (this.$store.state.user.invitations_count > 0) {
              this.$router.push({ name: "Invitations" })
              return
            }
            // this.$router.push({ name: "Gate" })
            resolve()
          })
          .catch(e => {
            console.log(e)
            this.error = e
            this.slow = false
            this.loading = false
            clearTimeout(timeout)
          })
      })
    },
    exit() {
      this.$router.push("/dashboard")
      this.form = "login"
      this.loading = false
    },
    isMac() {
      if (process.platform == "darwin") return true
      const browser = detect()
      if (browser) {
        return browser.os == "Mac OS"
      }
      return false
    },
    async login(retry = false) {
      this.hasRetried = retry
      this.error = false
      this.loading = true
      const { email, password } = this
      if (this.$route.query.token) {
        // this.$gtm.trackEvent({
        //   event: "login", // Event type [default = 'interaction'] (Optional)
        //   client_id: process.env.VUE_APP_CLIENT_ID,
        //   user: this.$store.state.user.uuid,
        // })
        await this.openSession()
        this.exit()
      } else {
        const url = new URL(window.location.href)
        let headers = {}
        if (url.searchParams.get("client_id") && url.searchParams.get("client_id") == 4) {
          headers = {
            "Client-Id": 4,
          }
        }
        let device_id = this.$store.state.device_id
        if (url.searchParams.get("device_id")) {
          device_id = url.searchParams.get("device_id")
        }
        post(
          "login",
          {
            email,
            retry,
            password,
            device_id,
          },
          headers
        )
          .then(async ret => {
            if (ret.status == 202) {
              this.loading = false
              this.form = "code"
              interval = setInterval(() => {
                if (this.delay > 0) {
                  this.delay--
                }
              }, 1000)
            } else {
              this.$store.dispatch("user", { access_token: ret.data }).then(async () => {
                const url = new URL(window.location.href)
                if (url.searchParams.get("client_id") && url.searchParams.get("client_id") == 4) {
                  window.location = process.env.VUE_APP_APP_URL + "extension/login?loading=true&token=" + ret.data
                  return
                }
                // await this.loadUserInfos()
                // this.$gtm.trackEvent({
                //   event: "login", // Event type [default = 'interaction'] (Optional)
                //   client_id: process.env.VUE_APP_CLIENT_ID,
                //   user: this.$store.state.user.uuid,
                // })
                await this.openSession()
                this.exit()
              })
            }
          })
          .catch(e => {
            this.error = e.response.data.message
            this.slow = false
            this.loading = false
            clearTimeout(timeout)
            console.log(e)
          })
      }
    },
    resetPassword() {
      this.passwordResetError = null
      this.passwordResetConfirm = false
      post("forgot-password", {
        email: this.email,
      })
        .then(e => {
          this.passwordResetConfirm = true
          console.log(e)
        })
        .catch(e => {
          this.passwordResetError = e.response.data.errors.email[0]
        })
    },
    openSignup() {
      var cversion = require("./../../../package.json").version
      window.location = process.env.VUE_APP_APP_URL + "signup?client_id=" + process.env.VUE_APP_CLIENT_ID + "&client_version=" + cversion + "&device_id=" + this.$store.state.device_id
    },
    signup() {
      timeout = setTimeout(() => {
        this.slow = true
      }, 2000)
      this.loading = true
      const { email, password, password_confirm, name } = this
      let headers = {}
      const url = new URL(window.location.href)
      if (url.searchParams.get("client_id") && url.searchParams.get("client_id") == 4) {
        headers = {
          "Client-Id": 4,
        }
      }
      post("register", { email, password, password_confirmation: password_confirm, name }, headers)
        .then(async () => {
          let ret = await post("login", { email, password, device_id: this.$store.state.device_id }, headers)

          // Handle browser extension login
          if (url.searchParams.get("client_id") && url.searchParams.get("client_id") == 4) {
            window.location = process.env.VUE_APP_APP_URL + "extension/login?loading=true&token=" + ret.data
            return
          }

          await this.$store.dispatch("user", { access_token: ret.data })
          await this.openSession()
          // this.$gtm.trackEvent({
          //   event: "signup", // Event type [default = 'interaction'] (Optional)
          //   client_id: process.env.VUE_APP_CLIENT_ID,
          //   user: this.$store.state.user.uuid,
          // })

          if (this.$route.query.next) {
            this.$root.$emit("autologin", { next: this.$route.query.next })
            return
          }

          if (this.$store.state.user.parent_subscription) {
            this.$router.push({ name: "Responses" })
            return
          }

          this.form = "invite"
          this.loading = false
        })
        .catch(e => {
          clearTimeout(timeout)
          this.slow = false
          this.loading = false
          console.log(e)
          if (e.response.data && e.response.data.errors) {
            this.error = Object.values(e.response.data.errors)[0][0]
          } else {
            this.error = e
          }
        })
    },
  },
}
</script>

<style lang="scss">
@import "@/common/assets/css/_variables.scss";
@import "@/common/assets/css/mixins.scss";
.login {
  overflow-x: hidden;
  padding: 0;
  @include media-breakpoint-up(md) {
    padding-top: 5vh;
  }
  // @media (prefers-color-scheme: light) {
  background: url("~@/common/assets/img/bg_login.svg") left -200px bottom 100px no-repeat;
  // }
}
section {
  width: 100%;
}
.theme-light .login {
  background: url("~@/common/assets/img/bg_login.svg") left -200px bottom 100px no-repeat;
}
.form {
  max-width: 525px;
  width: 100%;
  padding: 3rem;
  @include media-breakpoint-up(sm) {
    padding: 6rem 5rem;
  }
}
h2 {
  font-weight: 500 !important;
  font-family: "Outfit", sans-serif !important;
  letter-spacing: -0.08rem !important;
}
input[data-com-onepassword-filled] {
  color: var(--color-default) !important;
  background: transparent !important;
}
</style>
