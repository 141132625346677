<template>
  <div id="app">
    <router-view />
    <b-alert v-model="showError" class="position-fixed fixed-bottom m-0 rounded-0" style="z-index: 2000" variant="danger" dismissible fade>
      {{ error }}
      <template #dismiss>
        <b-icon icon="x"></b-icon>
      </template>
    </b-alert>
    <Paywall />
  </div>
</template>

<script>
import Paywall from "@/common/components/Paywall"
export default {
  components: {
    Paywall,
  },
  data() {
    return {
      error: null,
      showError: false,
    }
  },
  created() {
    this.$root.$on("error", e => {
      if ((e && (!e.response || !e.response.status)) || (e && e.response && e.response.status !== 402)) {
        this.error = e
        if (e.response && e.response.data && typeof e.response.data.errors !== "undefined") {
          this.error = Object.values(e.response.data.errors)[0][0]
        }
        if (e.response && e.response.data && e.response.data.message) {
          this.error = e.response.data.message
        }
        this.showError = true
        setTimeout(() => {
          this.error = null
          this.showError = false
        }, 5000)
      }
    })
  },
  mounted() {
    // Bugfix Extension Chrome Glitch
    document.querySelector("#app").style.height = "100vh"
    if (document.querySelector(".responses")) document.querySelector(".responses").style.height = "100vh"
    if (document.querySelector(".dashboard")) document.querySelector(".dashboard").style.height = "100vh"
    if (document.querySelector(".responselist")) document.querySelector(".responselist").style.height = "100vh"
    if (document.querySelector(".sidebar")) document.querySelector(".sidebar").style.height = "100vh"
    if (document.querySelector(".sidebar__inner")) document.querySelector(".sidebar__inner").style.height = "100vh"
    if (document.querySelector(".wrapper")) document.querySelector(".wrapper").style.height = "100vh"
  },
}
</script>
<style scoped>
#app {
  overflow: hidden;
  height: 100vh;
  /* max-height: 100vh; */
}
</style>
