<template>
  <AppCommon />
</template>

<script>
import AppCommon from "@/common/AppCommon.vue"
import Axios from "axios"

export default {
  components: {
    AppCommon,
  },
  methods: {
    openHelp(search) {
      if (search && search.length > 0) {
        window.open("https://www.typedesk.com/help/search?s=" + encodeURI(search))
        return
      } else {
        window.open("https://www.typedesk.com/help")
      }
    },
  },
  mounted() {
    // smartlookClient.init("b753071c9f0ad8e82a6fec9e41fc0660bf920de6")
    // if (this.$store.state.user.email) {
    //   smartlookClient.identify(this.$store.state.user.uuid)
    // }
    // if (this.$store.state.user && this.$store.state.user.email && this.$hj) {
    //   this.$hj("identify", this.$store.state.user.uuid, {
    //     email: this.$store.state.user.email,
    //   })
    // }
  },
  async created() {
    const url = new URL(window.location.href)
    if (this.$store.state.user && this.$root.isWeb() && !url.searchParams.get("token")) {
      Axios.get(process.env.VUE_APP_APP_URL + "autologin?token=" + this.$store.state.user.access_token)
    }
    if (!this.$store.state.device_id) {
      const FingerprintJS = require("@fingerprintjs/fingerprintjs")
      const fpPromise = FingerprintJS.load()
      // Get the visitor identifier when you need it.
      const fp = await fpPromise
      const result = await fp.get()
      this.$store.dispatch("device_id", result.visitorId)
      console.log("New Device Id: " + this.$store.state.device_id)
    }
    this.$root.$on("logout", () => {
      Axios.post(process.env.VUE_APP_APP_URL + "logout")
    })
    this.$root.$on("login", () => {
      Axios.get(process.env.VUE_APP_APP_URL + "autologin?token=" + this.$store.state.user.access_token)
      // if (this.$store.state.user.email && this.$hj) {
      //   this.$hj("identify", this.$store.state.user.uuid, {
      //     email: this.$store.state.user.email,
      //   })
      // }
      // smartlookClient.identify(this.$store.state.user.uuid)
    })
    this.$root.$on("openHelp", search => {
      this.openHelp(search)
    })
    this.$root.$on("openUrl", url => {
      window.open(url)
    })
    this.$root.$on("loginWithGoogle", () => {
      var cversion = require("./../package.json").version
      if (this.$route.query.next) {
        window.location =
          process.env.VUE_APP_APP_URL +
          "auth/google?client_id=" +
          process.env.VUE_APP_CLIENT_ID +
          "&next=" +
          this.$route.query.next +
          "&client_version=" +
          cversion +
          "&device_id=" +
          this.$store.state.device_id
      } else {
        window.location = process.env.VUE_APP_APP_URL + "auth/google?client_id=" + process.env.VUE_APP_CLIENT_ID + "&client_version=" + cversion + "&device_id=" + this.$store.state.device_id
      }
    })
    this.$root.$on("autologin", e => {
      let url = process.env.VUE_APP_APP_URL + "autologin?token=" + this.$store.state.user.access_token
      if (e.next) {
        url += "&next=" + encodeURI(e.next)
      }
      window.location = url
    })
    this.$root.$on("loginWithApple", () => {
      window.location = process.env.VUE_APP_APP_URL + "auth/apple?client_id=" + process.env.VUE_APP_CLIENT_ID
    })
    this.$root.$on("openApps", () => {
      window.location = process.env.VUE_APP_APP_URL + "download"
    })
  },
}
</script>
