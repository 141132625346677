<template>
  <div v-if="folder" class="d-flex align-items-center flex-nowrap text-truncate" :class="size" :style="'color: ' + folder.color">
    <span :style="'background-color: ' + folder.color" :class="{ team: folder.team }" class="d-block rounded-full folder-badge mr-1"></span>
    <span class="d-block text-nowrap text-truncate">
      {{ folder.name }}
    </span>
  </div>
</template>

<script>
export default {
  props: {
    uuid: String,
    size: {
      type: String,
      default: null,
    },
  },
  computed: {
    folder() {
      if (this.uuid) {
        return this.$store.state.folders.owned
          .concat(this.$store.state.folders.shared)
          .concat(this.$store.state.folders.team)
          .find(e => e.uuid == this.uuid)
      }
      return null
    },
  },
}
</script>

<style lang="scss" scoped>
.folder-badge {
  width: 5px;
  height: 10px;
  border-radius: 5px;
}
.folder-badge.team {
  border-radius: 3px;
}
</style>
