<template>
  <div>
    <b-dropdown ref="dropdown" id="link" no-caret variant="link" toggle-class="p-0" boundary="window">
      <template #button-content>
        <b-button @click.stop.prevent="openDropdown()" class="m-0 d-block" variant="hover" :class="{ active: isActive() }">
          <b-icon icon="link"></b-icon>
        </b-button>
      </template>
      <b-dropdown-text>
        <div class="p-3 dropdown">
          <div class="d-flex flex-column justify-content-center" v-if="isActive()">
            <b-input class="my-2" disabled v-model="url"></b-input>
            <b-button @click.stop="remove()" variant="danger" block>Remove link</b-button>
          </div>
          <div class="d-flex flex-column justify-content-center" v-else>
            <p v-if="error" class="text-danger text-center my-3">{{ error }}</p>
            <b-input class="my-2" v-model="text" placeholder="Link text"></b-input>
            <b-input autofocus ref="url" class="my-2" v-model="url" placeholder="https://, http://, mailto:"></b-input>
            <b-button @click.stop="insert()" variant="primary" block>Insert</b-button>
          </div>
        </div>
      </b-dropdown-text>
    </b-dropdown>
  </div>
</template>

<script>
import { Editor, Transforms, Range } from "slate"
import { SlateMixin } from "slate-vue"
let lastSelection = null
export default {
  mixins: [SlateMixin],
  data() {
    return {
      text: null,
      url: null,
      error: null,
    }
  },
  computed: {
    defaultText() {
      if (this.$editor.selection) {
        return Editor.string(this.$editor, this.$editor.selection)
      }
      return ""
    },
  },
  created() {
    document.addEventListener("keydown", this.attachShortcut)
  },
  beforeDestroy() {
    document.removeEventListener("keydown", this.attachShortcut)
  },
  methods: {
    attachShortcut(e) {
      if (e.key == "k" && (e.ctrlKey || e.metaKey)) {
        this.openDropdown()
        e.preventDefault()
      }
    },
    openDropdown() {
      lastSelection = this.$editor.selection
      let [nodes] = Editor.nodes(this.$editor, {
        match: n => n.type === "link",
      })
      if (typeof nodes == "undefined") {
        if (this.$editor.selection && !Range.isCollapsed(this.$editor.selection)) {
          this.text = Editor.string(this.$editor, this.$editor.selection)
          this.url = null
        } else {
          this.url = null
          this.text = null
        }
      } else {
        if (nodes && nodes[0].type == "link") {
          this.text = nodes[0].children[0].text
          this.url = nodes[0].to
        }
      }
      this.$refs.dropdown.show()
      if (this.$refs.url) {
        setTimeout(() => {
          this.$refs.url.focus()
        }, 100)
      }
    },
    remove() {
      Transforms.unwrapNodes(this.$editor, {
        match: n => n.type === "link",
        mode: "lowest",
      })
    },
    insert() {
      if (this.url.indexOf("http") == 0 || this.url.indexOf("mailto") == 0) {
        const editor = this.$editor
        const selection = lastSelection
        const isCollapsed = selection && Range.isCollapsed(selection)
        const link = {
          type: "link",
          to: this.url,
          children: [{ text: this.text }],
        }
        if (isCollapsed) {
          Transforms.insertNodes(editor, link)
        } else {
          Transforms.wrapNodes(editor, link, { split: true, at: lastSelection })
          // Transforms.collapse(editor, { edge: "end" })
        }
        this.$refs.dropdown.hide()
      } else {
        this.error = "It looks like your link is malformatted. Please insert a link starting with http:// or https://"
      }
    },

    // toggleMark() {
    //   if (this.isMarkActive("link")) {
    //     // Editor.addMark(this.$editor, format, true)
    //     this.$editor.removeMark("link", true)
    //   } else {
    //     this.$editor.addMark("link", true)
    //     // Editor.removeMark(this.$editor, format, true)
    //   }
    // },
    isActive() {
      const [match] = Editor.nodes(this.$editor, {
        match: n => {
          return n.type === "link"
        },
      })
      if (match && match.length && match[0].to.length) {
        this.url = match[0].to
      }
      return !!match
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .dropdown-menu {
  min-width: 350px;
  width: 350px;
}
</style>
