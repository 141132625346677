<template>
  <b-button :title="title" :class="{ active: isMarkActive(format) }" @click="toggleMark(format)" variant="hover">
    <b-icon :icon="icon"></b-icon>
  </b-button>
</template>

<script>
import { Editor } from "slate"
import { SlateMixin } from "slate-vue"

export default {
  mixins: [SlateMixin],
  props: {
    format: String,
    shortcut: String,
    icon: String,
    active: Boolean,
    title: String,
  },
  created() {
    document.addEventListener("keydown", this.attachShortcut)
  },
  beforeDestroy() {
    document.removeEventListener("keydown", this.attachShortcut)
  },
  methods: {
    attachShortcut(e) {
      if (e.key == this.shortcut && (e.ctrlKey || e.metaKey)) {
        this.toggleMark(this.format)
        e.preventDefault()
      }
    },
    toggleMark(format) {
      if (format == "clear") {
        this.$editor.removeMark("font", true)
        this.$editor.removeMark("bold", true)
        this.$editor.removeMark("size", true)
        this.$editor.removeMark("italic", true)
      } else {
        if (this.isMarkActive(format)) {
          // Editor.addMark(this.$editor, format, true)
          this.$editor.removeMark(format, true)
        } else {
          this.$editor.addMark(format, true)
          // Editor.removeMark(this.$editor, format, true)
        }
      }
    },
    isMarkActive(format) {
      let marks = Editor.marks(this.$editor)
      return marks ? marks[format] === true : false
    },
  },
}
</script>

<style lang="scss" scoped></style>
