<template>
  <div>
    <div v-if="showForm" class="d-flex align-items-center justify-content-center">
      <div class="py-2" v-show="loading">
        <img width="16" src="@/common/assets/img/loader_dark.svg" alt="" />
      </div>
      <div class="w-100 px-3" v-show="!loading">
        <div class="d-flex align-items-center w-100">
          <b-input
            @blur="blur()"
            ref="input"
            autofocus
            v-model="name"
            @keydown.stop.prevent.esc="showForm = false"
            class="my-2 w-100"
            @keydown.enter.stop.prevent="createFolder()"
            :placeholder="placeholder"
          ></b-input>
          <b-button variant="link text-decoration-none" class="px-0" @click="updateFolderName()"><b-icon class="ml-3" icon="arrow-return-left"></b-icon></b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { post } from "@/common/api"

export default {
  data() {
    return {
      showForm: false,
      name: null,
      showError: false,
      loading: false,
    }
  },
  props: {
    type: {
      type: String,
      default: "owned",
    },
  },
  computed: {
    placeholder() {
      return this.type == "owned" ? "Create folder" : "Create team folder"
    },
  },
  methods: {
    show() {
      this.name = null
      this.showForm = true
    },
    blur() {
      if (!this.loading) {
        this.showForm = false
      }
    },
    createFolder() {
      // if (this.name && this.name.length) {
      this.loading = true
      post("folder/create", {
        name: this.name,
        type: this.type,
      })
        .then(ret => {
          this.$store.dispatch("folder", ret.data)
          this.showForm = false
          this.loading = false
        })
        .catch(e => {
          this.loading = false
          this.$nextTick(() => {
            this.$refs.input.focus()
          })
          this.$root.$emit("error", e)
        })
      // }
    },
  },
}
</script>

<style lang="scss" scoped>
</style>