import Vue from "vue"
import VueRouter from "vue-router"
import { routes } from "@/router/index"

// const Login = () => import("@/common/views/Login")
import Login from "@/common/views/Login"
import Dashboard from "@/common/views/Dashboard"
import Responses from "@/common/views/Responses"
import CreateResponse from "@/common/views/CreateResponse"
import AcceptInvitation from "@/common/views/AcceptInvitation"

// const CreateResponse = () => import("@/common/views/CreateResponse")
// const AcceptInvitation = () => import("@/common/views/AcceptInvitation")

Vue.use(VueRouter)

const mergedRoutes = [
  ...routes,
  ...[
    // {
    //   path: "*",
    //   redirect: "/dashboard/responses",
    // },
    {
      path: "/",
      redirect: "/dashboard/responses",
    },
    {
      path: "/login",
      name: "Login",
      meta: {
        title: "Login or Create an Account",
      },
      component: Login,
    },
    {
      path: "/edit/:uuid?",
      name: "CreateResponse",
      meta: {
        title: "Edit Response",
      },
      component: CreateResponse,
    },
    {
      path: "/invitation/:code",
      name: "AcceptInvitation",
      meta: {
        title: "Accept Invitation",
      },
      component: AcceptInvitation,
    },
    {
      path: "/invitations/",
      name: "Invitations",
      meta: {
        title: "My invitations",
      },
      component: AcceptInvitation,
    },
    {
      path: "/dashboard",
      component: Dashboard,
      children: [
        {
          path: "/dashboard/",
          redirect: "/dashboard/responses",
        },
        {
          path: "/dashboard/responses",
          name: "Responses",
          component: Responses,
          props: { type: null },
          meta: {
            title: "Dashboard - Responses",
          },
        },
        {
          path: "/dashboard/bookmarks",
          name: "Bookmarks",
          component: Responses,
          props: { type: "bookmarks" },
          meta: {
            title: "Dashboard - Bookmarks",
          },
        },
        {
          path: "/dashboard/archives",
          name: "Archives",
          component: Responses,
          meta: {
            title: "Dashboard - Archives",
          },
          props: { type: "archives" },
        },
        {
          path: "/dashboard/responses/:uuid",
          name: "Folder",
          component: Responses,
          meta: {
            title: "Folder",
          },
          props: { type: "folder" },
        },
      ],
    },
  ],
]

const router = new VueRouter({
  mode: process.env.IS_ELECTRON ? "hash" : "history",
  base: process.env.BASE_URL,
  routes: mergedRoutes,
})

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  // // console.log(from, to);

  window.from = {
    name: from.name,
    params: from.params,
  }
  window.to = {
    name: to.name,
    params: to.params,
  }
  if (!process.env.IS_ELECTRON) {
    // This goes through the matched routes from last to first, finding the closest route with a title.
    // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
    // `/nested`'s will be chosen.
    const nearestWithTitle = to.matched
      .slice()
      .reverse()
      .find(r => r.meta && r.meta.title)

    // Find the nearest route element with meta tags.
    const nearestWithMeta = to.matched
      .slice()
      .reverse()
      .find(r => r.meta && r.meta.metaTags)

    // If a route with a title was found, set the document (page) title to that value.
    if (nearestWithTitle) document.title = nearestWithTitle.meta.title

    // Remove any stale meta tags from the document using the key attribute we set below.
    Array.from(document.querySelectorAll("[data-vue-router-controlled]")).map(el => el.parentNode.removeChild(el))

    // Skip rendering meta tags if there are none.
    if (!nearestWithMeta) return next()

    // Turn the meta tag definitions into actual elements in the head.
    nearestWithMeta.meta.metaTags
      .map(tagDef => {
        const tag = document.createElement("meta")

        Object.keys(tagDef).forEach(key => {
          tag.setAttribute(key, tagDef[key])
        })

        // We use this to track which meta tags we create so we don't interfere with other ones.
        tag.setAttribute("data-vue-router-controlled", "")

        return tag
      })
      // Add the meta tags to the document head.
      .forEach(tag => document.head.appendChild(tag))
  }

  next()
})

export default router
