<template>
  <div class="sidebar vh-100 d-flex flex-column justify-content-start" :class="{ show: showMenu, windows: !$root.isMac() }">
    <div v-if="$store.state.user" class="scroll-container" :class="{ mac: $root.isElectron() && $root.isMac() }">
      <div class="pb-0 sidebar__inner">
        <div class="d-flex align-items-center">
          <b-button class="p-0 m-0 mt-1" variant="hover">
            <div class="m-0 h3">
              <b-icon class="m-0 menu h3" @click="showMenu = !showMenu" icon="x"></b-icon>
            </div>
          </b-button>
          <b-dropdown lazy id="user-dropdown" ref="userDropdown" variant="hover" class="w-100" :toggle-class="{ 'text-decoration-none p-0 text-left w-100 py-2 px-1': true }" no-caret>
            <template #button-content>
              <div class="d-flex align-items-center">
                <Avatar class="mr-2" />
                <div class="header w-100">
                  <span class="d-flex align-items-center w-100">
                    <div class="d-flex align-items-center flex-grow-1 justify-content-start shrink">
                      <span class="name">
                        {{ $store.state.user.name || $store.state.user.email | truncate(20) }}
                      </span>
                      <span v-if="notifyNewVersion || notifyGetStarted" class="notify"></span>
                    </div>
                  </span>
                  <span v-if="$store.state.user.subscription" class="d-block plan">
                    {{ $store.state.user.subscription.caption | truncate(20) }}
                  </span>
                  <span v-else-if="$store.state.user.parent_subscription && $store.state.user.parent_subscription.role == 'admin'" class="d-block plan"> Admin account </span>
                  <span v-else-if="$store.state.user.parent_subscription && $store.state.user.parent_subscription.role == 'guest'" class="d-block plan"> Guest account </span>
                  <span v-else class="d-block plan">Free</span>
                </div>
                <b-icon class="color-default" icon="chevron-down"></b-icon>
              </div>
            </template>

            <b-dropdown-item @click="$root.$emit('autologin', { next: 'checkout' })"
              ><span class="text-yellow-dark d-flex align-items-center">
                <b-icon class="mr-2 unlock" icon="unlock"></b-icon>
                Upgrade my subscription</span
              ></b-dropdown-item
            >
            <b-dropdown-divider />
            <b-dropdown-item v-b-modal.preferences><span>Account & Subscription</span></b-dropdown-item>
            <b-dropdown-item @click="$root.$emit('openPreferences', 1)"><span>Manage Users</span></b-dropdown-item>
            <b-dropdown-item v-if="!$root.isElectron()" @click="$root.$emit('openApps')">
              <div class="d-flex align-items-center">
                <b-icon class="mr-2" icon="window"></b-icon>
                <span>Install apps</span>
              </div>
            </b-dropdown-item>
            <b-dropdown-item @click="$root.$emit('openPreferences', 2)"><span>Preferences</span></b-dropdown-item>

            <b-dropdown-divider v-if="showDebug" />
            <b-dropdown-item v-if="showDebug" @click="openSession()">Reload data</b-dropdown-item>
            <b-dropdown-item v-if="$root.isElectron() && showDebug" @click="$root.$emit('showJournals')">
              <div class="d-flex align-items-center">
                <span>Show logfiles</span>
              </div>
            </b-dropdown-item>
            <b-dropdown-item v-if="$root.isElectron() && showDebug" @click="$store.dispatch('debug', !$store.state.debug)">
              <div class="d-flex align-items-center">
                <span v-if="!$store.state.debug">Activate debug</span>
                <span v-if="$store.state.debug">Desactivate debug</span>
              </div>
            </b-dropdown-item>
            <b-dropdown-divider />
            <b-dropdown-item @click="$root.$emit('openHelp')"><span>Help Center</span></b-dropdown-item>
            <b-dropdown-item @click="openGetStarted()">
              <span>Get started</span>
              <span v-if="notifyGetStarted" class="notify"></span>
            </b-dropdown-item>
            <b-dropdown-item @click="openWhatsNew()">
              <span>What's new</span>
              <span v-if="notifyNewVersion" class="notify"></span>
            </b-dropdown-item>

            <b-dropdown-divider />
            <b-dropdown-item @click="$root.$emit('logout')"><span class="text-danger">Logout</span></b-dropdown-item>
          </b-dropdown>
          <!-- <Notifications v-if="$store.state.user.notifications && $store.state.user.notifications.length" class="mr-1" /> -->
        </div>
        <div
          v-if="!$store.state.user.subscription && !$store.state.user.parent_subscription"
          class="p-3 mt-3 mb-4 text-center text-white rounded cursor-pointer bg-warning"
          @click="$root.$emit('showPaywall')"
        >
          Click here to upgrade your account and remove the limitations.
        </div>
        <div
          v-if="$store.state.user.subscription && $store.state.user.subscription.is_trial && !$store.state.user.parent_subscription"
          class="p-4 my-2 text-center rounded trial"
          @click="$root.$emit('showPaywall')"
        >
          <span>
            <b>{{ $store.state.user.subscription.days_left }} days</b> left on your trial
          </span>
          <div class="my-2 trialprogress">
            <span :style="'width: ' + Math.round(($store.state.user.subscription.days_left / 30) * 100) + '%'"></span>
          </div>
          <span class="d-flex align-items-center justify-content-center w-100" @click="$root.$emit('showPaywall')">
            Upgrade
            <b-icon class="mt-1 ml-2" icon="arrow-right"></b-icon>
          </span>
        </div>
        <!-- <router-link :to="{ name: 'CreateResponse' }">Create</router-link> -->
        <b-button @click="createResponse()" class="my-3" variant="primary" size="lg" block>Create response</b-button>
        <div class="section">
          <router-link :to="{ name: 'Responses' }" class="p-2 text-left d-flex align-items-center btn">
            <b-icon class="mr-2" icon="list"></b-icon>
            All responses
            <b-badge pill class="ml-2" variant="background">
              <!-- Using responses count in case we are on free plan with more responses than what's actually sent back -->
              {{ $store.state.user.responses_count }}
            </b-badge>
          </router-link>
          <router-link :to="{ name: 'Bookmarks' }" class="p-2 text-left d-flex align-items-center btn">
            <b-icon fontScale=".98" class="mr-2" icon="bookmark-star"></b-icon>
            Bookmarks
            <b-badge :class="{ glow: animateBookmarks }" pill class="ml-2" variant="background">
              {{ bookmarks.length }}
            </b-badge>
          </router-link>
          <a @click.stop.prevent="$root.$emit('openPreferences', 1)" class="p-2 text-left d-flex align-items-center btn">
            <b-icon fontScale=".98" class="mr-2" icon="gear"></b-icon>
            Users & Permissions
          </a>
        </div>
        <div class="section">
          <span class="pr-2 ml-2 d-flex">
            <span class="title text-muted text-uppercase">My folders</span>
            <b-dropdown lazy toggle-tag="span" ref="foldersDropdown" variant="hover" class="ml-auto" toggle-class="p-0 border-0 rounded text-decoration-none" no-caret>
              <template #button-content>
                <span class="p-0 px-1 d-block">
                  <b-icon icon="three-dots"></b-icon>
                </span>
              </template>
              <b-dropdown-item @click="$refs.createfolderinput.show()"><span>New folder</span></b-dropdown-item>
              <b-dropdown-divider></b-dropdown-divider>
              <b-dropdown-group header="Sort">
                <b-dropdown-item @click="sort('az')"><span :class="{ 'text-primary': $store.state.folderSort == 'az' }">A-Z</span></b-dropdown-item>
                <b-dropdown-item @click="sort('count')"><span :class="{ 'text-primary': $store.state.folderSort == 'count' }">Most used first</span></b-dropdown-item>
                <b-dropdown-item @click="sort('date')"><span :class="{ 'text-primary': $store.state.folderSort == 'date' }">Latest first</span></b-dropdown-item>
              </b-dropdown-group>
            </b-dropdown>
          </span>
          <CreateFolderInput ref="createfolderinput" />
          <SidebarFolderLink :key="folder.uuid" v-for="folder in folders.owned" :folder="folder" />
        </div>
        <div class="section" v-if="folders.shared && folders.shared.length">
          <span class="ml-3 d-block title text-uppercase text-muted">Shared with me</span>
          <SidebarFolderLink :key="folder.uuid" v-for="folder in folders.shared" :folder="folder" />
        </div>
        <div class="section">
          <span class="pr-2 ml-2 d-flex">
            <span class="title text-muted text-uppercase">Team folders</span>
            <div class="ml-auto d-flex align-items-center">
              <b-dropdown lazy toggle-tag="span" ref="foldersDropdown" variant="hover" class="ml-auto" toggle-class="p-0 border-0 rounded text-decoration-none" no-caret>
                <template #button-content>
                  <span class="p-0 px-1 d-block">
                    <b-icon icon="three-dots"></b-icon>
                  </span>
                </template>
                <b-dropdown-item @click="$refs.createteamfolderinput.show()"><span>New folder</span></b-dropdown-item>
                <b-dropdown-divider></b-dropdown-divider>
                <b-dropdown-group header="Sort">
                  <b-dropdown-item @click="sort('az')"><span :class="{ 'text-primary': $store.state.folderSort == 'az' }">A-Z</span></b-dropdown-item>
                  <b-dropdown-item @click="sort('count')"><span :class="{ 'text-primary': $store.state.folderSort == 'count' }">Most used first</span></b-dropdown-item>
                  <b-dropdown-item @click="sort('date')"><span :class="{ 'text-primary': $store.state.folderSort == 'date' }">Latest first</span></b-dropdown-item>
                </b-dropdown-group>
                <b-dropdown-divider></b-dropdown-divider>
                <b-dropdown-item @click="$root.$emit('openHelp')"><span>Help</span></b-dropdown-item>
              </b-dropdown>
            </div>
          </span>
          <CreateFolderInput ref="createteamfolderinput" type="team" />
          <SidebarFolderLink :key="folder.uuid" v-for="folder in folders.team" :folder="folder" />
          <div
            v-if="!$store.state.user.subscription && !$store.state.user.parent_subscription"
            class="p-4 my-4 text-center rounded cursor-pointer bg-warning-lighter text-warning"
            @click="$root.$emit('showPaywall')"
          >
            <p class="text-center">
              <b-icon icon="lock-fill"></b-icon>
            </p>
            You are on our Free plan. Please upgrade your account for team folders.
          </div>
        </div>
        <div class="section">
          <router-link :to="{ name: 'Archives' }" class="p-2 text-left d-flex align-items-center btn">
            <b-icon fontScale=".98" class="mr-2" icon="archive"></b-icon>
            Archived
          </router-link>
        </div>
        <b-button @click="$root.$emit('openApps')" variant="background" class="p-4 mt-auto text-center rounded bg-body-bg d-flex flex-column align-items-center" v-if="!$root.isElectron()" block>
          <!-- <b-icon class="mb-2 text-primary" icon="window"></b-icon> -->
          <img class="my-3 visible-light" src="@/common/assets/img/devices_icon.svg" width="72" alt="" />
          <img class="my-3 visible-dark" src="@/common/assets/img/devices_icon_dark.svg" width="72" alt="" />
          Install typedesk for<br />Windows/Mac today!
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
// import Notifications from "@/common/components/Notifications"
import SidebarFolderLink from "@/common/components/SidebarFolderLink"
import CreateFolderInput from "@/common/components/CreateFolderInput"
import Avatar from "@/common/components/Avatar"

import { post } from "@/common/api"
let listener
export default {
  data() {
    return {
      showMenu: false,
      newColor: null,
      newFolderName: null,
      currentlyEditedFolder: null,
      showFolderInput: false,
      animateBookmarks: false,
      version: require("./../../../package.json").version,
      showDebug: false,
    }
  },
  components: {
    // Notifications,
    CreateFolderInput,
    Avatar,
    SidebarFolderLink,
  },
  destroyed() {
    document.removeEventListener("keydown", e => listener(e))
  },

  methods: {
    openWhatsNew() {
      this.$store.dispatch("last_seen_clv", this.$store.state.last_available_clv)
      this.$root.$emit("openUrl", "https://www.typedesk.com/what-s-new")
    },
    openGetStarted() {
      post("me/prop/hide_get_started_notification").then(ret => {
        let user = this.$store.state.user
        user.props.push(ret.data)
        this.$store.dispatch("user", user)
      })
      this.$root.$emit("openUrl", "https://www.typedesk.com/help/questions/faq/get-started-with-typedesk")
    },
    createResponse() {
      if (this.$route.params.uuid) {
        this.$router.push({
          name: "CreateResponse",
          params: {
            folder: this.$route.params.uuid,
          },
        })
      } else {
        this.$router.push({ name: "CreateResponse" })
      }
    },
    sort(order) {
      this.$store.dispatch("folderSort", order)
    },
    openSession() {
      this.$root.openSession().catch(e => {
        console.log(e)
      })
    },
    resetVisualState() {
      this.showFolderInput = false
      this.showDebug = false
    },
    showUserMenu() {
      if (this.$refs.userDropdown) {
        this.$refs.userDropdown.show()
      }
    },
    openMenu() {
      this.showMenu = true
    },
  },
  mounted() {
    let el = document.querySelector(".sidebar .router-link-active")
    if (el) {
      var rect = el.getBoundingClientRect()
      var elemTop = rect.top
      var elemBottom = rect.bottom

      // Only completely visible elements return true:
      var isVisible = elemTop >= 0 && elemBottom <= window.innerHeight
      // console.log(isVisible)
      if (el && !isVisible) {
        el.scrollIntoView()
      }
    }
  },
  watch: {
    showFolderInput(newValue) {
      if (newValue === true) {
        this.$nextTick(() => {
          this.$refs.folderinput[0].focus()
        })
      }
    },
    currentlyEditedFolder() {
      this.showFolderInput = false
    },
    $route() {
      this.showFolderInput = false
    },
  },
  computed: {
    bookmarks() {
      return this.$store.state.bookmarks
    },
    folders() {
      return this.$store.getters.sortedFolders
    },
    notifyNewVersion() {
      var cmp = require("semver-compare")
      return (!this.$store.state.last_seen_clv && this.$store.state.last_available_clv) ||
        (this.$store.state.last_available_clv && cmp(this.$store.state.last_available_clv, this.$store.state.last_seen_clv) > 0)
        ? true
        : false
    },
    notifyGetStarted() {
      let maxDate = new Date(this.$store.state.user.created_at)
      let today = new Date()
      maxDate.setDate(maxDate.getDate() + 7)
      return today < maxDate && this.$store.state.user && !this.$store.state.user.props.some(e => e.name == "hide_get_started_notification")
    },
    hasUpdates() {
      return this.notifyNewVersion || this.notifyGetStarted
    },
  },
  beforeDestroy() {
    document.removeEventListener("keydown", e => listener(e))
    this.$root.$off("bv::dropdown::show", this.resetVisualState)
    this.$root.$off("showMenu", this.openMenu)
    // this.$root.$off("showUserMenu", this.showUserMenu)
  },
  created() {
    listener = e => {
      if (e.key == "Alt") {
        this.showDebug = true
      }
    }
    document.addEventListener("keydown", e => listener(e))
    // document.addEventListener("keyup", e => listener(e, false))
    this.$root.$on("bv::dropdown::show", this.resetVisualState)

    // this.$root.$on("showUserMenu", this.showUserMenu)
    this.$root.$on("showMenu", this.openMenu)
    this.$store.dispatch("current_clv", this.version)
  },
}
</script>

<style lang="scss" scoped>
@import "@/common/assets/css/_variables.scss";
@import "@/common/assets/css/components/_vueswatches.scss";
@import "@/common/assets/css/mixins";

$bgColor: var(--color-primary-background);

.scroll-container {
  @include media-breakpoint-up(sm) {
    margin-top: 20px;
    &.mac {
      margin-top: 40px;
    }
    // padding: 0 $spacer 0 $spacer;
  }
  @include media-breakpoint-up(lg) {
    padding: 0 $spacer * 1.5 0 $spacer * 1.5;
  }
}

@include scroll;

.sidebar {
  border-right: 1px solid var(--color-border);
  background: var(--sidebar-bg);
  @include media-breakpoint-down(md) {
    width: 100vw;
  }
  &.windows {
    background: var(--color-background);
  }
  &__inner {
    height: auto !important;
    visibility: visible;
    @include media-breakpoint-down(sm) {
      width: 100vw;
    }
  }

  .menu {
    display: none;
    @include media-breakpoint-down(md) {
      display: block;
    }
  }

  @include media-breakpoint-down(md) {
    // background: white;
    position: fixed;
    z-index: 100;
    width: 400px;
    transform: translateX(calc(-400px));
    &__inner {
      padding: $spacer * 1.5;
    }
    // &:hover,
    &.show {
      transform: translateX(0);
      // .menu {
      //   display: none;
      // }
    }
  }
}

.unlock {
  display: block;
  width: 24px;
  height: 24px;
  background: var(--color-yellow-light);
  color: var(--color-yellow-dark);
  border-radius: 100px;
  padding: 6px;
  stroke-width: 3px;
}

.section .title {
  letter-spacing: 0.2rem;
  font-size: 0.75rem;
  height: 24px;
}

::v-deep .section > a {
  cursor: default;
  margin: 2px 0;
  &:hover {
    background: $bgColor;
  }
}

::v-deep .router-link-exact-active,
::v-deep .active {
  background: $bgColor;
  // color: var(--color-primary);

  &:hover {
    // color: var(--color-primary);
    background: $bgColor !important;
  }
}

::v-deep a.folder {
  cursor: default;
  margin: 2px 0;
  &:hover {
    background: $bgColor;
  }
}

::v-deep .folder:hover #folder-dropdown .dropdown-toggle {
  visibility: visible;
}

::v-deep .folder:not(.router-link-active):not(:hover) #folder-dropdown .dropdown-toggle {
  visibility: hidden;
}

.color {
  width: 14px;
  height: 14px;
  border-radius: 50px;
}
::v-deep .badge {
  min-width: 30px;
  margin-top: 2px;
  font-weight: 500;
  vertical-align: middle;
}

.name {
  white-space: nowrap;
  flex-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--color-default);
}
.plan {
  color: var(--color-default);
  opacity: 0.5;
}

.section {
  margin-bottom: $spacer * 2;
}
::v-deep .color-dropdown {
  height: 14px;
  padding: 0;
}
::v-deep .color-dropdown .btn {
  height: 14px;
  border: none;
  display: flex;
  align-items: center;
  &:hover {
    opacity: 0.8;
  }
}

::v-deep .color-dropdown .dropdown-menu {
  padding: 1rem;
}

.shrink {
  white-space: nowrap;
  flex-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.header {
  max-width: 80%;
}
.trial {
  color: var(--color-yellow-dark);
  background-color: var(--color-yellow-light);
  transition: background 0.2s ease;
  &:hover {
    background-color: var(--color-yellow-light-hover);
  }
}
.trialprogress {
  width: 100%;
  height: 8px;
  border-radius: 50px;
  border: 1px solid var(--color-yellow-dark);
  position: relative;
  overflow: hidden;
  span {
    border-radius: 50px;
    // box-shadow: inset 0 0 0px 3px var(--body-bg);
    display: block;
    // opacity: 0.5;
    background: var(--color-yellow-dark);
    height: calc(100%);
    // margin: 3px;
    max-width: 90%;
  }
}

::v-deep {
  .glow {
    animation: glow 1.4s forwards;
    position: relative;
    &:after {
      content: "";
      display: block;
      transition-delay: -100ms;
      width: 70px;
      height: 70px;
      border-radius: 50px;
      border: 2px solid var(--color-warning);
      position: absolute;
      left: calc(50% - 35px);
      top: calc(50% - 35px);
      animation: inflate 1s linear forwards;
    }
  }
  .notify {
    display: inline-block;
    vertical-align: middle;
    background: var(--color-danger);
    width: 6px;
    height: 6px;
    margin-left: 5px;
    border-radius: 40px;
  }
  @keyframes glow {
    from {
      background: var(--white);
      transform: scale(0);
    }
    25% {
      background: var(--color-warning);
      transform: scale(1.2);
      color: white;
    }
    50% {
      transform: scale(0.9);
      color: white;
    }
    75% {
      background: var(--color-warning);
      transform: scale(1.05);
      color: white;
      opacity: 1;
    }
    to {
      transform: scale(1);
      background: var(--white);
    }
  }
  @keyframes inflate {
    from {
      opacity: 0;
    }
    15% {
      opacity: 1;
    }
    25% {
      opacity: 0;
    }
    to {
      opacity: 0;
    }
  }
}
</style>
