<template>
  <div>
    <b-dropdown right @shown="markAsRead()" boundary="window" variant="hover" id="notifications" toggle-class="text-decoration-none px-1 p-0 ml-2" no-caret>
      <template #button-content>
        <span v-if="hasUnread" class="unread"></span>
        <b-icon icon="bell"></b-icon>
      </template>
      <div class="p-3" v-if="($store.state.user.notifications && $store.state.user.notifications.length > 0) || $store.state.user.invitations_count > 0">
        <div class="notification">
          <div class="py-1 px-3">
            <div v-if="$store.state.user.invitations_count > 0">
              <span>You have pending invitations on your account</span>
              <div class="d-flex align-items-center justify-content-between">
                <a href="#" @click.stop.prevent="$router.push({ name: 'Invitations' })">See my invitations</a>
              </div>
              <b-dropdown-divider v-if="$store.state.user.notifications && $store.state.user.notifications.length > 0" />
            </div>
            <div :key="notification.id" v-for="(notification, key) in $store.state.user.notifications">
              <div v-if="notification.type == 'App\\Notifications\\AcceptInvitation'">
                <span v-if="notification.data.folder">{{ notification.data.name }} has joined your shared folder "{{ notification.data.folder }}"</span>
                <span v-else>{{ notification.data.name }} has joined your team. You can also invite him/her to any folder.</span>
                <span class="text-muted d-block small">{{ beautifulDate(notification.created_at) }}</span>
              </div>
              <!-- <div v-if="notification.type == 'App\\Notifications\\ReceiveInvitation'">
              <span>{{ notification.data.name }} has invited you to a shared folder "{{ notification.data.folder }}" </span>
              <div class="d-flex align-items-center justify-content-between">
                <span class="text-muted d-block small">{{ beautifulDate(notification.created_at) }}</span>
                <p>
                  <a href="#" @click.stop.prevent="$router.push({ name: 'Invitations' })">See my invitations</a>
                </p>
              </div>
            </div> -->
              <div v-if="notification.type == 'App\\Notifications\\EditRole'">
                <span>You can now edit canned responses in "{{ notification.data.folder }}"</span>
                <span class="text-muted d-block small">{{ beautifulDate(notification.created_at) }}</span>
              </div>
              <div v-if="notification.type == 'App\\Notifications\\CreateSharedResponse'">
                <span>{{ notification.data.name }} has created "{{ notification.data.response }}" in the shared folder "{{ notification.data.folder }}"</span>
                <span class="text-muted d-block small">{{ beautifulDate(notification.created_at) }}</span>
              </div>
              <div v-if="notification.type == 'App\\Notifications\\LeaveSharedFolder'">
                <span>{{ notification.data.name }} has left your shared folder "{{ notification.data.folder }}"</span>
                <span class="text-muted d-block small">{{ beautifulDate(notification.created_at) }}</span>
              </div>
              <div v-if="notification.type == 'App\\Notifications\\StartImportProcess'">
                <span>We are importing your old data. This may take a few minutes. We'll send you a notification when we're done.</span>
                <span class="text-muted d-block small">{{ beautifulDate(notification.created_at) }}</span>
              </div>
              <div v-if="notification.type == 'App\\Notifications\\FinishImportProcess'">
                <span>We have finished importing your data. Please reload / restart typedesk.</span>
                <span class="text-muted d-block small">{{ beautifulDate(notification.created_at) }}</span>
              </div>
              <div v-if="notification.type == 'App\\Notifications\\ResponseUpdate'">
                <section v-if="notification.data.type == 'name'">
                  <span>{{ notification.data.name }} has changed the name of "{{ notification.data.previous_value }}" to "{{ notification.data.new_value }}" in "{{ notification.data.folder }}"</span>
                </section>
                <section v-if="notification.data.type == 'shortcut'">
                  <span>{{ notification.data.name }} has changed the shortcut of "{{ notification.data.response }}" to "{{ notification.data.new_value }}" in "{{ notification.data.folder }}"</span>
                </section>
                <section v-if="notification.data.type == 'private_note'">
                  <span>{{ notification.data.name }} has updated the private note on "{{ notification.data.response }}": "{{ notification.data.new_value }}"</span>
                </section>
                <section v-else>
                  <span>{{ notification.data.name }} has updated "{{ notification.data.response }}" in "{{ notification.data.folder }}"</span>
                </section>
                <span class="text-muted d-block small">{{ beautifulDate(notification.created_at) }}</span>
              </div>
              <hr class="my-2" v-if="key != Object.keys($store.state.user.notifications).length - 1" />
            </div>
          </div>
        </div>
      </div>
      <div class="text-muted p-5 text-center" v-else>
        <!-- <b-icon fontScale="2" class="mb-2" icon="brightness-high"></b-icon> -->
        <img src="@/common/assets/img/sun.svg" width="48" class="sun mb-2" alt="" />
        <p class="m-0">You are all caught up</p>
      </div>
    </b-dropdown>
    <b-alert v-model="showError" class="position-fixed fixed-bottom m-0 rounded-0" style="z-index: 2000" variant="danger" dismissible fade>
      {{ error }}
      <template #dismiss>
        <b-icon icon="x"></b-icon>
      </template>
    </b-alert>
  </div>
</template>

<script>
import { get, post } from "@/common/api"
var prettydate = require("pretty-date")

let interval = null
const period = 1000 * 60 * 2
export default {
  data() {
    return {
      error: null,
      showError: null,
    }
  },
  created() {
    interval = setInterval(this.fetchNotifications, period)
  },
  methods: {
    fetchNotifications() {
      if (this.$store.state.user) {
        get("session/notifications")
          .then(ret => {
            let user = this.$store.state.user
            user.notifications = ret.data
            this.$store.dispatch("user", user)
          })
          .catch(e => {
            console.info(e)
          })
      }
    },
    beautifulDate(str) {
      let date = new Date(Date.parse(str))
      return prettydate.format(date)
    },
    markAsRead() {
      clearInterval(interval)
      let oldUser = JSON.parse(JSON.stringify(this.$store.state.user))
      let user = JSON.parse(JSON.stringify(this.$store.state.user))

      user.notifications.forEach(e => {
        e.read_at = true
      })
      this.$store.dispatch("user", user)
      post("notifications/acknowledge")
        .then(() => {
          interval = setInterval(this.fetchNotifications, period)
        })
        .catch(e => {
          this.error = e
          this.showError = true
          this.$store.dispatch("user", oldUser)
          interval = setInterval(this.fetchNotifications, period)
        })
    },
  },
  computed: {
    hasUnread() {
      return (this.$store.state.user.notifications && this.$store.state.user.notifications.some(e => e.read_at == null)) || this.$store.state.user.invitations_count > 0
    },
  },
  destroyed() {
    clearInterval(interval)
  },
}
</script>

<style lang="scss" scoped>
::v-deep .dropdown-menu {
  max-width: 400px;
  min-width: 300px;
  max-height: 300px;
  overflow: auto;
}

// .notification {
//   opacity: 0.5;
//   &.active {
//     // background: var(--color-warning-lighter);
//     opacity: 1;
//   }
// }

hr {
  border-top-color: var(--color-background);
}

.unread {
  background: var(--color-danger);
  width: 6px;
  height: 6px;
  border-radius: 40px;
  position: absolute;
  right: 0px;
  top: 0px;
}

.sun {
  animation: enter 0.5s ease-out;
}

@keyframes enter {
  from {
    opacity: 0;
    transform: scale(0.9) rotate(50deg);
  }
}

::v-deep .btn {
  position: relative;
}
</style>
