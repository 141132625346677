<template>
  <div class="variantavatar">
    <img class="d-block" v-if="variant.flag" :src="require('@/common/assets/img/flag-' + variant.flag + '.svg')" alt="" />
    <span v-else alt="">{{ getVariantInitials(variant.name) }}</span>
  </div>
</template>

<script>
export default {
  props: {
    variant: {
      type: Object,
    },
  },
  methods: {
    getVariantInitials() {
      if (this.variant.name.length >= 1) {
        return this.variant.name.substr(0, 1)
      } else {
        return this.variant.name
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.variantavatar span {
  width: 20px;
  user-select: none;
  height: 20px;
  font-weight: 500;
  background: var(--color-primary-light);
  color: var(--color-primary);
  font-size: 12px;
  display: flex;
  line-height: 1;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  border-radius: 50px;
}
.variantavatar img {
  width: 20px;
  height: 20px;
}
</style>