<template>
  <div class="bar bg-body-bg border-bottom border-border d-flex flex-wrap align-items-center justify-content-start px-4 py-2 w-100">
    <!-- <section class="mx-2 d-flex align-items-center"> -->
    <MarkButton format="bold" icon="type-bold" shortcut="b" />
    <MarkButton format="italic" icon="type-italic" shortcut="i" />
    <MarkButton format="underline" icon="type-underline" shortcut="u" />
    <MarkButton format="strikethrough" icon="type-strikethrough" />
    <MarkButton format="code" icon="code-square" />
    <FontSelector />
    <!-- <b-button :class="{ active: isMarkActive('bold') }" @click="$emit('toggleMark', 'bold')" variant="light">
        <b-icon icon="type-bold"></b-icon>
      </b-button> -->
    <!-- <b-button variant="light">
        <b-icon icon="type-italic"></b-icon>
      </b-button> -->
    <!-- </section>
    <section class="mx-2 d-flex align-items-center"> -->
    <BlockButton format="h1" icon="type-h1" />
    <BlockButton format="h2" icon="type-h2" />
    <BlockButton format="h3" icon="type-h3" />
    <BlockButton format="bulleted-list" icon="list-ul" />
    <BlockButton format="numbered-list" icon="list-ol" />
    <SizeSelector />
    <!-- <b-button variant="light">
        <b-icon icon="type-h1"></b-icon>
      </b-button>
      <b-button variant="light">
        <b-icon icon="type-h2"></b-icon>
      </b-button>
      <b-button variant="light">
        <b-icon icon="type-h3"></b-icon>
      </b-button> -->
    <!-- </section>
    <section class="d-flex align-items-center mx-2"> -->
    <MarkButton title="Remove formatting" format="clear" icon="BIconXCircle" />
    <!-- </section>
    <section class="d-flex align-items-center mx-2"> -->
    <LinkButton />
    <ColorButton />
    <ImageButton />

    <!-- <b-button variant="light">
        <b-icon icon="justify-left"></b-icon>
      </b-button>
      <b-button variant="light">
        <b-icon icon="text-center"></b-icon>
      </b-button>
      <b-button variant="light">
        <b-icon icon="justify-right"></b-icon>
      </b-button> -->
    <!-- </section> -->
  </div>
</template>

<script>
// const isMarkActive = (editor, format) => {
//   const marks = Editor.marks(editor)
//   return marks ? marks[format] === true : false
// }
import MarkButton from "@/common/components/Editor/MarkButton"
import LinkButton from "@/common/components/Editor/LinkButton"
import BlockButton from "@/common/components/Editor/BlockButton"
import ColorButton from "@/common/components/Editor/ColorButton"
import ImageButton from "@/common/components/Editor/ImageButton"
import FontSelector from "@/common/components/Editor/FontSelector"
import SizeSelector from "@/common/components/Editor/SizeSelector"

// import { Editor } from "slate"

export default {
  components: {
    MarkButton,
    FontSelector,
    SizeSelector,
    BlockButton,
    ColorButton,
    ImageButton,
    LinkButton,
  },
}
</script>

<style lang="scss" scoped>
@import "../../assets/css/mixins";
@import "../../assets/css/variables";

.bar {
  // height: 60px;
  // min-height: 60px;
  z-index: 100;
  // box-shadow: 0 10px 20px rgba($color-default, 0.05);
}
div.bar > * {
  margin-right: $spacer / 3 !important;
}
::v-deep .rounded,
::v-deep .custom-select {
  border-radius: 4px !important;
}
// ::v-deep .btn:not(.btn-link),
// ::v-deep .btn:not(.btn-block),
::v-deep .formatbarbtn,
::v-deep .dropdown-toggle .btn,
::v-deep .dropdown-toggle.btn {
  height: 32px;
  min-height: 32px;
  width: 32px;
  min-width: 32px;
  padding: 0;
  border-radius: 4px;

  .b-icon {
    font-size: 110% !important;
  }

  &.active {
    background: var(--color-primary-light) !important;
    border-color: var(--color-primary) !important;
    color: var(--color-primary) !important;
  }
}
::v-deep section .btn-background {
  width: 40px;
  height: 40px;
}
</style>
