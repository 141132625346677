<template>
  <div>
    <span @click="$root.$emit('insertEditorVariable', { name, label }, false, block)" class="variable">{{ label }}</span>
  </div>
</template>

<script>
export default {
  props: {
    name: String,
    label: String,
    block: {
      type: Boolean,
      default: false,
    },
  },
}
</script>


