<template>
  <div class="responses bg-body-bg" v-if="$store.state.responses">
    <ResponseList
      class="border-right border-border"
      ref="list"
      :searchPlaceholder="searchPlaceholder"
      @search="searchStr = $event"
      @selectResponse="currentResponse = $event"
      :currentResponse="currentResponse"
      :responses="responses"
      :type="type"
      :shouldScroll="shouldScroll"
      @click.native="shouldScroll = false"
      @showPreview="showPreview = true"
    />

    <Preview @hidePreview="showPreview = false" :class="{ visible: showPreview }" v-if="currentResponse" :response="currentResponse"></Preview>
  </div>
</template>

<script>
import ResponseList from "@/common/components/ResponseList"
import Preview from "@/common/components/Preview"
import { Search } from "@/common/search"

let previousKey = null

export default {
  data() {
    return {
      currentResponse: null,
      searchStr: null,
      shouldScroll: false,
      showPreview: false,
    }
  },
  components: {
    ResponseList,
    Preview,
  },
  props: {
    type: {
      type: String,
      default: null,
    },
  },
  mounted() {
    window.addEventListener("keydown", this.keydown, true)
    this.$root.$on("bv::modal::shown", this.removeListener)
    this.$root.$on("bv::modal::hidden", this.addListener)
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this.keydown, true)
    this.$root.$off("bv::modal::shown", this.removeListener)
    this.$root.$off("bv::modal::hidden", this.addListener)
  },
  methods: {
    addListener(bvEvent, modalId) {
      if (modalId == "buildResponse") {
        window.addEventListener("keydown", this.keydown, true)
      }
    },
    removeListener(bvEvent, modalId) {
      if (modalId == "buildResponse") {
        window.removeEventListener("keydown", this.keydown, true)
      }
    },
    keydown(e) {
      let code = e.keyCode
      let index = this.responses.findIndex(e => e.uuid == this.currentResponse.uuid)
      if (index >= 0) {
        if (code == 40 || code == "ArrowDown") {
          this.shouldScroll = true
          let newIndex = Math.min(index + 1, this.responses.length - 1)
          this.currentResponse = this.responses[newIndex]
          document.querySelector(".response.active").scrollIntoView({ block: "start", inline: "nearest", behavior: "smooth" })
          e.preventDefault()
          e.stopPropagation()
        }
        if (code == 38 || code == "ArrowUp") {
          this.shouldScroll = true
          let newIndex = Math.max(index + -1, 0)
          this.currentResponse = this.responses[newIndex]
          document.querySelector(".response.active").scrollIntoView({ block: "end", inline: "nearest", behavior: "smooth" })
          e.preventDefault()
          e.stopPropagation()
        }
        if ((code == 13 || code == "Enter") && ((e.target.tagName !== "INPUT" && e.target.tagName !== "TEXTAREA") || e.target.id == "search")) {
          this.$root.$emit("selectResponse", this.currentResponse.uuid)
          e.preventDefault()
          e.stopPropagation()
        }
        if (this.$store.state.shortcut.length && code == this.$store.state.shortcut[1].keyCode && previousKey == this.$store.state.shortcut[0].keyCode) {
          e.preventDefault()
          e.stopPropagation()
          if (this.$refs.list) this.$refs.list.focusSearch()
        }
        previousKey = code
      }
    },
  },
  computed: {
    searchPlaceholder() {
      let searchPlaceholder = "Search in all responses (" + this.$store.getters.shortcutString + ")"
      if (this.type == "bookmarks") {
        searchPlaceholder = this.$options.filters.truncate("Search in Bookmarks", 17) + " (" + this.$store.getters.shortcutString + ")"
      }
      if (this.type == "folder") {
        let folder = this.$store.state.folders.owned
          .concat(this.$store.state.folders.shared)
          .concat(this.$store.state.folders.team)
          .find(elm => {
            return elm.uuid == this.$route.params.uuid
          })
        if (folder) {
          searchPlaceholder = this.$options.filters.truncate("Search in " + folder.name, 17) + " (" + this.$store.getters.shortcutString + ")"
        }
      }
      return searchPlaceholder
    },
    responses() {
      let responses = this.$store.state.responses

      if (responses) {
        if (this.type == "bookmarks") {
          responses = this.$store.state.bookmarks
        }

        if (this.type == "archives") {
          responses = this.$store.state.archives
        }

        if (this.type == "folder") {
          responses = this.$store.state.responses.filter(elm => {
            if (elm.folder) {
              return elm.folder.uuid == this.$route.params.uuid
            }
          })
        }
        if (this.searchStr && this.searchStr.length > 0) {
          responses = Search(this.searchStr, responses).map(e => e.response)
        } else {
          switch (this.$store.state.sort) {
            case "alpha":
              responses = responses.sort((a, b) => {
                return a.name.localeCompare(b.name, "en", {
                  ignorePunctuation: true,
                })
              })
              break
            case "date":
              responses = responses.sort((a, b) => {
                return Date.parse(a.created_at) > Date.parse(b.created_at) ? -1 : 1
              })
              break
            case "lastuse":
              responses = responses.sort((a, b) => {
                return a.last_used_at > b.last_used_at ? -1 : 1
              })
              break
            case "popularity":
              responses = responses.sort((a, b) => {
                return a.hits > b.hits ? -1 : 1
              })
              break

            default:
          }
        }
        return responses
      }
      return []
    },
  },
  created() {
    if (this.$route.params.response) {
      this.currentResponse = this.$store.state.responses.find(e => {
        return e.uuid == this.$route.params.response
      })
    }
  },
  watch: {
    searchStr() {
      this.currentResponse = this.responses[0] || null
    },
    responses: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (this.currentResponse) {
          let currentResponseNew = this.responses.find(e => {
            return e.uuid == this.currentResponse.uuid
          })
          if (currentResponseNew) {
            this.currentResponse = currentResponseNew
          } else {
            this.currentResponse = newValue[0]
          }
        } else {
          this.currentResponse = newValue[0]
        }
      },
    },
  },
}
</script>

<style lang="scss">
@import "@/common/assets/css/_variables.scss";
@import "@/common/assets/css/mixins.scss";
.responses {
  display: grid;
  grid-template-columns: 380px auto;
  height: 100vh;
  // @include media-breakpoint-down(md) {
  //   padding-left: 40px;
  // }

  @include media-breakpoint-down(lg) {
    grid-template-columns: 300px auto;
  }

  @include media-breakpoint-down(md) {
    display: block;
    width: 100vw;
  }

  @include media-breakpoint-up(xl) {
    grid-template-columns: 400px auto;
  }
}
</style>
