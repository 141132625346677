<template>
  <div class="toggle" :class="{ disabled: disabled }">
    <input :disabled="disabled" :checked="value" @input="handleInput" @change="change" :id="id" type="checkbox" />
    <label :for="id"
      ><slot></slot>
      <span></span>
    </label>
  </div>
</template>

<script>
export default {
  data() {
    return {
      id: null,
    }
  },
  name: "toggle",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
    },
  },
  methods: {
    handleInput(e) {
      this.$emit("input", e.target.checked)
    },
    change(e) {
      this.$emit("change", e.target.checked)
    },
  },
  created() {
    this.id = Math.random().toString(36).substring(7)
  },
}
</script>
