<template>
  <div>
    <div id="clipboard" class="clipboard"></div>
  </div>
</template>

<script>
import { copy, restore } from "@/clipboard.js"

export default {
  created() {
    this.$root.$on("copyToClipboard", copy)
    this.$root.$on("restoreClipboard", restore)
  },
  beforeDestroy() {
    this.$root.$off("copyToClipboard", copy)
    this.$root.$off("restoreClipboard", restore)
  },
}
</script>

<style lang="scss" scoped>
::v-deep #clipboard {
  opacity: 0;
  position: absolute;
  left: -99999px;
  top: -99999px;
  vertical-align: baseline;
  font-weight: 400;
  font-family: sans-serif;
  font-style: inherit;
  font-size: 14px;
  line-height: 16px;
  border: none;
  outline: 0;
  padding: 0;
  margin: 0;

  b,
  p b,
  strong {
    font-weight: 600 !important;
  }
  p {
    margin: 0 !important;
    line-height: 1.5;
  }
}
</style>
