import Variable from "@/common/components/Editor/VariableRender.vue"
import EditableImage from "@/common/components/Editor/EditableImage.vue"

export const renderLeaf = ({ attributes, children, leaf }) => {
  return {
    render() {
      if (leaf.bold) {
        children = <strong style="font-weight: 600">{children}</strong>
      }

      if (leaf.code) {
        children = <code>{children}</code>
      }

      if (leaf.italic) {
        children = <em>{children}</em>
      }

      if (leaf.underline) {
        children = <u>{children}</u>
      }

      if (leaf.code) {
        children = <code>{children}</code>
      }

      if (leaf.strikethrough) {
        children = <strike>{children}</strike>
      }

      if (leaf.size) {
        children = (
          <span {...{ attrs: attributes }} style={{ fontSize: leaf.size + "px" }}>
            {children}
          </span>
        )
      }

      if (leaf.color) {
        children = (
          <span {...{ attrs: attributes }} style={{ color: leaf.color.value }}>
            {children}
          </span>
        )
      }

      if (leaf.font) {
        children = (
          <span {...{ attrs: attributes }} style={{ fontFamily: leaf.font }}>
            {children}
          </span>
        )
      }

      return <span {...{ attrs: attributes }}>{children}</span>
    },
  }
}

export const renderElement = ({ attributes, children, element }) => {
  return {
    render() {
      let label = element.label
      if ((element.name == "text" || element.name == "select") && element.params && element.params.length) {
        label = element.params[0].value
      }
      switch (element.type) {
        case "block-quote":
          return <blockquote {...{ attrs: attributes }}>{children}</blockquote>
        case "bulleted-list":
          return <ul {...{ attrs: attributes }}>{children}</ul>
        case "variable":
          return (
            <Variable params={element.params} uuid={element.uuid} name={element.name} label={label} {...{ attrs: attributes }}>
              {children}
            </Variable>
          )
        case "blockvariable":
          return (
            <div class="blockvariable" contenteditable="false">
              <span>{label}</span>
              {children}
            </div>
          )
        case "image":
          return (
            <EditableImage url={element.params.url} link={element.params.link} size={element.params.width} {...{ attrs: attributes }}>
              {children}
            </EditableImage>
          )
        // return <img src={element.params.url} {...{ attrs: attributes }} />
        case "h1":
          return <h1 {...{ attrs: attributes }}>{children}</h1>
        case "h2":
          return <h2 {...{ attrs: attributes }}>{children}</h2>
        case "h3":
          return <h3 {...{ attrs: attributes }}>{children}</h3>
        case "link":
          return (
            <span class="text-primary" style="text-decoration: underline" {...{ attrs: attributes }}>
              {children}
            </span>
          )
        case "list-item":
          return <li {...{ attrs: attributes }}>{children}</li>
        case "span":
          return <span>{children}</span>
        case "numbered-list":
          return <ol {...{ attrs: attributes }}>{children}</ol>
        default:
          return <p {...{ attrs: attributes }}>{children}</p>
      }
    },
  }
}
