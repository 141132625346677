import Vue from "vue"
// import * as Sentry from "@sentry/vue"
// import { Integrations } from "@sentry/tracing"
import App from "@/App.vue"
import router from "@/common/router"
import store from "@/common/store"
import { get } from "@/common/api"
import "@/common/assets/css/app.scss"
import "@/common/functions.js"
// import VueGtm from "@gtm-support/vue2-gtm"
import { SlatePlugin } from "slate-vue"
import { withHistory } from "slate-history"
// import Vue from "vue"

window.store = store;

// let gtm_auth
// let gtm_preview

// if (process.env.NODE_ENV == "production") {
//   gtm_auth = "_Q-mCfuElFUQcSl9LBv6ig"
//   gtm_preview = "env-59"
// } else {
//   gtm_auth = "OoqGpoOxhhKFObPnlbBzSQ"
//   gtm_preview = "env-22"
// }

// Vue.use(VueGtm, {
//   id: [
//     {
//       id: process.env.VUE_APP_GTM_ID,
//       queryParams: {
//         gtm_auth,
//         gtm_preview,
//         gtm_cookies_win: "x",
//       },
//     },
//   ], // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy'] or array of objects [{id: 'GTM-xxxxxx', queryPararms: { gtm_auth: 'abc123', gtm_preview: 'env-4', gtm_cookies_win: 'x'}}, {id: 'GTM-yyyyyy', queryParams: {gtm_auth: 'abc234', gtm_preview: 'env-5', gtm_cookies_win: 'x'}}]
//   defer: false, // defaults to false. Script can be set to `defer` to increase page-load-time at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible)
//   enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
//   debug: store.state.debug, // Whether or not display console logs debugs (optional)
//   loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
//   vueRouter: router, // Pass the router instance to automatically sync with router (optional)
//   ignoredViews: [], // Don't trigger events for specified router names (case insensitive) (optional)
//   trackOnNextTick: false, // Whether or not call trackView in Vue.nextTick
// })

Vue.use(SlatePlugin, {
  editorCreated(editor) {
    withHistory(editor)
  },
})

Vue.config.productionTip = false

var filter = function (text, length, clamp) {
  clamp = clamp || "..."
  var node = document.createElement("div")
  node.innerHTML = text
  var content = node.textContent
  return content.length > length ? content.slice(0, length) + clamp : content
}
Vue.filter("truncate", filter)

import {
  ModalPlugin,
  ButtonPlugin,
  FormSelectPlugin,
  TabsPlugin,
  CollapsePlugin,
  DropdownPlugin,
  FormCheckboxPlugin,
  FormTextareaPlugin,
  AlertPlugin,
  FormPlugin,
  ToastPlugin,
  FormInputPlugin,
  FormRadioPlugin,
  FormGroupPlugin,
  CardPlugin,
  TooltipPlugin,
  BadgePlugin,
  BIcon,
  BIconX,
  BIconArrowLeft,
  BIconMailbox,
  BIconBox,
  BIconFolderPlus,
  BIconEnvelope,
  BIconGripVertical,
  BIconThreeDots,
  BIconBell,
  BIconBrightnessHigh,
  BIconShieldCheck,
  BIconCheck,
  BIconMoon,
  BIconUnlock,
  BIconArrowReturnLeft,
  BIconPencil,
  BIconLightningFill,
  BIconBookmarkPlus,
  BIconBookmarkCheckFill,
  BIconSearch,
  BIconSortDown,
  BIconArrowUpSquareFill,
  BIconLockFill,
  BIconList,
  BIconWindow,
  BIconBookmarkStar,
  BIconArchive,
  BIconShare,
  BIconDroplet,
  BIconTypeBold,
  BIconTypeItalic,
  BIconTypeUnderline,
  BIconTypeH1,
  BIconTypeH2,
  BIconTypeH3,
  BIconLink,
  BIconJustifyLeft,
  BIconTextCenter,
  BIconJustifyRight,
  BIconImage,
  BIconFileMinus,
  BIconPlus,
  BIconDash,
  BIconArrowRight,
  BIconPen,
  BIconTrash,
  BIconPlusCircle,
  BIconChevronDown,
  BIconKeyboard,
  BIconQuestionCircleFill,
  BIconCheckCircle,
  BIconFolder,
  BIconArrowRightShort,
  BIconArrowDown,
  BIconArrowDownSquareFill,
  BIconListUl,
  BIconListOl,
  FormTagsPlugin,
  BIconXCircle,
  BIconQuestionCircle,
  BIconBraces,
  BIconBoxArrowInUpRight,
  BIconBoxArrowDown,
  BIconClock,
  BIconTypeStrikethrough,
  BIconFileBreak,
  BIconGear,
  BIconCodeSquare,
} from "bootstrap-vue"

Vue.use(ModalPlugin)
Vue.use(ButtonPlugin)
Vue.use(TabsPlugin)
Vue.use(DropdownPlugin)
Vue.use(FormPlugin)
Vue.use(AlertPlugin)
Vue.use(ToastPlugin)
Vue.use(TooltipPlugin)
Vue.use(FormInputPlugin)
Vue.use(FormTagsPlugin)
Vue.use(FormRadioPlugin)
Vue.use(FormGroupPlugin)
Vue.use(CardPlugin)
Vue.use(BadgePlugin)
Vue.use(CollapsePlugin)
Vue.use(FormSelectPlugin)
Vue.use(FormTextareaPlugin)
Vue.use(FormCheckboxPlugin)

Vue.component("BIcon", BIcon)
Vue.component("BIconX", BIconX)
Vue.component("BIconBox", BIconBox)
Vue.component("BIconArrowLeft", BIconArrowLeft)
Vue.component("BIconMailbox", BIconMailbox)
Vue.component("BIconFolderPlus", BIconFolderPlus)
Vue.component("BIconEnvelope", BIconEnvelope)
Vue.component("BIconListUl", BIconListUl)
Vue.component("BIconListOl", BIconListOl)
Vue.component("BIconThreeDots", BIconThreeDots)
Vue.component("BIconBell", BIconBell)
Vue.component("BIconBrightnessHigh", BIconBrightnessHigh)
Vue.component("BIconShieldCheck", BIconShieldCheck)
Vue.component("BIconPlusCircle", BIconPlusCircle)
Vue.component("BIconCheck", BIconCheck)
Vue.component("BIconMoon", BIconMoon)
Vue.component("BIconUnlock", BIconUnlock)
Vue.component("BIconArrowReturnLeft", BIconArrowReturnLeft)
Vue.component("BIconPencil", BIconPencil)
Vue.component("BIconLightningFill", BIconLightningFill)
Vue.component("BIconBookmarkPlus", BIconBookmarkPlus)
Vue.component("BIconBookmarkCheckFill", BIconBookmarkCheckFill)
Vue.component("BIconSearch", BIconSearch)
Vue.component("BIconSortDown", BIconSortDown)
Vue.component("BIconArrowUpSquareFill", BIconArrowUpSquareFill)
Vue.component("BIconArrowDownSquareFill", BIconArrowDownSquareFill)
Vue.component("BIconArrowDown", BIconArrowDown)
Vue.component("BIconLockFill", BIconLockFill)
Vue.component("BIconList", BIconList)
Vue.component("BIconWindow", BIconWindow)
Vue.component("BIconBookmarkStar", BIconBookmarkStar)
Vue.component("BIconArchive", BIconArchive)
Vue.component("BIconShare", BIconShare)
Vue.component("BIconDroplet", BIconDroplet)
Vue.component("BIconTypeBold", BIconTypeBold)
Vue.component("BIconTypeItalic", BIconTypeItalic)
Vue.component("BIconTypeH1", BIconTypeH1)
Vue.component("BIconTypeH2", BIconTypeH2)
Vue.component("BIconTypeH3", BIconTypeH3)
Vue.component("BIconJustifyLeft", BIconJustifyLeft)
Vue.component("BIconTextCenter", BIconTextCenter)
Vue.component("BIconJustifyRight", BIconJustifyRight)
Vue.component("BIconImage", BIconImage)
Vue.component("BIconFileMinus", BIconFileMinus)
Vue.component("BIconPlus", BIconPlus)
Vue.component("BIconArrowRight", BIconArrowRight)
Vue.component("BIconPen", BIconPen)
Vue.component("BIconTrash", BIconTrash)
Vue.component("BIconChevronDown", BIconChevronDown)
Vue.component("BIconKeyboard", BIconKeyboard)
Vue.component("BIconQuestionCircleFill", BIconQuestionCircleFill)
Vue.component("BIconCheckCircle", BIconCheckCircle)
Vue.component("BIconFolderPlus", BIconFolderPlus)
Vue.component("BIconFolder", BIconFolder)
Vue.component("BIconArrowRightShort", BIconArrowRightShort)
Vue.component("BIconLink", BIconLink)
Vue.component("BIconDash", BIconDash)
Vue.component("BIconXCircle", BIconXCircle)
Vue.component("BIconGripVertical", BIconGripVertical)
Vue.component("BIconTypeUnderline", BIconTypeUnderline)
Vue.component("BIconQuestionCircle", BIconQuestionCircle)
Vue.component("BIconBraces", BIconBraces)
Vue.component("BIconBoxArrowInUpRight", BIconBoxArrowInUpRight)
Vue.component("BIconBoxArrowDown", BIconBoxArrowDown)
Vue.component("BIconClock", BIconClock)
Vue.component("BIconTypeStrikethrough", BIconTypeStrikethrough)
Vue.component("BIconFileBreak", BIconFileBreak)
Vue.component("BIconGear", BIconGear)
Vue.component("BIconCodeSquare", BIconCodeSquare)

global.vm = new Vue({
  router,
  store,
  render: h => h(App),
  async created() {
    await store.restored

    this.$store.dispatch("debug", false)

    this.$root.$on("logout", () => {
      this.$store.dispatch("logout").then(() => {
        if (this.$route.name !== "Login") {
          this.$router.replace({ name: "Login" })
        }
      })
    })
    document.querySelector("body").classList.add("theme-" + this.$store.state.theme)
    this.$root.$on("toggle:theme", () => {
      let body = document.querySelector("body")
      body.classList.remove("theme-light")
      body.classList.remove("theme-dark")
      body.classList.remove("theme-system")
      body.classList.add("theme-" + this.$store.state.theme)
    })
    setInterval(() => {
      if (this.$store.state.user) {
        this.openSession().catch((e) => {
          console.log(e);

          this.$root.$emit("error", e)
          // if (this.$route.name !== "Login") {
          //   this.$router.replace({ name: "Login" })
          // }
        })
      }
    }, 1000 * 60 * 30) // Refresh every 30 minutes

    const url = new URL(window.location.href)

    if (window.to && !window.to.name) {
      if (this.$store.state.user) {
        this.$router.push({ name: "Responses" })
        await this.openSession()
        return
      } else {
        this.$router.push({ name: "Login" })
        return
      }
    }
    if (this.$store.state.user && !url.searchParams.get("token")) {
      // this.$router.replace({ name: "Responses" });
      this.$root.openSession().catch(e => {
        this.$root.$emit("error", e)
        if (this.$route.name !== "Login") {
          this.$router.replace({ name: "Login" })
        }
      })
    }
    if (url.searchParams.get("token")) {
      if (this.$store.state.user && url.searchParams.get("token") && this.$store.state.user.access_token !== url.searchParams.get("token")) {
        if (this.$route.name !== "Login") {
          this.$router.replace({ name: "Login", query: { token: url.searchParams.get("token") } })
        }
      } else {
        this.$store.dispatch('user', {
          access_token: url.searchParams.get("token")
        })
        this.openSession().catch((e) => {
          this.$root.$emit("error", e)
          if (this.$route.name !== "Login") {
            this.$router.replace({ name: "Login" })
          }
        })
      }
    }

  },
  watch: {
    theme() {
      document.querySelector("body").classList.remove("theme-dark")
      document.querySelector("body").classList.remove("theme-auto")
      document.querySelector("body").classList.remove("theme-light")
      document.querySelector("body").classList.add("theme-" + this.$store.state.theme)
    },
  },
  computed: {
    theme() {
      return this.$store.state.theme
    },
  },
  methods: {
    isElectron() {
      return process.env.IS_ELECTRON
    },
    isMac() {
      return process.env.VUE_APP_CLIENT_ID == 2
    },
    isWeb() {
      return process.env.VUE_APP_CLIENT_ID == 1
    },
    isExtension() {
      return process.env.VUE_APP_CLIENT_ID == 4
    },
    async openSession() {
      await store.restored

      return new Promise((resolve, reject) => {
        if (typeof this.$store.state.shortcut !== "object" || !this.$store.state.shortcut.length) {
          this.$store.dispatch("shortcut", [
            { key: "Alt", code: "AltLeft", keyCode: 18 },
            { key: "©", code: "KeyC", keyCode: 67 },
          ])
        }
        // setTimeout(() => { console.log(this.$store.state.user) }, 2000);

        get("session/open")
          .then(ret => {
            this.$store.dispatch("user", Object.assign(this.$store.state.user, ret.data.user))
            this.$store.dispatch("responses", ret.data.responses)
            this.$store.dispatch("folders", ret.data.folders)
            this.$store.dispatch("archives", ret.data.archives)
            this.$store.dispatch("last_available_clv", ret.data.last_available_clv)
            resolve()
          })
          .catch(e => {
            console.log(e)
            // this.$root.$emit('logout')
            reject("Unable to refresh session: " + e)
          })
      })
    },
  },
}).$mount("#app")

// import { version } from "@/../package.json"

// if (process.env.NODE_ENV == "production") {
//   Sentry.init({
//     Vue,
//     dsn: "https://3daa0d16d6a6400c8da2949692e21938@o185228.ingest.sentry.io/5705090",
//     integrations: [new Integrations.BrowserTracing()],

//     // Set tracesSampleRate to 1.0 to capture 100%
//     // of transactions for performance monitoring.
//     // We recommend adjusting this value in production
//     tracesSampleRate: 1.0,
//     environment: process.env.NODE_ENV,
//     beforeSend(event) {
//       // Check if it is an exception, and if so, show the report dialog
//       console.log(event)
//       // if (event.exception) {
//       //   Sentry.showReportDialog({eventId: event.event_id, title: "It looks like something didn't go as expected :("});
//       // }
//       return event
//     },
//   })
//   Sentry.setTag("clv", version || "0")
//   Sentry.setTag("client_id", process.env.VUE_APP_CLIENT_ID)
      // }
