import Vue from "vue"
import * as Sentry from "@sentry/vue"
import Vuex from "vuex"
import { plugins } from "@/store/plugins"

Vue.use(Vuex)

function createStore() {
  return new Vuex.Store({
    state: {
      user: null,
      responses: [],
      folders: {
        owned: [],
        shared: [],
        team: [],
      },
      debug: false,
      bookmarks: [],
      record_keys: [],
      variable_values: [],
      archives: [],
      theme: "light",
      shortcut: [
        { key: "Alt", code: "AltLeft", keyCode: 18 },
        { key: "©", code: "KeyC", keyCode: 67 },
      ],
      shortcut_newresponse: [
        { key: "Alt", code: "AltLeft", keyCode: 18 },
        { key: "n", code: "KeyN", keyCode: 78 },
      ],
      sort: "date",
      folderSort: "date",
      autostart: false,
      restoreClipboard: false,
      window: null,
      current_clv: null,
      last_seen_clv: null,
      last_available_clv: null,
      first_launch: true,
      keymap: null,
      device_id: null,
      last_version_notified: null,
    },
    mutations: {
      user: (state, payload) => {
        state.user = payload
        if (payload && payload.email) {
          Sentry.setUser({ email: payload.email })
        } else {
          Sentry.configureScope(scope => scope.setUser(null))
        }
      },
      responses: (state, payload) => {
        state.responses = payload
      },
      folders: (state, payload) => {
        state.folders = payload
      },
      debug: (state, payload) => {
        state.debug = payload
      },
      bookmarks: (state, payload) => {
        state.bookmarks = payload
      },
      archives: (state, payload) => {
        state.archives = payload
      },
      theme: (state, payload) => {
        state.theme = payload
      },
      sort: (state, payload) => {
        state.sort = payload
      },
      autostart: (state, payload) => {
        state.autostart = payload
      },
      restoreClipboard: (state, payload) => {
        state.restoreClipboard = payload
      },
      shortcut: (state, payload) => {
        Vue.set(state, "shortcut", payload)
      },
      shortcut_newresponse: (state, payload) => {
        Vue.set(state, "shortcut_newresponse", payload)
      },
      record_keys: (state, payload) => {
        Vue.set(state, "record_keys", payload)
      },
      folderSort: (state, payload) => {
        state.folderSort = payload
      },
      window: (state, payload) => {
        state.window = payload
      },
      current_clv: (state, payload) => {
        state.current_clv = payload
      },
      last_seen_clv: (state, payload) => {
        state.last_seen_clv = payload
      },
      last_available_clv: (state, payload) => {
        state.last_available_clv = payload
      },
      last_version_notified: (state, payload) => {
        state.last_version_notified = payload
      },
      variable_value: (state, payload) => {
        let allValues = Object.assign({}, state.variable_values)
        allValues[payload.uuid] = payload.value
        state.variable_values = allValues
      },
      first_launch: (state, payload) => {
        state.first_launch = payload
      },
      keymap: (state, payload) => {
        state.keymap = payload
      },
      device_id: (state, payload) => {
        state.device_id = payload
      },
    },
    actions: {
      user({ commit }, payload) {
        commit("user", payload)
      },
      autostart({ commit }, payload) {
        commit("autostart", payload)
      },
      restoreClipboard({ commit }, payload) {
        commit("restoreClipboard", payload)
      },
      response({ dispatch, state }, payload) {
        let found = false
        let responses = [...state.responses]

        responses.forEach((response, index) => {
          if (response.uuid == payload.uuid) {
            responses[index] = payload
            dispatch("responses", responses)
            found = true
          }
        })
        if (!found) {
          responses.unshift(payload)
          dispatch("responses", responses)

          // Update responses count
          let user = Object.assign({}, state.user)
          user.responses_count++
          dispatch("user", user)
        }
      },
      responses({ commit, state }, responses) {
        commit("responses", responses)
        const bookmarks = responses.filter(elm => {
          return elm.is_bookmarked
        })
        commit("bookmarks", bookmarks)

        let allFolders = JSON.parse(JSON.stringify(state.folders.owned.concat(state.folders.shared).concat(state.folders.team)))
        let folders = JSON.parse(JSON.stringify(state.folders))
        responses.forEach(response => {
          if (response.folder && !allFolders.some(e => e && e.uuid == response.folder.uuid)) {
            folders.owned.push(response.folder)
            allFolders.push(response.folder)
          }
        })
        commit("folders", folders)
      },
      folders({ commit }, folders) {
        commit("folders", folders)
      },
      folder({ state, dispatch }, payload) {
        let found = false
        let folders = JSON.parse(JSON.stringify(state.folders))
        let type = payload.team ? "team" : "owned"
        folders[type].forEach((folder, index) => {
          if (folder.uuid == payload.uuid) {
            folders[type][index] = payload
            dispatch("folders", folders)
            found = true
          }
        })
        if (!found) {
          folders[type].unshift(payload)
          dispatch("folders", folders)
        }
      },
      archives({ commit }, payload) {
        commit("archives", payload)
      },
      theme({ commit }, payload) {
        commit("theme", payload)
      },
      shortcut({ commit }, payload) {
        commit("shortcut", payload)
      },
      shortcut_newresponse({ commit }, payload) {
        commit("shortcut_newresponse", payload)
      },
      sort({ commit }, payload) {
        commit("sort", payload)
      },
      folderSort({ commit }, payload) {
        commit("folderSort", payload)
      },
      debug({ commit }, payload) {
        commit("debug", payload)
      },
      window({ commit }, payload) {
        commit("window", payload)
      },
      current_clv({ commit }, payload) {
        commit("current_clv", payload)
      },
      last_seen_clv({ commit }, payload) {
        commit("last_seen_clv", payload)
      },
      last_version_notified({ commit }, payload) {
        commit("last_version_notified", payload)
      },
      keymap({ commit }, payload) {
        commit("keymap", payload)
      },
      record_keys({ commit }, payload) {
        commit("record_keys", payload)
      },
      last_available_clv({ commit }, payload) {
        commit("last_available_clv", payload)
      },
      variable_value({ commit }, payload) {
        commit("variable_value", payload)
      },
      first_launch({ commit }) {
        commit("first_launch", false)
      },
      device_id({ commit }, payload) {
        commit("device_id", payload)
      },
      logout({ commit }) {
        // commit("theme", "light")
        commit("user", null)
        commit("responses", [])
        commit("folders", {
          owned: [],
          shared: [],
          team: [],
        })
        commit("bookmarks", [])
        commit("archives", [])
      },
    },

    getters: {
      shortcutString: state => {
        try {
          let keys = []
          if (state.shortcut && state.shortcut.length) {
            state.shortcut.forEach(({ key, keyCode }) => {
              if ([16, 17, 18, 91, 93].indexOf(keyCode) >= 0) {
                keys.push(key)
              } else {
                keys.push(String.fromCharCode(keyCode))
              }
            })
          }
          return keys.join("+")
        } catch (error) {
          // console.log(error)
          return "Alt+C"
        }
      },
      shortcutNewResponseString: state => {
        try {
          let keys = []
          if (state.shortcut_newresponse && state.shortcut_newresponse.length) {
            state.shortcut_newresponse.forEach(({ key, keyCode }) => {
              if ([16, 17, 18, 91, 93].indexOf(keyCode) >= 0) {
                keys.push(key)
              } else {
                keys.push(String.fromCharCode(keyCode))
              }
            })
          }
          return keys.join("+")
        } catch (error) {
          // console.log(error)
          return "Alt+N"
        }
      },
      sortedFolders: state => {
        let owned = state.folders.owned
        let shared = state.folders.shared
        let team = state.folders.team
        switch (state.folderSort) {
          case "az":
            ;[owned, shared, team].forEach(l => {
              l.sort((a, b) => a.name.localeCompare(b.name))
            })
            break
          case "date":
            ;[owned, shared, team].forEach(l => {
              l.sort((a, b) => (Date.parse(a.created_at) >= Date.parse(b.created_at) ? -1 : 1))
            })
            break
          case "count":
            ;[owned, shared, team].forEach(l => {
              l.sort((a, b) => {
                let countA = state.responses.filter(e => {
                  return e.folder && e.folder.uuid == a.uuid
                }).length
                let countB = state.responses.filter(e => {
                  return e.folder && e.folder.uuid == b.uuid
                }).length
                return countA > countB ? -1 : 1
              })
            })
            break
          default:
            break
        }
        return { owned, shared, team }
      },
    },
    modules: {},
    plugins,
  })
}

let store

while (store === undefined) {
  try {
    store = createStore()
    break
  } catch (e) {
    // alert("Error in Store, guess race condition. Recreating Storage." + e)
    continue
  }
}

export default store
