<template>
  <b-tab title="Subscription" class="py-4">
    <div v-if="loading" class="d-flex align-items-center justify-content-center p-5">
      <img width="24" src="@/common/assets/img/loader_dark.svg" alt="" />
    </div>
    <div v-else>
      <div class="mt-3" v-if="showUnsubscribeConfirm">
        <p class="text-center p-4">
          After your subscription ends on {{ $store.state.user.subscription.next_billing_date_formatted }},
          <b> you will only be able to use 10 canned responses and all the users on your plan will revert back to the free plan as well </b>. Are you sure?
        </p>
        <b-button :disabled="loading" @click="cancelSubscription()" class="mt-3 py-3" variant="danger" block>Yes, cancel subscription</b-button>
        <b-button @click="showUnsubscribeConfirm = false" class="mt-3 py-3 text-default" variant="link" block>Back</b-button>
      </div>
      <div class="text-center mt-4" v-else>
        <p class="d-flex align-items-center justify-content-center">
          <span class="badge badge-yellow-dark pt-2 mr-2">YOUR CURRENT PLAN</span>
          <span v-if="$store.state.user.subscription">
            {{ $store.state.user.subscription.caption }}
          </span>
          <span v-else-if="$store.state.user.parent_subscription && $store.state.user.parent_subscription.role == 'admin'"
            >Admin account <span class="text-muted">({{ subscriptionOwner.name }})</span></span
          >
          <span v-else-if="$store.state.user.parent_subscription && $store.state.user.parent_subscription.role == 'guest'"
            >Guest account <span class="text-muted">({{ subscriptionOwner.name }})</span></span
          >
          <span v-else> Free </span>
        </p>
        <div v-if="$store.state.user.subscription && $store.state.user.subscription.renew">
          <p>You subscription will be automatically renewed on {{ $store.state.user.subscription.next_billing_date_formatted }}</p>
          <b-button @click="$root.$emit('autologin', { next: 'checkout' })" block variant="primary" size="lg">Change my subscription</b-button>
          <b-button @click="$root.$emit('autologin', { next: 'portal' })" block variant="primary-light text-primary" size="lg">Update my payment information</b-button>
          <b-button :disabled="loading" @click="showUnsubscribeConfirm = true" class="text-muted m-0 mt-4" variant="hover" block>Cancel subscription</b-button>
        </div>
        <div v-else-if="$store.state.user.subscription && $store.state.user.subscription.caption.indexOf('LTD') === false">
          <p>
            You subscription <span class="text-danger">will stop on {{ $store.state.user.subscription.next_billing_date_formatted }}</span
            >. Click on the button below to stay on our premium plan past that date.
          </p>
          <b-button v-if="$store.state.user.subscription.is_trial" @click="$root.$emit('showPaywall')" block variant="premium" size="lg" class="d-flex align-items-center justify-content-center">
            <b-icon class="mr-3" icon="unlock"></b-icon>
            Subscribe now
          </b-button>
          <b-button v-else @click="restartSubscription()" block variant="premium" size="lg" class="d-flex align-items-center justify-content-center">
            <b-icon class="mr-3" icon="unlock"></b-icon>
            Subscribe now
          </b-button>
        </div>
        <div v-else-if="!$store.state.user.parent_subscription">
          <b-button variant="premium" @click="$root.$emit('autologin', { next: 'checkout' })" block size="lg">Upgrade my subscription</b-button>
        </div>
      </div>
      <hr class="my-4 border-top border-border" />

      <section v-if="$store.state.user.subscription || ($store.state.user.parent_subscription && $store.state.user.parent_subscription.role == 'admin')">
        <div class="d-flex align-items-center justify-content-between">
          <div><b>User</b></div>
          <div class="d-flex align-items-center">
            <b>Role</b>
            <span class="ml-2" v-b-tooltip.hover title="Guests can create and edit responses. Admins can invite other users and create team folders.">
              <b-icon icon="question-circle-fill" class="text-muted"></b-icon>
            </span>
          </div>
        </div>

        <div class="my-4">
          <!-- <UserLine :showStatus="false" :user="$store.state.user" :type="'user'">
        <div class="col-4">
          <p class="m-0">Owner</p>
        </div>
      </UserLine> -->
          <div :key="user.user.email" v-for="user in users">
            <UserLine :key="user.user.uuid" :showStatus="!user.user.uuid" :user="user.user" :type="user.user.uuid ? 'user' : 'invitation'">
              <div class="d-flex align-items-center justify-content-end">
                <b-form-select class="w-100" v-model="user.role" @change="changeRole(user)" v-if="user.role == 'owner' && user.user.uuid" disabled :options="ownerOptions"></b-form-select>
                <b-form-select
                  class="w-100"
                  v-model="user.role"
                  @change="changeRole(user)"
                  :disabled="user.user.uuid == $store.state.user.uuid"
                  v-else-if="user.user.uuid"
                  :options="options"
                ></b-form-select>
                <b-dropdown
                  lazy
                  v-if="$store.state.user.subscription"
                  toggle-tag="span"
                  variant="hover"
                  toggle-class="text-decoration-none border-0 rounded p-0 ml-3"
                  no-caret
                  :disabled="user.role == 'owner' ? true : false"
                >
                  <template #button-content>
                    <span class="d-block px-1 p-0">
                      <b-icon icon="three-dots"></b-icon>
                    </span>
                  </template>
                  <b-dropdown-item @click="removeUser(user.user)"><span class="text-danger">Remove from my plan</span></b-dropdown-item>
                </b-dropdown>
              </div>
            </UserLine>
          </div>
        </div>
        <hr class="my-4" />

        <p class="my-3"><b>Add users</b></p>
        <invite-users @done="addUsers($event)" />
        <!-- <b-button
            variant="primary"
            size="lg"
            class="w-100"
            @click="
              $bvModal.hide('preferences')
              $bvModal.show('inviteusers')
            "
            >Invite users</b-button
          > -->
        <!-- <div class="bg-background p-4 rounded">
            In order to add new users to your plan, invite them to one of your folders. <a href="#" @click.stop="$root.$emit('openHelp', 'share folder')">Learn more</a>
          </div> -->
      </section>
    </div>
  </b-tab>
</template>

<script>
import { post } from "@/common/api"
import UserLine from "@/common/components/UserLine"
import InviteUsers from "@/common/components/InviteUsers"

export default {
  data() {
    return {
      user: this.$store.state.user,
      showUnsubscribeConfirm: false,
      loading: false,
      options: [
        { text: "Guest", value: "guest" },
        { text: "Admin", value: "admin" },
      ],
      ownerOptions: [{ text: "Owner", value: "owner" }],
      error: null,
      role: null,
      users: [],
    }
  },
  components: {
    UserLine,
    InviteUsers,
  },
  watch: {
    user() {
      if (this.$store.state.user) {
        if (this.$store.state.user.parent_subscription) {
          this.users = this.$store.state.user.parent_subscription.subscription.users.slice()
        } else if (this.$store.state.user.subscription) {
          this.users = this.$store.state.user.subscription.users.slice()
        }
      }
    },
  },
  created() {
    if (this.$store.state.user) {
      if (this.$store.state.user.parent_subscription) {
        this.users = this.$store.state.user.parent_subscription.subscription.users.slice()
      } else if (this.$store.state.user.subscription) {
        this.users = this.$store.state.user.subscription.users.slice()
      }
    }
    this.$root.$on("bv::modal::show", (bvEvent, modalId) => {
      if (modalId == "preferences") {
        this.showUnsubscribeConfirm = false
      }
    })
  },
  computed: {
    subscriptionOwner() {
      return this.$store.state.user.parent_subscription.subscription.users.find(e => e.role == "owner").user
    },
  },
  methods: {
    addUsers(emails) {
      // console.log(emails)

      emails.forEach(email => {
        let userO = {
          role: "guest",
          user: {
            email,
            name: null,
          },
        }
        this.users.push(userO)
        if (this.$store.state.user.parent_subscription) {
          let newUser = Object.assign({}, this.$store.state.user)
          newUser.parent_subscription.users.push(userO)
          this.$store.dispatch("user", newUser)
        } else {
          let newUser = Object.assign({}, this.$store.state.user)
          newUser.subscription.users.push(userO)
          this.$store.dispatch("user", newUser)
        }
      })
    },
    removeUser(user) {
      this.loading = true
      let params = {}
      if (user.uuid) {
        params.user_id = user.uuid
      } else {
        params.user_email = user.email
      }
      post("me/subscription/user/revoke", params)
        .then(() => {
          this.$root.openSession().then(() => {
            if (this.$store.state.user.parent_subscription) {
              this.users = this.$store.state.user.parent_subscription.subscription.users
            } else {
              this.users = this.$store.state.user.subscription.users
            }
            this.loading = false
          })
        })
        .catch(e => {
          this.$root.$emit("error", e)
          this.loading = false
        })
    },
    changeRole(user) {
      post("me/subscription/updaterole", {
        user_id: user.user.uuid,
        role: user.role,
      }).catch(e => {
        this.$root.$emit("error", e)
      })
    },
    cancelSubscription() {
      this.loading = true
      post("me/subscription/cancel")
        .then(() => {
          this.loading = false
          let user = this.$store.state.user
          user.subscription.renew = false
          this.$store.dispatch("user", user)
          this.showUnsubscribeConfirm = false
        })
        .catch(e => {
          this.loading = false
          this.$root.$emit("error", e)
        })
    },
    restartSubscription() {
      this.loading = true
      post("me/subscription/restart")
        .then(() => {
          this.loading = false
          let user = this.$store.state.user
          user.subscription.renew = true
          this.$store.dispatch("user", user)
          this.showUnsubscribeConfirm = false
        })
        .catch(e => {
          this.loading = false
          this.$root.$emit("error", e)
        })
    },
  },
}
</script>

<style lang="scss" scoped></style>
