<template>
  <div>
    <div class="dashboard">
      <Sidebar></Sidebar>
      <router-view :key="JSON.stringify($route.path)" />
      <Preferences v-if="$store.state.user && loaded" />
      <Shortcuts v-if="$store.state.user && loaded" />
      <BuildResponse v-if="$store.state.user && loaded" />
      <Clipboard v-if="$store.state.user && loaded" />
      <FolderShareModal v-if="$store.state.user && loaded" />
      <component :is="component_tour" />
      <b-toast toaster="b-toaster-top-center" id="bookmark" variant="success" no-close-button auto-hide-delay="1000" solid>
        <template #toast-title></template>
        <b-icon icon="check"></b-icon>
        Added to bookmarks
      </b-toast>
      <b-toast toaster="b-toaster-top-center" id="copied" no-close-button auto-hide-delay="1000" solid variant="success">
        <template #toast-title></template>
        <b-icon icon="check"></b-icon>
        Copied to clipboard
      </b-toast>
      <component :is="updater" />
    </div>
    <div
      v-if="!$store.state.user || !loaded"
      style="position: fixed; top: 0; left: 0; opacity: 60%; background: white; display: flex; align-items: center; width: 100vw; height: 100vh; overflow: hidden; justify-content: center"
    >
      <img style="height: 20px; width: 20px" src="@/common/assets/img/loader_dark.svg" alt="" />
    </div>
  </div>
</template>

<script>
import Sidebar from "@/common/components/Sidebar"
import Preferences from "@/common/components/Preferences"
import Shortcuts from "@/common/components/Shortcuts"
import BuildResponse from "@/common/components/BuildResponse"
import Clipboard from "@/common/components/Clipboard"
import FolderShareModal from "@/common/components/FolderShareModal"
import Tour from "@/common/components/Tour"
// let shouldDisplayKeyboardTraining
// import store from "@/common/store"

export default {
  data() {
    return {
      showWelcome: false,
      player: null,
      tour: null,
      component: null,
      component_tour: null,
      updater: null,
      loaded: false,
    }
  },
  components: {
    Sidebar,
    Tour,
    Preferences,
    Shortcuts,
    BuildResponse,
    Clipboard,
    FolderShareModal,
  },
  methods: {
    shouldStartTour() {
      return !this.$store.state.user || (this.$store.state.user && this.$store.state.user.props && !this.$store.state.user.props.some(e => e.name == "tutorial_seen"))
    },
    startTour() {
      if (this.shouldStartTour()) this.$bvModal.show("tour")
    },
    checkPermissions() {
      return new Promise(resolve => {
        if (!this.$root.isElectron() || !this.$root.isMac()) {
          resolve(true)
        }
        this.$root.$emit("checkPermissions")
        this.$root.$on("hasPermissions", hasPermissions => {
          resolve(hasPermissions)
        })
      })
    },
    // keyboardTrainingIsMounted() {
    //   if (!this.shouldStartTour() && this.$root.isElectron() && !this.$store.state.keymap) {
    //     // this.$bvModal.show("keyboardtraining")
    //   }
    // },
  },
  async created() {
    await window.store.restored
    let hasPermissions = await this.checkPermissions()
    if (!hasPermissions) {
      this.$router.replace({ name: "FirstLaunch" })
      return
    }
    this.loaded = true

    if (!this.$store.state.user) {
      this.$router.push({ name: "Login", query: this.$route.query })
    }

    if (this.shouldStartTour()) {
      this.component_tour = () => import("@/common/components/Tour.vue")
    }
  },
  mounted() {
    if (this.$root.isElectron()) {
      // this.component = () => import("@/components/KeyboardTrainingModal.vue")
      if (!this.shouldStartTour()) {
        this.updater = () => import("@/components/Updater.vue")
      }
      setTimeout(this.startTour, 1000)
    }
  },
  beforeDestroy() {
    clearTimeout(this.startTour, 1000)
    this.$root.$off("hasPermissions")
  },
}
</script>

<style lang="scss" scoped>
@import "@/common/assets/css/_variables.scss";
@import "@/common/assets/css/mixins.scss";

.dashboard {
  height: 100vh;
  overflow: hidden;
  display: grid;
  grid-template-columns: 300px auto;
  @include media-breakpoint-up(xl) {
    grid-template-columns: 350px auto;
  }
  @include media-breakpoint-down(md) {
    display: block;
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.1s;
}

.fade-enter-active {
  transition-delay: 0.1s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.welcome {
  position: absolute;
  right: 50px;
  bottom: 50px;
  width: 190px;
  height: 250px;
  border-radius: 8px;
  box-shadow: 0 20px 20px 5px var(--color-overlay);
  cursor: pointer;
  div:hover + .close {
    display: none;
  }
  > div {
    > img.gif {
      position: absolute;
      left: 0;
      top: 0;
      z-index: 0;
      border-radius: 8px;
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
    .overlay {
      position: absolute;
      left: 0;
      top: 0;
      z-index: 1;
      width: 100%;
      height: 100%;
      transition: all 0.2s ease;
      background-color: var(--color-overlay);
      &:hover {
        background-color: var(--color-overlay);
      }
    }
    &:hover {
      transform: scale(1.1);
    }
    border-radius: 8px;
    overflow: hidden;
    transition: all 0.2s ease;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .play {
      pointer-events: none;
      z-index: 3;
      width: 45px;
      height: 45px;
    }
  }

  .close {
    opacity: 1;
    position: absolute;
    color: white;
    right: -10px;
    top: -10px;
    z-index: 200;
    width: 24px;
    height: 24px;
    background: var(--color-default);
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      background: var(--color-danger);
      opacity: 1 !important;
    }
  }
}
::v-deep #getstarted .modal-content {
  background: transparent;
  border: none;
}
::v-deep iframe {
  max-width: 100% !important;
  width: 100%;
}
</style>
