<template>
  <div>
    <b-modal id="preferences" hide-footer header-border-variant="0">
      <template #modal-header-close>
        <b-icon icon="x"></b-icon>
      </template>
      <div class="px-4">
        <!-- <div class="row">
          <div class="col">
            <p>
              <b-icon icon="moon" class="mr-3"></b-icon>
              Appearance
            </p>
          </div>
          <div class="col">
            <b-form-radio-group id="radio-group-1" v-model="theme" :options="{ auto: 'Auto', light: 'Light', dark: 'Dark' }" name="theme"></b-form-radio-group>
          </div>
        </div> -->
        <!-- <div class="d-flex align-items-center mt-5">
          <p class="d-flex align-items-center flex-grow-1">
            <b-icon icon="moon" class="mr-3"></b-icon>
            Appearance
          </p>
          <div>
            <div class="d-flex align-items-center">
              <div class="option">
                <img @click="theme = 'auto'" :class="{ active: $store.state.theme == 'auto' }" src="@/common/assets/img/icon_auto.png" class="themeselector" alt="" />
                <p class="mt-2 small text-center text-muted">Auto</p>
              </div>
              <div class="option">
                <img @click="theme = 'light'" :class="{ active: $store.state.theme == 'light' }" src="@/common/assets/img/icon_light.png" class="themeselector" alt="" />
                <p class="mt-2 small text-center text-muted">Light</p>
              </div>

              <div class="option">
                <img @click="theme = 'dark'" :class="{ active: $store.state.theme == 'dark' }" src="@/common/assets/img/icon_dark.png" class="themeselector" alt="" />
                <p class="mt-2 small text-center text-muted">Dark</p>
              </div>
            </div>
          </div>
        </div> -->
        <b-tabs v-model="index" pills align="center">
          <b-tab title="My account" class="pt-4">
            <b-card-text>
              <div class="text-center my-3" v-if="error || userUpdated">
                <div v-if="error" class="alert alert-danger">{{ error }}</div>
                <p v-else-if="userUpdated" class="alert alert-success">
                  Your account has been updated.
                  <span v-if="user.email !== $store.state.user.email">&nbsp;You will receive an email on your new email address to confirm it.</span>
                </p>
              </div>
              <div class="d-flex justify-content-center">
                <Avatar class="mr-3 my-3" />
              </div>
              <b-form-group label="Account email">
                <!-- <label for="email"></label> -->
                <b-input :state="user.email == '' ? false : null" v-model="user.email"></b-input>
              </b-form-group>
              <div class="row">
                <div class="col">
                  <b-form-group label="First name">
                    <b-input :state="user.first_name == '' ? false : null" required placeholder="Johh" v-model="user.first_name"></b-input>
                  </b-form-group>
                </div>
                <div class="col">
                  <b-form-group label="Last name">
                    <b-input :state="user.last_name == '' ? false : null" required placeholder="Doe" v-model="user.last_name"></b-input>
                  </b-form-group>
                </div>
              </div>
              <b-form-group label="Role">
                <b-input placeholder="CEO" v-model="user.role"></b-input>
              </b-form-group>
              <b-form-group label="Business name">
                <b-input placeholder="Acme Inc." v-model="user.business"></b-input>
              </b-form-group>
              <b-button :disabled="loading" @click="saveUser()" block variant="primary" size="lg">Save</b-button>
              <hr class="border-top border-border" />
              <section v-if="showPasswordLost">
                <p class="text-center text-muted">Click on the button below to change or reset your password.</p>
                <b-button @click="updatePassword()" block variant="secondary" size="lg" class="mt-2 d-flex align-items-center justify-content-center">
                  <b-icon class="mr-2" icon="lock-fill"></b-icon>
                  Update password</b-button
                >
              </section>
              <section v-else>
                <div class="alert alert-success">We have sent password reset instructions to your email address. Please check your inbox.</div>
              </section>
            </b-card-text>
          </b-tab>

          <SubscriptionTab />
          <b-tab v-if="!$root.isExtension()" title="Settings" class="pt-4">
            <b-card-text>
              <div v-if="error" class="alert alert-danger">{{ error }}</div>
              <div class="d-flex align-items-center justify-content-between">
                <div>
                  <p class="m-0">Quick search shortcut</p>
                  <p v-if="!recording" @click="recordShortcut" class="cursor-pointer text-muted small m-0">Click here to record</p>
                  <p v-if="recording" class="text-muted small m-0">Press your keyboard shortcut to record</p>
                </div>
                <div>
                  <span class="shortcut cursor-pointer px-3 py-2 bg-primary-light text-default rounded" v-if="recording">Recording...</span>
                  <span @click="recordShortcut" class="shortcut cursor-pointer px-3 py-2 bg-primary-light text-default rounded" v-else>{{ shortcut }}</span>
                </div>
              </div>
              <section v-if="this.$root.isElectron()">
                <div class="d-flex align-items-center justify-content-between mt-3">
                  <div>
                    <p class="m-0">Create new response shortcut</p>
                    <p v-if="!recordingNewResponse" @click="recordNewresponseShortcut" class="cursor-pointer text-muted small m-0">Click here to record</p>
                    <p v-if="recordingNewResponse" class="text-muted small m-0">Press your keyboard shortcut to record</p>
                  </div>
                  <div>
                    <span class="shortcut cursor-pointer px-3 py-2 bg-primary-light text-default rounded" v-if="recordingNewResponse">Recording...</span>
                    <span @click="recordNewresponseShortcut" class="shortcut cursor-pointer px-3 py-2 bg-primary-light text-default rounded" v-else>{{ shortcutNewResponse }}</span>
                  </div>
                </div>
                <div class="d-flex align-items-center justify-content-between mt-3">
                  <div>
                    <p class="m-0">Quick Shortcut trigger</p>
                    <p class="text-muted small m-0">Select one or more</p>
                  </div>
                  <div>
                    <b-form-group>
                      <b-form-checkbox-group
                        id="qstrigger"
                        v-model="triggers"
                        name="qstrigger"
                        :options="[
                          { text: '.', value: '.', disabled: triggers.length <= 1 && triggers[0] == '.' },
                          { text: '/', value: '/', disabled: triggers.length <= 1 && triggers[0] == '/' },
                          { text: ':', value: ':', disabled: triggers.length <= 1 && triggers[0] == ':' },
                          { text: ';', value: ';', disabled: triggers.length <= 1 && triggers[0] == ';' },
                        ]"
                      ></b-form-checkbox-group>
                    </b-form-group>
                  </div>
                </div>
                <!-- <div class="alert alert-warning mt-4" v-if="shouldTrainKeyboard()">You will have to restart the keyboard training under the Help menu for this to work.</div> -->

                <hr class="my-4 border-top border-border" />
                <div class="d-flex align-items-center justify-content-between">
                  <div>
                    <p class="m-0">Open typedesk when computer starts</p>
                  </div>
                  <div>
                    <Toggle v-model="autostart" />
                  </div>
                </div>
                <div class="my-4 d-flex align-items-center justify-content-between">
                  <div>
                    <p class="m-0">Restore clipboard automatically</p>
                  </div>
                  <div>
                    <Toggle v-model="restore" />
                  </div>
                </div>
              </section>
              <hr class="my-4 border-top border-border" />
              <div class="d-flex align-items-center justify-content-between mt-3">
                <div>
                  <p class="m-0">Color theme</p>
                </div>
                <div>
                  <b-form-group class="m-0">
                    <b-form-radio-group
                      id="theme"
                      v-model="theme"
                      name="theme"
                      :options="[
                        { text: 'System', value: 'system' },
                        { text: 'Dark', value: 'dark' },
                        { text: 'Light', value: 'light' },
                      ]"
                    ></b-form-radio-group>
                  </b-form-group>
                </div>
              </div>
            </b-card-text>
          </b-tab>
        </b-tabs>
      </div>
      <!-- <b-card no-body>
        <b-tabs pills card>
          <b-tab title="General" active>
            <b-card-text>Tab contents 1</b-card-text>
          </b-tab>
          <b-tab title="Tab 2"><b-card-text>Tab contents 2</b-card-text></b-tab>
        </b-tabs>
      </b-card> -->
    </b-modal>
  </div>
</template>

<script>
import Toggle from "@/common/components/Toggle.vue"
import Avatar from "@/common/components/Avatar"
import SubscriptionTab from "@/common/components/Preferences/SubscriptionTab"
import { post } from "@/common/api"
let keydown = null
let keyup = null

export default {
  components: {
    Toggle,
    SubscriptionTab,
    Avatar,
  },
  data() {
    return {
      theme: this.$store.state.theme,
      autostart: false,
      restore: true,
      codes: [],
      recording: false,
      recordingNewResponse: false,
      userUpdated: false,
      error: null,
      triggers: [],
      index: 0,
      loading: false,
      cancelled: false,
      showPasswordLost: true,
      user: {
        email: this.$store.state.user.email || null,
        first_name: this.$store.state.user.first_name || null,
        last_name: this.$store.state.user.last_name || null,
        role: this.$store.state.user.role || null,
        business: this.$store.state.user.business || null,
      },
    }
  },
  watch: {
    theme() {
      this.toggleTheme()
      this.$root.$emit("theme:toggle")
      // if (this.$root.isElectron()) ipcRenderer.send("theme:toggle")
    },
    autostart(newValue) {
      this.$store.dispatch("autostart", newValue)
      this.$root.$emit("setAutostart", newValue)
    },
    restore(newValue) {
      this.$store.dispatch("restoreClipboard", newValue)
    },
    triggers(newValue) {
      if (newValue.length > 0) {
        this.$store.dispatch("record_keys", Object.values(newValue))
        setTimeout(() => {
          this.$root.$emit("updateKeymap")
        }, 1000)
      }
    },
  },
  computed: {
    shortcut() {
      return this.$store.getters.shortcutString
    },
    shortcutNewResponse() {
      return this.$store.getters.shortcutNewResponseString
    },
  },
  beforeDestroy() {
    this.$root.$off("bv::modal::show", this.resetValues)
    this.$root.$off("openPreferences", this.openPreferences)
    this.$root.$off("shortcutError", this.showShortcutError)
  },
  created() {
    this.triggers = this.$store.state.record_keys
    this.autostart = this.$store.state.autostart
    this.$root.$on("bv::modal::show", this.resetValues)
    this.$root.$on("openPreferences", this.openPreferences)
    this.$root.$on("shortcutError", this.showShortcutError)
  },
  methods: {
    resetValues(bvEvent, modalId) {
      if (modalId == "preferences") {
        this.stopRecording()
        this.error = null
        this.userUpdated = false
      }
    },
    openPreferences(tab) {
      if (tab) {
        this.index = tab
        this.$bvModal.show("preferences")
      }
    },
    showShortcutError() {
      this.error = "Unable to set shortcut (invalid shortcut). Please try again with a different shortcut."
    },
    // shouldTrainKeyboard() {
    //   return !this.$store.state.keymap || !Object.values(this.$store.state.keymap).filter(e => typeof e.shiftKey == "undefined").length == 0
    // },
    updatePassword() {
      post("forgot-password", {
        email: this.$store.state.user.email,
      })
        .catch(e => {
          this.loading = false
          // console.log(e)
          this.$root.$emit("error", e)
        })
        .then(() => {
          this.showPasswordLost = false
        })
    },
    saveUser() {
      this.loading = true
      let oldUser = JSON.parse(JSON.stringify(this.$store.state.user))
      let user = this.$store.state.user
      Object.assign(user, {
        first_name: this.user.first_name,
        last_name: this.user.last_name,
        role: this.user.role,
        business: this.user.business,
      })
      this.error = null
      this.userUpdated = false
      this.$store.dispatch("user", user)

      post("me/update", this.user)
        .catch(e => {
          this.loading = false
          // console.log(e)
          this.$store.dispatch("user", oldUser)

          if ((e && (!e.response || !e.response.status)) || (e && e.response && e.response.status !== 402)) {
            this.error = e
            if (e.response && e.response.data && typeof e.response.data.errors !== "undefined") {
              this.error = Object.values(e.response.data.errors)[0][0]
            }
          }
        })
        .then(() => {
          document.querySelector("#preferences").scrollTo(0, 0)
          this.loading = false
          this.userUpdated = true
        })
    },
    toggleTheme() {
      this.$store.dispatch("theme", this.theme)
    },
    recordShortcut() {
      this.$root.$emit("unregisterShortcut")
      this.recording = true
      this.codes = []
      keydown = e => {
        if (e.repeat) return
        let { key, code, keyCode } = e
        this.codes.push({ key, code, keyCode })
        e.preventDefault()
        e.stopPropagation()
      }
      keyup = () => {
        if (this.codes.length > 1) {
          // const newShortcut = this.newShortcut
          // this.shortcut = newShortcut
          // this.$root.$emit("updateShortcut", this.codes)
          this.$store.dispatch("shortcut", this.codes)
          this.$root.$emit("updateShortcut")
          this.stopRecording()
        }
        this.codes = []
      }
      document.addEventListener("keydown", keydown)
      document.addEventListener("keyup", keyup)
    },
    recordNewresponseShortcut() {
      // this.$root.$emit("unregisterShortcut")
      this.recordingNewResponse = true
      this.codes = []
      keydown = e => {
        if (e.repeat) return
        let { key, code, keyCode } = e
        this.codes.push({ key, code, keyCode })
        e.preventDefault()
        e.stopPropagation()
      }
      keyup = () => {
        if (this.codes.length > 1) {
          // const newShortcut = this.newShortcut
          // this.shortcut = newShortcut
          // this.$root.$emit("updateShortcut", this.codes)

          this.$store.dispatch("shortcut_newresponse", this.codes)
          this.$root.$emit("updateShortcut")
          this.stopRecording()
        }
        this.codes = []
      }
      document.addEventListener("keydown", keydown)
      document.addEventListener("keyup", keyup)
    },
    stopRecording() {
      document.removeEventListener("keydown", keydown)
      document.removeEventListener("keyup", keyup)
      this.recording = false
      this.recordingNewResponse = false
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@/common/assets/css/_variables";

.themeselector {
  border-radius: 3px;
  box-shadow: 0 0 0 2px var(--color-border);
  // border: 1px solid $color-border;
  &.active {
    // border: 2px solid rgba($color-primary, 0.3);
    box-shadow: 0 0 0 2px var(--color-primary);
  }
}

.option {
  margin-left: 1rem;
  img {
    width: 70px;
  }
}
</style>
