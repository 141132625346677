<template>
  <div :active="isMarkActive()" class="rounded overflow-hidden border border-border-1 border-border text-default d-flex align-items-center">
    <b-button @click="size--" variant="hover" class="m-0 rounded-0">
      <b-icon icon="dash"></b-icon>
    </b-button>
    <div class="text-center small size">
      {{ size }}
    </div>
    <b-button @click="size++" variant="hover" class="m-0 rounded-0">
      <b-icon icon="plus"></b-icon>
    </b-button>
  </div>
</template>

<script>
import { SlateMixin } from "slate-vue"
import { Editor } from "slate"

export default {
  mixins: [SlateMixin],
  data() {
    return {
      size: 15,
    }
  },
  methods: {
    updateSize() {
      this.$editor.addMark("size", this.size)
    },
    isMarkActive() {
      let marks = Editor.marks(this.$editor)
      if (marks && marks.size) {
        this.size = marks.size
      } else {
        this.size = 15
      }
      // return marks ? marks[format] === true : false
    },
  },
  watch: {
    size() {
      this.updateSize()
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .custom-select {
  width: 150px;
  max-height: 40px;
  padding-top: 0;
  padding-bottom: 0;
}

.size {
  width: 30px;
}
</style>
