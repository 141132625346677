<template>
  <div>
    <b-modal id="buildResponse" hide-footer header-border-variant="0">
      <template #modal-header-close>
        <b-icon icon="x"></b-icon>
      </template>
      <div v-if="loading" class="p-8 align-items-center justify-content-center text-center">
        <img src="@/common/assets/img/loader_dark.svg" alt="" width="24" />
      </div>
      <div v-else :class="{ ignoremouse: ignoreMouse }">
        <div v-if="response" class="p-5">
          <h4 class="text-center my-0">{{ response.name }}</h4>
          <div v-if="questions && questions.length" class="my-5">
            <div :key="question.uuid" v-for="(question, index) in questions">
              <div v-if="index == questionIndex">
                <p class="text-center mb-3">{{ question.title }}</p>
                <section v-if="question.name == 'select'">
                  <b-button
                    @mouseenter="optionIndex = index"
                    block
                    @click.stop="toggleSelectOption"
                    @keydown.space.stop.prevent="toggleSelectOption"
                    class="text-primary bg-transparent py-3 flex-wrap align-items-center justify-content-center d-flex position-relative"
                    :class="{
                      active: index == optionIndex,
                      isSelected: currentQuestion.selectedOptions && currentQuestion.selectedOptions.some(e => e == option),
                    }"
                    v-for="(option, index) in question.options"
                    :key="index"
                  >
                    {{ option }}
                    <div v-show="index == optionIndex || (currentQuestion.selectedOptions && currentQuestion.selectedOptions.some(e => e == option))" v-if="isMulti()" class="selectinstructions">
                      <!-- <p class="mb-0 mt-0 mr-2 text-white">Press spacebar or click to select</p> -->
                      <div v-b-tooltip.hover title="Press spacebar or click to select" class="checkbox">
                        <b-icon icon="check" v-if="currentQuestion.selectedOptions && currentQuestion.selectedOptions.some(e => e == option)"></b-icon>
                      </div>
                    </div>
                  </b-button>
                </section>
                <section v-if="question.name == 'text'">
                  <b-input ref="input" autofocus :placeholder="currentQuestion.defaultValue" v-model="inputValue"></b-input>
                </section>
              </div>
            </div>
          </div>
          <div class="text-center">
            <!-- <span>Press Enter</span>
            <b-icon icon="arrow-return-left" class="mx-2"></b-icon>
            <span>to continue</span>
            <span v-if="isMulti()">&nbsp; - Space to select option</span> -->
            <b-button @click="next()" block class="text-primary bg-primary-lighter border-primary py-3 wrap align-items-center justify-content-center d-flex">
              Press Enter to continue
              <b-icon class="ml-3" icon="arrow-return-left"></b-icon>
            </b-button>
            <p class="text-muted mt-3" v-if="currentQuestion && currentQuestion.name == 'select'">&nbsp; Arrow right to skip</p>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms))
}
import utils from "@/common/components/Editor/parse"
import serialize from "@/common/components/Editor/serialize.js"
// import { copy } from "@/clipboard.js"
import { post } from "@/common/api"
export default {
  data() {
    return {
      ignoreMouse: false,
      loading: false,
      response: null,
      content: null,
      contentParsed: null,
      questions: [],
      questionIndex: 0,
      optionIndex: 0,
      inputValue: null,
      inputDefault: null,
      variant: null,
    }
  },
  methods: {
    isMulti() {
      return this.currentQuestion && typeof this.currentQuestion.options !== "undefined" && this.currentQuestion.id !== "variant" && this.currentQuestion.multi
    },
    toggleSelectOption(e) {
      e.preventDefault()
      e.stopPropagation()
      if (this.questions[this.questionIndex].id == "variant" || this.questions[this.questionIndex].id == "gender") {
        this.next()
        return
      }
      let currentOption = this.currentQuestion.options[this.optionIndex]
      if (typeof this.questions[this.questionIndex].selectedOptions == "undefined") {
        let questions = this.questions
        questions[this.questionIndex].selectedOptions = []
        this.questions = JSON.parse(JSON.stringify(questions))
      }
      if (this.currentQuestion.selectedOptions.indexOf(currentOption) >= 0) {
        this.questions[this.questionIndex].selectedOptions = this.questions[this.questionIndex].selectedOptions.filter(e => e !== currentOption)
      } else {
        this.questions[this.questionIndex].selectedOptions.push(currentOption)
      }
      if (!this.isMulti()) {
        this.next()
        return
      }
    },
    selectResponse(uuid) {
      this.loading = false
      this.response = this.$store.state.responses.find(e => {
        return e.uuid == uuid
      })

      if (this.response && this.response.variants.length) {
        if (this.response.variants.length >= 2) {
          this.questionIndex = 0
          this.optionIndex = 0
          this.selectVariant()
        } else {
          this.variant = this.response.variants[0]
          this.content = JSON.parse(this.response.variants[0].content)
          this.parse()
        }
      }
    },
    addListeners(bvEvent, modalId) {
      if (modalId == "buildResponse") {
        window.addEventListener("keydown", this.keydown, true)
        window.addEventListener("mousemove", this.mousemove, true)
      }
    },
    removeListeners(bvEvent, modalId) {
      if (modalId == "buildResponse") {
        window.removeEventListener("keydown", this.keydown, true)
        window.removeEventListener("mousemove", this.mousemove, true)
      }
    },
    click(e) {
      if (!this.isMulti() && this.currentQuestion.name == "select") {
        this.toggleSelectOption(e)
      } else {
        this.next()
      }
    },
    mousemove() {
      this.ignoreMouse = false
    },
    keydown(e) {
      this.ignoreMouse = true
      let code = e.keyCode

      if (code == 32 || code == "Space") {
        if (this.isMulti()) {
          this.toggleSelectOption(e)
        }
      }
      if (code == 8 || code == "Backspace") {
        if (this.questionIndex - 1 >= 0 && this.currentQuestion.name == "select") {
          this.questionIndex--
        }
      }
      if (code == 40 || code == "ArrowDown") {
        this.optionIndex = Math.min(this.optionIndex + 1, this.questions[this.questionIndex].options.length - 1)
        document.querySelector("#buildResponse button.active").scrollIntoView({
          behavior: "smooth",
          block: "center",
        })
        e.preventDefault()
        e.stopPropagation()
      }
      if (code == 38 || code == "ArrowUp") {
        this.optionIndex = Math.max(this.optionIndex - 1, 0)
        document.querySelector("#buildResponse button.active").scrollIntoView({
          behavior: "smooth",
          block: "center",
        })
        e.preventDefault()
        e.stopPropagation()
      }

      if (code == 13 || code == "Enter") {
        if (!e.repeat) {
          if (!this.isMulti() && this.currentQuestion.name == "select") {
            this.toggleSelectOption(e)
          } else {
            this.next()
          }
        }
        e.preventDefault()
        e.stopPropagation()
      }

      if (code == 39 || code == "ArrowRight") {
        if (e.target.nodeName !== "INPUT") {
          this.next()
          e.preventDefault()
          e.stopPropagation()
        }
      }
    },
    selectVariant() {
      this.questionIndex = 0
      this.optionIndex = 0
      this.questions = [
        {
          title: "Select a variant",
          name: "select",
          id: "variant",
          options: this.response.variants.map(e => e.name),
        },
      ]
      //this.currentOptions = this.response.variants
      //this.step = "variant"
      this.$root.$emit("showWindow")
      this.$bvModal.show("buildResponse")
    },
    async next() {
      // console.log("next")

      if (this.questions.length && this.questions[this.questionIndex].id == "variant") {
        if (typeof this.questions[this.questionIndex].response !== "undefined") {
          // We have selected the variant of a subresponse
          // this.content = JSON.parse(this.questions[0].response.variants[this.optionIndex].content)
          let subVariant = this.questions[this.questionIndex].response.variants[this.optionIndex]
          let questions = []
          let content = Object.values(this.content)
          for (const node of content) {
            await utils.parse(node, questions, subVariant, null)
          }
          // this.questions = questions
          this.variant = subVariant
          this.content = content
          this.parse()
        } else {
          this.variant = this.response.variants[this.optionIndex]
          this.content = JSON.parse(this.variant.content)
          this.parse()
        }
      } else {
        if (this.questions.length) {
          let question = this.questions[this.questionIndex]
          // if (question.name == "select") {
          if (this.currentQuestion && this.currentQuestion.id == "gender" && (!this.currentQuestion.selectedOptions || !this.currentQuestion.selectedOptions.length)) {
            this.questions[this.questionIndex].selectedOptions = [this.currentQuestion.options[this.optionIndex]]
          }
          // }
          if (question.name == "text") {
            let value
            if (!this.inputValue && question.defaultValue) {
              value = question.defaultValue
              this.$store.dispatch("variable_value", {
                uuid: question.uuid,
                value: null,
              })
            } else {
              value = this.inputValue
              if (question.remember === true) {
                this.$store.dispatch("variable_value", {
                  uuid: question.uuid,
                  value,
                })
              }
            }
            question.value = value
          }
        }
        if (this.questions.length && this.questionIndex + 1 < this.questions.length) {
          this.questionIndex++
          this.optionIndex = 0
          this.inputValue = null
          window.setTimeout(() => {
            if (typeof this.$refs.input !== "undefined" && typeof this.$refs.input[0] !== "undefined") {
              this.$nextTick(() => {
                this.$refs.input[0].$el.focus()
              })
            }
          }, 150)
        } else {
          this.sendToClipboard()
        }
      }
    },
    async sendToClipboard() {
      // console.log("sendtoclipboard")
      // this.content.map((node, index, nodes) => {
      //   if (typeof node.children[0] !== "undefined" && node.children[0].text == "" && typeof node.children[1] !== "undefined" && node.children[1].type == "variable" && node.children.length == 3) {
      //     // console.log(JSON.parse(JSON.stringify(node)))
      //     nodes[index] = node.children[1]
      //   }
      // })
      this.content.map((node, index, nodes) => {
        this.replaceVariables(node, index, nodes)
      })
      this.content.map((node, index, nodes) => {
        try {
          if (
            typeof node.children !== "undefined" &&
            node.children.length == 3 &&
            node.children[1] == null &&
            node.children[0].text == "" &&
            node.children[2].text == "" &&
            nodes[index + 1].type == "paragraph" &&
            nodes[index + 1].children !== "undefined" &&
            nodes[index + 1].children[0].text == "" &&
            nodes[index + 1].children.length == 1
          ) {
            nodes.splice(index, 2)
          }
        } catch (error) {
          // console.log(error)
        }
        // if (node == null && typeof nodes[index + 1] !== "undefined" && nodes[index + 1].children !== "undefined" && nodes[index + 1].children[0].text == "" && nodes[index + 1].children.length == 1) {
        //   nodes.splice(index, 2)
        // }
      })
      let isSplit = false
      this.content.map(node => {
        if (typeof node.name !== "undefined" && (node.name == "tab" || node.name == "enter")) {
          isSplit = true
        }
      })

      if (isSplit) {
        let segments = []
        let currentSegment = []
        this.content.map(node => {
          if (typeof node.name !== "undefined" && (node.name == "tab" || node.name == "enter")) {
            segments.push(currentSegment)
            segments.push(node.name)
            currentSegment = []
          } else {
            currentSegment.push(node)
          }
        })
        segments.push(currentSegment)
        // console.log(segments)

        this.$root.$emit("hideWindow")
        this.$root.$emit("autopaste")
        // let hidden = false
        for (const segment of segments) {
          console.log(segments)

          if (segment == "tab" || segment == "enter") {
            console.log(segment)

            // console.log("Emitting " + segment)
            this.$root.$emit(segment)
          } else {
            let contentHTML = serialize({ children: segment }, false, false)
            let content = serialize({ children: segment }, true, false)
            // console.log("Hide window")
            this.$root.$emit("copyToClipboard", contentHTML, content.toString().replace(/\n$/, ""), Boolean(this.response.plain_text))
            if (this.$root.isMac()) {
              this.$root.$emit("paste")
              await sleep(500)
            } else {
              this.$root.$emit("copiedToClipboard")
              await sleep(1000)
            }

            // copy(contentHTML, content.toString().replace(/\n$/, ""), Boolean(this.response.plain_text))
          }
          this.$root.$emit("nohidewindow")

          // hidden = true
        }
      }

      if (!isSplit) {
        let contentHTML = serialize({ children: this.content }, false, false)
        let content = serialize({ children: this.content }, true, false)
        // // console.log(content, contentHTML)

        this.$root.$emit("copyToClipboard", contentHTML, content.toString().replace(/\n$/, ""), Boolean(this.response.plain_text))
        this.$root.$emit("copiedToClipboard")
      }
      post("response/log", {
        response_id: this.response.uuid,
      })
      const responseUpdated = this.response
      responseUpdated.last_used_at = new Date().getTime()
      this.$store.dispatch("response", responseUpdated)
      // this.done = true
      this.$bvModal.hide("buildResponse")
      window.setTimeout(() => {
        this.$bvToast.show("copied")
        this.inputValue = null
        this.questions = []
        this.response = []
        this.questionIndex = 0
        this.optionIndex = 0
        this.variant = null
      }, 200)
    },
    replaceVariables(node, index, nodes) {
      let question = this.questions.find(e => {
        return e.uuid == node.uuid
      })
      if (node.name == "select" && !question.selectedOptions) {
        nodes[index] = null
      }
      if (node) {
        if (node.type == "variable" && node.name == "text") {
          if (question && question.value == null) {
            nodes[index] = null
            return null
          }
          node.text = ""
          node.children = []

          if (question) {
            node.text = question.value
            // // console.log(question)
          }
        }
        if (node.type == "variable" && node.name == "select") {
          if (question && question.selectedOptions) {
            if (typeof node.params[1].concat !== "undefined") {
              if (node.params[1].concat == "list") {
                node.type = "bulleted-list"
              } else {
                node.type = "span"
              }
              node.children = []
              if (node.params[1].concat == "inline") {
                node.text = ""
                node.type = "paragraph"
                let chars = [", "]
                if (typeof node.params[1].concat_char !== "undefined") {
                  chars = node.params[1].concat_char.split("|")
                }
                question.selectedOptions.forEach((option, index) => {
                  if (index + 2 == question.selectedOptions.length && question.selectedOptions.length > 1 && typeof chars[1] !== "undefined") {
                    node.text += option + chars[1]
                  } else {
                    node.text += option
                    if (index + 1 < question.selectedOptions.length) {
                      node.text += chars[0]
                    }
                  }
                })
              } else {
                if (question.selectedOptions.length > 1) {
                  question.selectedOptions.forEach(element => {
                    if (node.type == "bulleted-list") {
                      node.children.push({
                        type: "list-item",
                        children: [
                          {
                            text: element,
                          },
                        ],
                      })
                    } else {
                      node.children.push({
                        type: "paragraph",
                        children: [
                          {
                            text: node.params[1].concat_char + element,
                          },
                        ],
                      })
                    }
                  })
                } else {
                  node.type = "text"
                  node.text = question.selectedOptions[0]
                  // node.children = []
                  // node.children.push({
                  //   children: [
                  //     {
                  //       text: question.selectedOptions[0],
                  //     },
                  //   ],
                  // })
                  // node.text = question.selectedOptions[0]
                }
              }
            } else {
              node.type = "paragraph"
              node.text = question.selectedOptions.join(", ")
            }
          } else {
            node.text = null
          }
        }
        if (node.type == "variable" && node.name == "periodconditional") {
          let date = new Date()
          if (date.getHours() >= 12) {
            node.text = node.params[1].value
          } else {
            node.text = node.params[0].value
          }
        }
        if (node.type == "variable" && node.name == "genderconditional") {
          let gender = this.questions.find(e => {
            return e.id == "gender"
          }).selectedOptions[0]

          node.text =
            node.params.find(e => {
              return e.label == gender
            }).value ?? ""
        }
        if (node.children) {
          node.children.map((n, i, ns) => this.replaceVariables(n, i, ns))
        }
        return node
      }
    },
    async parse() {
      this.loading = true
      let questions = []
      for (const node of Object.values(this.content)) {
        await utils.parse(node, questions, this.variant, null)
      }

      var sortArray = questions.map(function (data, idx) {
        return { idx: idx, data: data }
      })

      sortArray.sort(function (a, b) {
        if (typeof a.data.response !== "undefined") return -1
        return a.idx < b.idx ? -1 : 1
      })

      questions = sortArray.map(function (val) {
        return val.data
      })

      // console.log(questions)

      if (questions.length) {
        this.questionIndex = 0
        this.optionIndex = 0
        this.questions = questions
        this.$root.$emit("showWindow")
        this.$bvModal.show("buildResponse")
      } else {
        this.sendToClipboard()
      }
      this.loading = false
    },
  },
  computed: {
    currentQuestion() {
      return this.questions[this.questionIndex]
    },
  },
  watch: {
    currentQuestion: {
      handler: function (value) {
        if (value && value.remember && value.lastValue) {
          this.inputValue = value.lastValue
        }
        if (value && value.defaultValue) {
          this.inputDefault = value.defaultValue
        }
      },
    },
  },
  beforeDestroy() {
    this.$root.$off("selectResponse", this.selectResponse)
    this.$root.$off("bv::modal::show", this.addListeners)
    this.$root.$off("bv::modal::hide", this.removeListeners)
  },
  created() {
    this.$root.$on("selectResponse", this.selectResponse)
    this.$root.$on("bv::modal::show", this.addListeners)
    this.$root.$on("bv::modal::hide", this.removeListeners)
  },
}
</script>

<style lang="scss" scoped>
@import "@/common/assets/css/_variables.scss";
@import "@/common/assets/css/mixins.scss";

::v-deep .active {
  background-color: var(--color-primary-light) !important;
}

.selectinstructions {
  position: absolute;
  padding: 0.5rem;
  right: 0;
  bottom: 0;
  background: $color-primary;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.8rem;
  border-radius: $border-radius 0 $border-radius 0;
}

::v-deep .ignoremouse {
  pointer-events: none;
}
::v-deep .wrap {
  word-break: break-all;
}

// .check {
//   $size: 48px;
//   width: $size;
//   height: $size;
//   border-radius: 100px;
//   display: flex;
//   align-items: center;
//   justify-content: center;
// }

.checkbox {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 12px;
  border-radius: 4px;
  height: 12px;
  border: 1px solid white;
}
</style>
