<template>
  <div class="mb-3">
    <div class="d-flex align-items-center justify-content-between w-100">
      <div class="d-flex align-items-center">
        <span class="avatar mr-2" v-if="type == 'invitation'">
          <b-icon icon="envelope"></b-icon>
        </span>
        <Avatar v-else size="small" class="mr-2" :name="user.name || user.email" :url="user.avatar" />
        <span class="mr-2" :title="user.email">
          {{ user.name ? user.name : user.email | truncate(20) }}
        </span>
      </div>
      <div class="d-flex align-items-center justify-content-end">
        <section class="ml-auto" v-if="showStatus">
          <span v-if="type == 'invitation'" class="badge badge-info">Invited</span>
          <span v-else-if="user.pivot && user.pivot.role == 'editor'" class="badge badge-info">Can edit</span>
          <span v-else-if="user.pivot && user.pivot.role == 'owner'" class="badge badge-info">Owner</span>
        </section>
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
import Avatar from "@/common/components/Avatar"

export default {
  components: {
    Avatar,
  },
  props: {
    layout: {
      type: String,
      default: "line", // Can be user or invitation
    },
    showStatus: {
      type: Boolean,
      default: true, // Can be user or invitation
    },
    type: {
      type: String,
      default: "user", // Can be user or invitation
    },
    user: {
      type: Object,
      default: null, // Can be user or invitation
    },
  },
}
</script>

<style lang="scss" scoped>
.invitation p,
.invitation .avatar {
  opacity: 0.5;
}
.layout-card {
  flex-direction: column;
}
.avatar {
  $size: 38px;
  border-radius: 50px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  width: $size;
  min-width: $size;
  height: $size;
  background: var(--color-primary-light);
  color: var(--color-primary);
}
</style>
