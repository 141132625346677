<template>
  <div class="d-flex flex-column justify-content-start align-items-start h-100" v-if="value">
    <Slate class="h-100 w-100" :value="value" @onChange="setValue">
      <FormatBar :marks="marks" />
      <div class="editor-container bg-background w-100">
        <div class="editor d-flex flex-grow-1 mx-auto p-5 w-100">
          <Editable :spellCheck="true" class="flex-grow-1" :renderLeaf="renderLeaf" :renderElement="renderElement" placeholder="Your canned response goes here..."></Editable>
        </div>
      </div>
    </Slate>
  </div>
</template>

<script>
import FormatBar from "@/common/components/Editor/FormatBar.vue"
import { renderLeaf, renderElement } from "./render"
import { Slate, Editable } from "slate-vue"
import { Editor, Transforms } from "slate"
let lastSelection = null
// import { SlatePlugin } from "slate-vue"
// import { withHistory } from "slate-history"
// import Vue from "vue"
// Vue.use(SlatePlugin, {
//   editorCreated(editor) {
//     withHistory(editor)
//   },
// })
import { SlateMixin } from "slate-vue"

export default {
  mixins: [SlateMixin],
  data() {
    return {
      renderLeaf,
      renderElement,
      marks: null,
      selection: null,
      value: null,
    }
  },
  props: {
    currentValue: String,
  },
  components: {
    FormatBar,
    Slate,
    Editable,
  },
  beforeDestroy() {
    lastSelection = null
    this.value = null
    this.$root.$off("insertEditorVariable", this.insertEditorVariable)
    window.currentSelection = null
  },
  created() {
    // Must pass a JSON Stringified Value
    this.value = this.currentValue

    this.$editor.isVoid = element => {
      return ["variable"].indexOf(element.type) >= 0 || ["blockvariable"].indexOf(element.type) >= 0
    }

    this.$editor.isInline = element => {
      return ["variable", "link"].indexOf(element.type) >= 0
    }

    this.$root.$on("insertEditorVariable", this.insertEditorVariable)
  },
  methods: {
    setValue() {
      if (this.$editor.selection) lastSelection = this.$editor.selection
      if (this.$editor.selection) window.currentSelection = this.$editor.selection
      this.marks = Editor.marks(this.$editor)
      this.$emit("change", JSON.stringify(this.$editor.children))
      this.$root.$emit("editorchange")
    },
    insertEditorVariable(variable, replace = false, block = false) {
      const node = [
        {
          type: block ? "blockvariable" : "variable",
          name: variable.name,
          uuid: variable.uuid,
          label: variable.label,
          params: variable.params,
          children: [
            {
              text: variable.label,
            },
          ],
        },
      ]

      if (replace) {
        Transforms.setNodes(
          this.$editor,
          {
            params: node[0].params,
            label: node[0].label,
          },
          {
            at: {
              anchor: { path: [0, 0], offset: 0 },
              focus: { path: [99, 99], offset: 99 },
            },
            match: n => {
              return node[0].uuid == n.uuid
            },
            voids: true,
          }
        )
      } else {
        if (lastSelection) {
          Transforms.insertNodes(this.$editor, node, {
            at: lastSelection,
          })
          if (block) {
            Transforms.unwrapNodes(this.$editor, {
              match: n => n.uuid == variable.uuid,
              split: true,
            })
            Transforms.insertNodes(this.$editor, [
              {
                type: "paragraph",
                children: [{ text: "" }],
              },
            ])
          }
        } else {
          Transforms.insertNodes(this.$editor, node)
          if (block) {
            Transforms.insertNodes(this.$editor, [
              {
                type: "paragraph",
                children: [{ text: "" }],
              },
            ])
          }
        }
      }
    },
  },
  watch: {
    currentValue: {
      handler(newValue) {
        Transforms.deselect(this.$editor)
        this.value = newValue
      },
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@/common/assets/css/_variables";
@import "@/common/assets/css/components/_variable";

::v-deep .editor {
  min-height: 100%;
  background: var(--body-bg);
  color: var(--color-default);
  max-width: 890px;

  p,
  ul,
  li {
    margin: 0;
  }
  p {
    line-height: 30px;
  }
}
::v-deep .editor {
  img {
    max-width: 100%;
  }
}
// ::v-deep .blockvariable {
//   width: 100%;
//   text-align: center;
//   background: #eee;
//   font-size: 10px;
//   height: 15px;
//   display: flex;
//   line-height: 10px;
//   font-weight: 500;
//   align-items: center;
//   justify-content: center;
//   text-transform: uppercase;
//   border-radius: 10px;
// }

.editor-container {
  height: 100%;
  overflow-y: auto;
}
</style>
