<template>
  <div class="avatar text-center text-white bg-primary d-flex align-items-center justify-content-center" :class="'avatar--' + this.size">
    <img v-if="url" :src="url" alt="" />
    <span v-else>{{ initials }}</span>
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: null,
    },
    url: {
      type: String,
      default: null,
    },
    size: {
      type: String,
      default: null,
    },
  },
  computed: {
    initials() {
      let name = this.name
      if (!name) {
        name = this.$store.state.user.name ?? this.$store.state.user.email
      }
      if (name) {
        let elms = name.split(/[\s:-]+/)

        if (elms.length >= 2) {
          return elms[0].substr(0, 1) + elms[1].substr(0, 1)
        } else if (elms[0].length >= 2) {
          return elms[0].substr(0, 2)
        } else {
          return elms[0].substr(0, 1)
        }
      }
      return null
    },
  },
}
</script>

<style lang="scss" scoped>
.avatar {
  img {
    width: 100%;
  }
  $size: 50px;
  border: 2px solid var(--color-primary);
  overflow: hidden;
  cursor: default;
  user-select: none;
  width: $size;
  min-width: $size;
  height: $size;
  text-transform: uppercase;
  font-size: 18px;
  &--small {
    font-size: 14px;
    width: 30px;
    min-width: 30px;
    height: 30px;
  }
  border-radius: 50px;
  object-fit: cover;
}
</style>
