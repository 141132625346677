<template>
  <div>
    <span :title="description" @click="openModal(name, params)" class="variable">
      <slot name="name">{{ label }}</slot>
    </span>
    <b-modal :id="name" hide-footer header-border-variant="0">
      <template #modal-header-close>
        <b-icon icon="x"></b-icon>
      </template>
      <div class="px-5 pb-5">
        <div class="mb-4">
          <h3 class="text-center mb-2">
            <slot name="name">{{ label }}</slot>
          </h3>
          <p class="text-center text-muted m-0">
            <slot name="description">{{ description }}</slot>
          </p>
          <div class="bg-background p-3 rounded text-center my-3"><slot name="example"></slot></div>
        </div>
        <!-- <options>
          <div class="my-3">
            <p>Female</p>
            <b-input v-model="female" placeholder="Example 'his'"></b-input>
          </div>
          <div class="my-3">
            <p>Male</p>
            <b-input placeholder="Example 'her'" v-model="male"></b-input>
          </div>
        </options> -->
        <p class="text-danger text-center my-3" v-if="error">{{ error }}</p>
        <div :key="index" v-for="(param, index) in paramsEditable" class="my-3">
          <section v-if="param.name == 'text'">
            <label class="d-flex align-items-center mb-2 mt-3">
              {{ param.label || param.name }}
            </label>
            <b-input autofocus v-model="paramsEditable[index].value"></b-input>
            <section v-if="name == 'text'">
              <Toggle :value="paramsEditable[index].remember || false" @change="paramsEditable[index].remember = $event" class="my-4">Remember last entered value</Toggle>
              <label class="d-flex align-items-center mb-2 mt-3"> Default value (leave empty for nothing) </label>
              <b-input :value="paramsEditable[index].default || null" @change="paramsEditable[index].default = $event"></b-input>
            </section>
          </section>
          <section v-if="param.name == 'select'">
            <label class="d-flex align-items-center mb-2 mt-3">
              {{ param.label || param.name }}
            </label>
            <b-form-select :options="getParamOptions(param.id)" v-model="param.value"></b-form-select>
          </section>
          <section v-if="param.name == 'offset'">
            <div class="row">
              <div class="col">
                <label class="d-flex align-items-center mb-2 mt-3">
                  {{ param.label || param.name }}
                </label>
              </div>
              <div class="col">
                <b-form-input v-model="param.value" type="number"></b-form-input>
                <!-- <b-form-select :options="getParamOptions(param.id)" v-model="param.value"></b-form-select> -->
              </div>
            </div>
          </section>
          <section v-if="param.name == 'multi'">
            <div :key="optionsIndex" v-for="(value, optionsIndex) in param.options">
              <label class="d-flex align-items-center mb-2 mt-3">
                {{ param.label || param.name }} {{ optionsIndex + 1 }}
                <b-icon @click="removeOption(param, optionsIndex)" icon="trash" class="text-danger ml-2" v-if="param.options.length > 1"></b-icon>
              </label>
              <b-input v-model="paramsEditable[index].options[optionsIndex]"></b-input>
            </div>
            <b-button @click="addOption(param)" variant="hover" block class="text-center d-flex align-items-center justify-content-center my-3">
              <b-icon class="mr-1" icon="plus"></b-icon>
              Add option</b-button
            >
            <div class="p-4 bg-background rounded">
              <Toggle :value="paramsEditable[index].multi || false" v-model="paramsEditable[index].multi">Allow multiple options selected</Toggle>
              <div v-show="paramsEditable[index].multi == true">
                <hr class="w-50 mr-auto" />
                <b-form-group class="m-0 mt-4" label="Format as...">
                  <b-form-radio v-model="paramsEditable[index].concat" name="list" value="list">Classic list</b-form-radio>
                  <!-- <b-form-radio v-model="paramsEditable[index].concat" name="customlist" value="list">Display as a list</b-form-radio> -->
                  <div class="row align-items-center my-2">
                    <div class="col-5">
                      <b-form-radio class="w-100" v-model="paramsEditable[index].concat" name="list" value="customlist">Custom list</b-form-radio>
                    </div>
                    <div class="col">
                      <b-form-input class="p-2" id="character" v-model="paramsEditable[index].concat_char" placeholder="Bullet (default nothing)"></b-form-input>
                    </div>
                  </div>
                  <div class="row align-items-center">
                    <div class="col-5">
                      <b-form-radio class="w-100" v-model="paramsEditable[index].concat" name="list" value="inline">
                        Inline
                        <br />
                        <a href="#" @click.stop.prevent="$root.$emit('openUrl', 'https://www.typedesk.com/help/questions/advanced/select-multiple-options-with-the-select-variable')">Help</a>
                      </b-form-radio>
                    </div>
                    <div class="col">
                      <b-form-input class="p-2" id="character" v-model="paramsEditable[index].concat_char" placeholder="Separator (default ', ')"></b-form-input>
                    </div>
                  </div>
                </b-form-group>
              </div>
            </div>
          </section>
        </div>
        <b-button v-if="edit" variant="primary" @click="insertVariable()" class="py-3" block>Edit Variable</b-button>
        <b-button v-else variant="primary" @click="insertVariable()" class="py-3" block>Insert Variable</b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { v4 as uuidv4 } from "uuid"
import Toggle from "@/common/components/Toggle"

export default {
  data() {
    return {
      error: null,
      paramsEditable: null,
      edit: false,
      uuid: null,
    }
  },
  props: {
    name: String,
    label: String,
    description: String,
    params: Array,
    addParams: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Toggle,
  },
  watch: {
    params(newValue) {
      this.paramsEditable = newValue
    },
  },
  methods: {
    addOption(param) {
      param.options.push("")
    },
    removeOption(param, index) {
      delete param.options[index]
      let options = param.options.filter(e => {
        return e !== undefined
      })
      param.options = options
    },
    getParamOptions(id) {
      let options = []
      if (id == "response") {
        this.$store.state.responses.forEach(r => {
          options.push({
            text: r.name,
            value: r.uuid,
          })
        })
      }
      if (id == "date_format") {
        options = [
          {
            text: "Month (eg January)",
            value: "month",
          },
          {
            text: "Day (1 to 31)",
            value: "day_number",
          },
          {
            text: "Month Number (eg 12 for December)",
            value: "month_number",
          },
          {
            text: "Year (eg 2025)",
            value: "year",
          },
          {
            text: "Short Year (eg 25)",
            value: "year_short",
          },
          {
            text: "Day of the week (eg Monday)",
            value: "day",
          },
          {
            text: "Full date (eg 2005-01-05)",
            value: "date",
          },
          {
            text: "Current Time (24h format)",
            value: "time24",
          },
          {
            text: "Current Time (12h format)",
            value: "time12",
          },
        ]
      }
      return options
    },
    insertVariable() {
      this.error = null
      this.paramsEditable.forEach(element => {
        if (element.name == "multi") {
          if (
            element.options.some(e => {
              return e.length <= 0
            })
          ) {
            // this.error = "All the parameters must be filled in before inserting the variable"
            // return false
          }
        }
        if (element.name == "text" || element.name == "select") {
          if (element.value === null || element.value.length <= 0) {
            // this.error = "All the parameters must be filled in before inserting the variable"
            // return false
          }
        }
      })
      if (this.error) return false
      let { name, label } = this
      if (this.paramsEditable[0].id == "response") {
        label = this.$options.filters.truncate(this.$store.state.responses.find(e => e.uuid == this.paramsEditable[0].value).name, 15)
      }

      if (this.paramsEditable[0].id == "date_format") {
        label = this.$options.filters.truncate(this.getParamOptions("date_format").find(e => e.value == this.paramsEditable[0]["value"]).text, 15)
      }
      // this.$gtm.trackEvent({
      //   event: "useVariables", // Event type [default = 'interaction'] (Optional)
      //   client_id: process.env.VUE_APP_CLIENT_ID,
      //   user: this.$store.state.user.uuid,
      // })
      this.$root.$emit(
        "insertEditorVariable",
        {
          name,
          label,
          uuid: this.uuid || uuidv4(),
          params: this.paramsEditable,
        },
        this.edit
      )
      this.$bvModal.hide(name)
    },
    openModal(name, params, uuid = null) {
      console.log(name)

      if (name == this.name) {
        this.edit = false
        this.paramsEditable = JSON.parse(JSON.stringify(params))
        this.uuid = uuid
        if (params && params.length && params[0].value) this.edit = true
        this.$bvModal.show(this.name)
      }
    },
  },
  created() {
    this.paramsEditable = this.params
    this.$root.$on("showVariableModal", this.openModal)
  },
  beforeDestroy() {
    this.$root.$off("showVariableModal", this.openModal)
  },
}
</script>
<style lang="scss" scoped>
@import "@/common/assets/css/components/_variable";

::v-deep #character {
  height: auto;
}
</style>
