import axios from "axios"
// import store from "@/common/store"
var cversion = require('./../../package.json').version;

let defaultHeaders = {
  Accept: "application/json",
  "Client-Id": process.env.VUE_APP_CLIENT_ID,
  "Client-Version": cversion,
}

export async function post(uri, data, additionalHeaders = null) {
  await window.store.restored
  return new Promise((resolve, reject) => {
    let headers = defaultHeaders
    if (additionalHeaders) {
      Object.assign(headers, additionalHeaders)
    }
    if (window.store.state.user && uri.indexOf("register") < 0 && uri.indexOf("login") < 0) {
      const user = window.store.state.user
      Object.assign(headers, {
        Authorization: "Bearer " + user.access_token,
      })
    }
    axios
      .post(process.env.VUE_APP_API_URL + uri, data, {
        headers,
      })
      .then(res => {
        resolve(res)
      })
      .catch(e => {
        // console.log(e)
        if (e.response && e.response.status == 402) {
          global.vm.$root.$emit("showPaywall", e)
        } else if (e.response && e.response.status == 401) {
          global.vm.$root.$emit("logout")
        }
        reject(e)
      })
  })
}

export async function get(uri, additionalHeaders = null) {
  await window.store.restored
  return new Promise((resolve, reject) => {
    let headers = defaultHeaders
    if (additionalHeaders) {
      Object.assign(headers, additionalHeaders)
    }
    if (window.store.state.user && uri.indexOf("register") < 0 && uri.indexOf("login") < 0) {
      const user = window.store.state.user
      Object.assign(headers, {
        Authorization: "Bearer " + user.access_token,
      })
    }
    axios
      .get(process.env.VUE_APP_API_URL + uri, {
        headers,
      })
      .then(res => {
        resolve(res)
      })
      .catch(e => {
        // console.log(e)
        if (e.response && e.response.status == 402) {
          global.vm.$root.$emit("showPaywall")
        } else if (e.response && e.response.status == 401) {
          global.vm.$root.$emit("logout")
        }
        reject(e)
      })
  })
}
