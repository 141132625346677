<template>
  <div class="w-100">
    <div class="alert alert-warning" v-if="inviteError">{{ inviteError }}</div>
    <b-form-input type="email" v-model="currentInviteEmail" class="mb-3" @blur="addEmail" placeholder="Enter an email address and press Enter" @change="addEmail"></b-form-input>
    <p class="text-default-light text-right" v-show="currentInviteEmail">Press Enter to validate</p>
    <div class="d-flex flex-wrap text-left justify-content-start mb-4">
      <div v-for="email in emails" :key="email">
        <b-button @click="removeEmail(email)" class="d-flex small flex-nowrap align-items-center bg-primary-light py-1 px-2 my-1 mr-1 rounded mw-100 text-truncate" :title="email">
          <span class="d-block text-truncate">
            {{ email }}
          </span>
          <b-icon icon="x" class="ml-2"></b-icon>
        </b-button>
      </div>
    </div>
    <b-button @click="sendInvitations()" :disabled="loading || !emails || emails.length == 0" variant="primary" size="lg" block
      >Finish
      <b-icon icon="arrow-right-short"></b-icon>
    </b-button>
  </div>
</template>

<script>
// import LottieAnimation from "@/common/components/LottieAnimation.vue" // import lottie-vuejs
import { post } from "@/common/api"

export default {
  data() {
    return {
      animation: require("@/common/assets/lottie/invite.json"),
      emails: [],
      inviteError: null,
      currentInviteEmail: null,
      name: this.$store.state.user.name,
      loading: false,
    }
  },
  components: {
    // LottieAnimation,
  },
  methods: {
    removeEmail(email) {
      this.emails = this.emails.filter(e => {
        return e !== email
      })
    },
    addEmail() {
      if (this.currentInviteEmail) {
        if (this.emails.length >= 5) {
          this.inviteError = "On your demo plan, you can invite up to 5 users. You can upgrade your account later to add more users."
          return
        }
        if (this.emails.indexOf(this.currentInviteEmail) < 0) {
          if (this.currentInviteEmail.length) {
            this.emails.push(this.currentInviteEmail)
            this.currentInviteEmail = null
          }
        }
      }
    },
    sendInvitations() {
      // this.$gtm.trackEvent({
      //   event: "sendInvitation", // Event type [default = 'interaction'] (Optional)
      //   client_id: process.env.VUE_APP_CLIENT_ID,
      //   user: this.$store.state.user.uuid,
      // })
      this.loading = true
      post("me/subscription/inviteuser", {
        emails: this.emails,
      })
        .catch(e => {
          this.loading = false
          this.inviteError = e.response.data
          // console.log(e)
        })
        .then(() => {
          this.loading = false
          let emails = JSON.parse(JSON.stringify(this.emails))
          this.$emit("done", emails)
          this.emails = []
          this.inviteError = null
          this.currentInviteEmail = null
          // this.openSession()
        })
    },
  },
}
</script>

<style lang="scss" scoped></style>
