<template>
  <div>
    <b-modal title="Upgrade your plan to unlock typedesk features" ref="paywall" id="paywall" hide-footer header-border-variant="0">
      <template #modal-header-close>
        <b-icon height="16" width="16" icon="x"></b-icon>
      </template>
      <div class="px-5">
        <b-icon height="48" icon="shield-check" class="text-warning mb-4" fontScale="3"></b-icon>
        <h2 v-if="errorMessage" v-text="errorMessage"></h2>
        <h2 v-else>Unlock your <span class="text-warning">productivity superpowers</span>. For you & your team!</h2>
        <p class="text-muted my-3">Save hours each week replying to emails, moderation social networks, filling out documents and sending outreach emails.</p>
        <div class="my-4">
          <p class="my-3 d-flex align-items-center">
            <b-icon height="16" width="16" icon="check" class="text-warning mr-3"></b-icon>
            Unlimited responses
          </p>
          <p class="my-3 d-flex align-items-center">
            <b-icon height="16" width="16" icon="check" class="text-warning mr-3"></b-icon>
            Shared & Team Folders
          </p>
          <p class="my-3 d-flex align-items-center">
            <b-icon height="16" width="16" icon="check" class="text-warning mr-3"></b-icon>
            Team Notifications
          </p>
          <p class="my-3 d-flex align-items-center">
            <b-icon height="16" width="16" icon="check" class="text-warning mr-3"></b-icon>
            Image Upload
          </p>
        </div>
        <div class="my-5">
          <p class="text-center text-muted">$5 / user / month - Cancel anytime</p>
          <b-button @click="$root.$emit('autologin', { next: 'checkout' })" block variant="warning" size="lg">Upgrade my plan</b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      errorMessage: null,
    }
  },
  methods: {
    showPaywall(e) {
      this.errorMessage = null
      if (e && e.response && e.response.data && e.response.data.message) {
        this.errorMessage = e.response.data.message
      }
      if (typeof this.$refs.paywall !== "undefined") this.$refs.paywall.show()
    },
  },
  created() {
    this.$root.$on("showPaywall", this.showPaywall)
  },
  beforeDestroy() {
    this.$root.$off("showPaywall", this.showPaywall)
  },
}
</script>

<style lang="scss" scoped>
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/mixins";
// override bootstrap functions to comply with --vars
@import "@/common/assets/css/_functions-override.scss";
@import "@/common/assets/css/_variables.scss";
// Import Bootstrap source files from node_modules
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/utilities/text";
::v-deep .modal-header {
  justify-content: center;
}
::v-deep .modal-title {
  @extend .text-muted;
  font-size: 1rem;
  letter-spacing: -0.03rem;
  padding-left: 56px;
  text-align: center;
  flex: 1;
}
</style>
