<template>
  <div>
    <b-modal id="tour" hide-footer hide-header no-close-on-esc visible no-close-on-backdrop>
      <template #modal-header-close>
        <b-icon icon="x"></b-icon>
      </template>
      <!-- <div class="text-center">
        <img width="24" src="@/common/assets/img/logo_icon.svg" alt="" />
      </div> -->
      <div class="position-relative">
        <transition-group appear v-on:after-enter="enter" name="fadeSwitch" mode="out-in">
          <div key="1" v-show="step == 1" class="text-center step">
            <div class="header">
              <h1>👋</h1>
              <img src="@/common/assets/img/slide_bg.svg" alt="" />
            </div>
            <div class="step__content">
              <h2>Welcome</h2>
              <p>My name is Josh and I'll finish setting up typedesk with you!</p>
              <p class="text-default-light d-flex align-items-center justify-content-center">
                <b-icon class="mr-2" icon="clock"></b-icon>
                1 minute
                <img src="@/common/assets/img/avatar.jpg" width="32" class="rounded-circle ml-3" alt="" />
              </p>
            </div>
          </div>
          <div key="2" v-show="kbtraining && step == 2" class="text-center step w-100">
            <component caption="First, we need to <b>learn how your keyboard works</b>. Please type the text in the box below." @done="next()" :is="kbtraining"></component>
          </div>
          <div key="3" v-show="step == 3" class="text-center step">
            <div class="header">
              <!-- <h1>🪄</h1> -->
              <!-- <img src="@/common/assets/img/slide_bg.svg" alt="" /> -->
              <video loop src="@/common/assets/img/autotype.mp4"></video>
            </div>
            <div class="step__content">
              <h2>Quick Shortcuts</h2>
              <p>Insert your canned responses by typing "/" followed by the response shortcut.</p>
              <div @click="$root.$emit('openUrl', 'https://www.typedesk.com/download')" class="bg-yellow-light p-3 d-inline-block rounded" v-if="!$root.isElectron()">
                💻&nbsp;Desktop app required - Click here to download
              </div>
              <div v-else>
                <p>For instance, type <span class="d-inline-block p-2 rounded lead bg-yellow-light">/eml</span> in the box below.</p>
                <b-form-input class="bg-background autofocus" placeholder="Type /eml here"></b-form-input>
                <p @click="$root.$emit('openUrl', 'https://www.typedesk.com/help/questions/advanced/quick-shortcuts-are-not-working')" class="small text-default-light text-right d-block my-2">
                  Nothing happening? Click here
                </p>
              </div>
            </div>
          </div>
          <div key="4" v-show="step == 4" class="text-center step">
            <div class="header">
              <video loop src="@/common/assets/img/customtrigger.mp4"></video>
            </div>
            <div class="step__content">
              <!-- <h2>Quick Shortcuts</h2> -->
              <p class="d-flex flex-column align-items-center justify-content-center">You can create a quick shortcut for each response and customize the trigger ("/") in the app Preferences.</p>
            </div>
          </div>
          <div key="5" v-show="step == 5" class="text-center step">
            <div class="header">
              <!-- <img src="@/common/assets/img/slide3.svg" alt="" /> -->
              <video loop src="@/common/assets/img/integrations.mp4"></video>
            </div>
            <div class="step__content">
              <h2>It works everywhere!</h2>
              <p>Use your new shortcuts accross all your apps and websites: Zendesk, Slack, Microsoft Office, Facebook, Linkedin...</p>
              <div @click="$root.$emit('openUrl', 'https://www.typedesk.com/download')" class="bg-yellow-light p-3 d-inline-block rounded" v-if="!$root.isElectron()">
                💻&nbsp;&nbsp;Desktop app required - Click here to download
              </div>
            </div>
          </div>
          <div key="6" v-show="step == 6" class="text-center step">
            <div class="header">
              <!-- <img class="w-100" src="@/common/assets/img/share.gif" alt="" /> -->
              <video loop src="@/common/assets/img/share.mp4"></video>
            </div>
            <div class="step__content">
              <h2>Better work with your team</h2>
              <p class="m-0">Create team folders so that every member can add and update templates and stay updated.</p>
              <b-button @click="$root.$emit('openUrl', 'https://www.typedesk.com/share-faq')" class="m-0" variant="link">Learn more about sharing folders</b-button>
            </div>
          </div>
          <div key="7" v-show="step == 7" class="text-center step">
            <div class="header">
              <!-- <img class="w-100" src="@/common/assets/img/share.gif" alt="" /> -->
              <video loop src="@/common/assets/img/variables.mp4"></video>
            </div>
            <div class="step__content">
              <h2>Custom Variables</h2>
              <p class="m-0">Quickly create customized messages thanks to template variables.</p>
              <b-button @click="$root.$emit('openUrl', 'https://www.typedesk.com/help/questions/get-started/how-to-add-variables-to-canned-responses')" class="m-0" variant="link"
                >Learn more about Variables</b-button
              >
            </div>
          </div>
        </transition-group>
      </div>
      <div class="d-flex align-items-center justify-content-between p-4">
        <div class="d-flex justify-content-center align-items-center">
          <span :class="{ active: step == i }" class="bullet mx-1" :key="i" v-for="i in totalSteps"></span>
        </div>
        <div class="d-flex justify-content-center">
          <!-- <b-button @click="step--" variant="lg" class="btn-background">
          Prev
          <b-icon icon="arrow-right"></b-icon>
        </b-button> -->
          <b-button v-show="step !== 2" @click="next()" variant="primary" class="px-4 py-2">
            <span v-if="step == totalSteps">Finish</span>
            <span v-else>Continue</span>
            <b-icon class="ml-2" icon="arrow-right"></b-icon>
          </b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
let done
import { post } from "@/common/api"
export default {
  data() {
    return {
      step: 1,
      totalSteps: 0,
      kbtraining: null,
    }
  },
  created() {
    if (this.$root.isElectron() && !this.$store.state.keymap) {
      // this.kbtraining = () => import("@/views/KeyboardTraining.vue")
    }
  },
  watch: {
    step(newValue) {
      if (newValue == 2 && !this.kbtraining) {
        this.next()
      }
    },
  },
  methods: {
    next() {
      if (this.step < this.totalSteps) {
        this.step++
        document.querySelectorAll("video").forEach(v => {
          v.pause()
        })
        setTimeout(() => {
          let parent = document.querySelectorAll(".step")[this.step - 1]
          if (parent) {
            if (parent.querySelector("video")) {
              parent.querySelector("video").play()
            }
          }
        }, 500)
      } else {
        done = false
        if (!done) {
          post("me/prop/tutorial_seen").then(ret => {
            let user = this.$store.state.user
            user.props.push(ret.data)
            this.$store.dispatch("user", user)
          })
          // this.$gtm.trackEvent({
          //   event: "tourComplete", // Event type [default = 'interaction'] (Optional)
          //   client_id: process.env.VUE_APP_CLIENT_ID,
          //   user: this.$store.state.user.uuid,
          // })
        }
        done = true
        this.$bvModal.hide("tour")
      }
    },
    enter() {
      this.totalSteps = document.querySelectorAll(".step").length
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@/common/assets/css/_variables.scss";
@import "@/common/assets/css/mixins.scss";
.step {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  // min-height: 60vh;
  &__content {
    @extend .px-4, .pt-5;
  }
}
::v-deep .modal-body {
  padding: 0;
  border: none;
}
::v-deep .modal-content {
  // width: 80%;
  // margin: 0 auto;
  border: none;
  overflow: hidden;
  border-radius: 0 0 0.5rem 0.5rem;
  overflow: hidden;
}

.header {
  width: 100%;
  position: relative;
  img {
    width: 100%;
    height: 300px;
    object-fit: cover;
  }
  video {
    width: 100%;
    height: auto;
  }
  h1 {
    font-size: 5rem;
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    z-index: 2;
    justify-content: center;
  }
}
.bullet {
  &.active {
    width: 20px;
    opacity: 1;
  }
  opacity: 0.3;
  display: block;
  border-radius: 50px;
  height: 6px;
  width: 6px;
  background: var(--color-primary);
}
</style>
