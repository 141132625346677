<template>
  <div class="login vw-100 vh-100 d-flex align-items-start pt-8 justify-content-center">
    <div class="form bg-body-bg px-5 py-5 mx-auto rounded">
      <transition name="fade" mode="out-in" duration="300">
        <p v-if="error" class="text-danger text-center m-0 py-5">
          <b-icon icon="x"></b-icon>
          <br />
          {{ error }}
          <b-button @click="error = null" variant="background" block class="py-3 mt-3 d-flex justify-content-center align-items-center">
            <b-icon icon="arrow-left" class="mr-3"></b-icon>
            Back</b-button
          >
        </p>
        <div v-else-if="loading">
          <div class="d-flex align-items-center justify-content-center">
            <img width="24" src="@/common/assets/img/loader_dark.svg" alt="" />
          </div>
        </div>
        <!-- <div v-else-if="invitation.status == 'accepted'" class="text-center">
          <b-icon fontScale="3" class="my-4 text-primary" icon="check-circle"></b-icon>
          <p class="text-center">This invitation has been accepted.</p>
          <b-button v-if="$store.state.user" @click="$router.push({ name: 'Responses' })" variant="primary" size="lg" block class="py-3 mt-2">Go to my dashboard</b-button>
          <b-button v-else @click="$router.push({ name: 'Login' })" variantf="primary" size="lg" block class="py-3 mt-2">Go to my dashboard</b-button>
        </div> -->
        <div v-else-if="invitations.length == 1 && invitation.folder == null" class="text-center p-4">
          <b-icon fontScale="1.2" icon="folder-plus"></b-icon>
          <h3 class="mt-4">Accept Invite</h3>
          <p>{{ invitation.sender.name || invitation.sender.email }} has invited you to his/her team on typedesk.</p>
          <b-button @click="accept(invitation.uuid)" variant="primary" block class="py-3 mt-3">Accept Invite</b-button>
        </div>
        <div v-else-if="invitations.length == 0" class="text-center">
          <b-icon fontScale="1.2" icon="folder-plus"></b-icon>
          <h3 class="mt-4">Join shared folder</h3>
          <p>You have no pending invitation on your account</p>
          <b-button @click="done()" variant="background" block class="py-3 mt-3">Continue</b-button>
        </div>
        <div v-else class="text-center">
          <b-icon fontScale="1.2" icon="folder-plus"></b-icon>
          <h3 class="mt-4">Accept Invitations</h3>
          <p v-if="$store.state.user && invitation && invitation.email.toLowerCase() !== $store.state.user.email.toLowerCase()" class="text-danger">
            You are currently logged in under {{ $store.state.user.email }}. Please log in with the email used in the invitation.
          </p>
          <p v-else>You have pending invitations on your account.</p>
          <div v-if="shouldLogin">
            <!-- <p>But first, you need to login or create your account.</p> -->
            <b-button v-if="shouldLogin" @click="login()" variant="primary" size="lg" block class="py-3 mt-5">Log in or create my account</b-button>
          </div>
          <div class="mt-5" v-else>
            <div class="text-left" v-for="invitation in invitations" :key="invitation.uuid">
              <div class="row align-items-center">
                <div class="col my-1">
                  <span v-if="invitation.folder && invitation.folder.name">
                    {{ invitation.folder.name }}
                    <br />
                    <span class="text-muted">{{ invitation.sender.email }}</span>
                  </span>
                  <span v-else>
                    {{ invitation.sender.email }}
                  </span>
                </div>
                <div class="col text-right">
                  <b-button @click="accept(invitation.uuid)" variant="primary" class="py-2 px-3">
                    Accept
                    <b-icon icon="arrow-right" class="ml-2"></b-icon>
                  </b-button>
                </div>
              </div>
            </div>
            <b-button @click="done()" variant="background" block class="py-3 mt-3">Continue</b-button>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { get, post } from "@/common/api"
export default {
  data() {
    return {
      loading: true,
      error: null,
      invitation: null,
      invitations: null,
      shouldLogin: false,
    }
  },
  methods: {
    login() {
      this.$router.push({
        name: "Login",
        params: {
          next: {
            name: "AcceptInvitation",
            params: {
              code: this.$route.params.code,
            },
          },
        },
      })
    },
    done() {
      this.$root.openSession().then(() => {
        this.$router.push({ name: "Responses" })
      })
    },
    accept(uuid) {
      this.loading = true
      let url = this.invitations.length == 1 && this.invitation.folder == null ? "me/subscription/acceptinvitation" : "folder/invitation/accept"
      post(url, {
        uuid,
      })
        .then(() => {
          this.invitations = this.invitations.filter(i => {
            return i.uuid !== uuid
          })
          this.invitation = this.invitations[0]
          if (this.invitations.length) {
            this.loading = false
          } else {
            this.done()
          }
        })
        .catch(e => {
          this.loading = false
          console.log(e)
          this.error = e.response.data.message ?? e
        })
    },
  },
  computed: {
    folderStr() {
      let names = this.invitations.map(e => e.name)
      return names.join(", ")
      // if(this.invitationStr.length > 1){
      // }
    },
  },
  created() {
    try {
      setTimeout(async () => {
        await window.store.restored
        if (!this.$store.state.user) {
          this.shouldLogin = true
        }
        // Retrieve invitation info
        let url
        if (this.$route.params.code) {
          url = "session/invitation/" + this.$route.params.code
        } else {
          url = "session/invitations"
        }
        get(url)
          .then(ret => {
            this.loading = false
            this.invitations = ret.data
            this.invitation = this.invitations[0]
            if (this.$store.state.user && this.invitations[0] && this.invitations[0].email.toLowerCase() !== this.$store.state.user.email.toLowerCase()) {
              // this.$root.$emit("logout")
              this.shouldLogin = true
            }
          })
          .catch(e => {
            this.loading = false
            console.log(e)
            this.error = "This invitation has either expired or doesn't exist. Please contact the sender to request a new invitation."
          })
      }, 0)
    } catch (error) {
      this.loading = false
      this.error = error
    }
  },
}
</script>

<style lang="scss" scoped>
.login {
  @media (prefers-color-scheme: light) {
    background: url("~@/common/assets/img/bg_login.svg") left -200px bottom 100px no-repeat;
  }
}
.theme-light .login {
  background: url("~@/common/assets/img/bg_login.svg") left -200px bottom 100px no-repeat;
}
.form {
  max-width: 525px;
  width: 100%;
}
</style>
