<template>
  <div class="sidebar border-left border-border h-100 p-4">
    <b-card no-body>
      <b-tabs fill pills>
        <b-tab title="Variables" class="pt-4">
          <div class="text-dark text-decoration-none text-left d-flex p-1 mb-2" v-if="currentVariables.length">
            <p class="m-0">
              Existing variables
              <br />
              <span class="text-muted">Insert previously used variables</span>
            </p>
            <!-- <b-icon icon="chevron-down"></b-icon> -->
          </div>
          <section class="mb-4 p-3 bg-background rounded-lg mb-2" v-if="currentVariables.length">
            <!-- <b-button v-b-toggle.existing variant="link" class="text-default text-decoration-none text-left d-flex px-0">
              <p class="mt-0 mb-2 pl-1">
                Existing Variables
                <br />
                <span class="text-muted">Reuse existing variables several times to save time</span>
              </p>
              <b-icon icon="chevron-down"></b-icon>
            </b-button> -->
            <div id="existing">
              <div class="d-flex flex-wrap align-items-start">
                <span :key="variable.uuid" v-for="variable in currentVariables" @click="$root.$emit('insertEditorVariable', variable)" class="variable">{{ variable.params[0].value }}</span>
              </div>
            </div>
          </section>
          <section class="mb-4">
            <div class="text-dark text-decoration-none text-left d-flex p-1 mb-2">
              <p class="m-0">
                Custom Placeholders
                <br />
                <span class="text-muted">Custom Placholders are replaced on the fly with your input (text, select)</span>
              </p>
              <!-- <b-icon icon="chevron-down"></b-icon> -->
            </div>
            <div>
              <b-card class="px-0">
                <div class="d-flex flex-wrap">
                  <InsertVariableModal
                    name="genderconditional"
                    label="Gender Conditional"
                    description="Gender conditional variables will be selected based on the gender of the recipient. This will add an extra step asking for the gender when selecting the canned response."
                    :params="[
                      { name: 'text', id: 'female', label: 'Female', value: null },
                      { name: 'text', id: 'male', label: 'Male', value: null },
                      { name: 'text', id: 'neutral', label: 'Neutral', value: null },
                    ]"
                  >
                    <template #example> I'll let you know as soon as I hear back from <span class="editor-variable">her</span> and con...</template>
                  </InsertVariableModal>
                  <InsertVariableModal
                    name="periodconditional"
                    label="Morning/Afternoon Conditional"
                    description="Insert content depending on whether it's morning or afternoon at the time of using."
                    :params="[
                      { name: 'text', id: 'morning', label: 'Morning', value: null },
                      { name: 'text', id: 'afternoon', label: 'Afternoon', value: null },
                    ]"
                  >
                    <template #example>Have a great <span class="editor-variable">afternoon</span> and I'll k...</template>
                  </InsertVariableModal>
                  <InsertVariableModal
                    name="text"
                    label="Text"
                    description="This variable is replaced on the fly by a custom value you enter each time"
                    :params="[{ name: 'text', id: 'question', label: 'Question (eg. Insert the reference number)', value: null }]"
                  >
                    <template #example>Your internal reference number is #<span class="editor-variable">GHJ67HH</span>. Please keep th...</template>
                  </InsertVariableModal>
                  <InsertVariableModal
                    name="select"
                    label="Select"
                    description="Offers a selection of options to replace the variable with"
                    addParams
                    :params="[
                      { name: 'text', id: 'question', label: 'Question (eg. Select the day)', value: null },
                      { name: 'multi', id: 'options', label: 'Option', concat: 'list', options: [''] },
                    ]"
                  >
                    <template #example>Your expected delivery day is next <span class="editor-variable">Friday</span> </template>
                  </InsertVariableModal>
                </div>
              </b-card>
            </div>
          </section>
          <section class="mb-4">
            <div class="text-default text-decoration-none text-left d-flex p-1 mb-2">
              <p class="m-0">
                General Variables
                <br />
                <span class="text-muted">General Variables are automatically filled in when using the canned response</span>
              </p>
              <!-- <b-icon icon="chevron-down"></b-icon> -->
            </div>
            <div>
              <b-card class="px-0">
                <div class="d-flex flex-wrap">
                  <Variable name="email" label="Your email" />
                  <Variable name="firstname" label="Your first name" />
                  <Variable name="lastname" label="Your last name" />
                  <Variable name="fullname" label="Your full name" />
                  <Variable name="role" label="Your role" />
                  <Variable name="business" label="Your business name" />
                  <Variable name="tab" label="Tab key" :block="true" />
                  <Variable name="enter" label="Enter key" :block="true" />
                </div>
              </b-card>
            </div>
          </section>
          <div class="text-default text-decoration-none text-left d-flex p-1 mb-2">
            <p class="m-0">
              Dynamic Variables
              <br />
              <span class="text-muted">Dynamic Variables change dynamically according to the context</span>
            </p>
            <!-- <b-icon icon="chevron-down"></b-icon> -->
          </div>
          <div>
            <b-card class="px-0">
              <div class="d-flex flex-wrap">
                <!-- <Variable name="currentdate" label="Date" /> -->
                <InsertVariableModal
                  name="date"
                  label="Date & Time"
                  description="Format a date or time relative to today."
                  :params="[
                    {
                      name: 'select',
                      id: 'date_format',
                      label: 'Format',
                      value: null,
                    },
                    {
                      name: 'offset',
                      id: 'date_offset',
                      label: 'Add/Remove Days',
                      value: 0,
                    },
                  ]"
                >
                  <template #example>I'll get back in touch next <span class="editor-variable">Monday</span>.</template>
                </InsertVariableModal>
                <Variable name="clipboard" label="Clipboard" />
                <InsertVariableModal
                  name="response"
                  label="Another Response"
                  description="Embed another canned response within this response."
                  :params="[
                    {
                      name: 'select',
                      id: 'response',
                      label: 'Select a canned response',
                      value: null,
                    },
                  ]"
                >
                  <template #example>For instance, insert your <span class="editor-variable">signature</span> after each canned response dynamically.</template>
                </InsertVariableModal>
                <InsertVariableModal
                  name="random"
                  label="Random Value"
                  description="This variable is replaced by a random piece of content within a list you define"
                  :params="[{ name: 'multi', id: 'options', label: 'Value', options: [''] }]"
                >
                  <template #example
                    >For instance, <span class="editor-variable">happy</span>, <span class="editor-variable">excited</span>, <span class="editor-variable">thrilled</span>,
                    <span class="editor-variable">pumped</span>...</template
                  >
                </InsertVariableModal>
              </div>
            </b-card>
          </div>
          <div class="text-center">
            <b-button
              @click="$root.$emit('openUrl', 'http://www.typedesk.com/help/questions/get-started/how-to-add-variables-to-canned-responses')"
              variant="hover"
              class="mt-4 small text-default-lighter text-center d-flex align-items-center mx-auto"
            >
              <b-icon icon="question-circle-fill" class="mr-2"></b-icon>
              Click here to learn more about variables</b-button
            >
          </div>
        </b-tab>
        <b-tab title="Variants" class="pt-4"
          ><b-card-text>
            <div class="bg-background rounded-lg p-4 mb-3 text-center">Create variants when you need translations or a slightly different version of the same canned response.</div>
            <div v-if="showVariantInput">
              <b-input
                @blur="updateVariantName($event)"
                @keydown.esc="showVariantInput = false"
                @keydown.enter="updateVariantName($event)"
                autofocus
                class="editable p-3"
                :value="selectedVariant.name"
              ></b-input>
              <p class="text-muted text-right small mt-2">Press Enter to validate</p>
            </div>
            <div v-else>
              <draggable v-model="variantsEdit" @start="drag = true" @end="drag = false">
                <div class="variant" :class="{ active: variant.selected }" :key="variant.name" v-for="variant in variantsEdit">
                  <a @click.stop.prevent="$emit('selectVariant', variant)" class="h-auto btn p-3 text-primary text-left d-flex align-items-center justify-content-between" href="#">
                    <span class="d-flex align-items-center mr-3">
                      <!-- <b-icon class="mr-2" v-show="variant.selected" icon="arrow-right"></b-icon> -->
                      <b-icon class="mr-2 grip" icon="grip-vertical"></b-icon>
                      {{ variant.name }}
                    </span>
                    <section v-show="variant.selected" class="d-flex align-items-center w-auto">
                      <b-button v-b-tooltip.hover title="Duplicate variant" @click.stop.prevent="$emit('duplicateVariant', variant)" class="p-0 px-1" variant="link text-default">
                        <b-icon icon="box-arrow-down"></b-icon>
                      </b-button>
                      <b-button v-b-tooltip.hover title="Edit variant name" @click="showVariantInput = true" class="p-0 px-1" variant="link text-default">
                        <b-icon icon="pen"></b-icon>
                      </b-button>
                      <b-button @click.stop="$emit('deleteVariant', variant)" v-if="variants.length > 1" class="p-0 px-1 text-danger" variant="link">
                        <b-icon icon="trash"></b-icon>
                      </b-button>
                    </section>
                  </a>
                </div>
              </draggable>
              <b-button variant="primary-light" class="mt-3" size="lg" @click.stop="$emit('addVariant')" block>
                <b-icon icon="plus"></b-icon>
                Add variant
              </b-button>
            </div>
          </b-card-text>
        </b-tab>

        <b-tab title="Settings" class="pt-4">
          <b-card-text>
            <!-- <b-button v-b-toggle.collapse-1 variant="primary">Toggle Collapse</b-button>
            <b-collapse id="collapse-1" class="mt-2">
              <b-card>
                <p class="card-text">Collapse contents Here</p>
                <b-button v-b-toggle.collapse-1-inner size="sm">Toggle Inner Collapse</b-button>
                <b-collapse id="collapse-1-inner" class="mt-2">
                  <b-card>Hello!</b-card>
                </b-collapse>
              </b-card>
            </b-collapse> -->
            <div class="my-2">
              <div class="m-0 d-flex align-items-center">
                <!-- <b-icon class="mr-2" icon="keyboard"></b-icon> -->
                <div class="shortcut text-yellow-dark bg-yellow-light p-2 rounded text-left d-flex align-items-center mr-2">
                  <b-icon icon="lightning-fill" scale="1"></b-icon>
                </div>
                <span class="mr-2"> Quick Response Shortcut</span>
                <span v-b-tooltip.hover title="Create a shortcut to quickly access your canned response. Between 1 and 30 characters.">
                  <b-icon icon="question-circle-fill"></b-icon>
                </span>
              </div>

              <b-input
                @keydown.shift="showLayout = true"
                @keyup="showLayout = false"
                trim
                class="my-3"
                :state="shortcutError ? false : null"
                v-model="shortcutEdit"
                placeholder="eg. signature"
              ></b-input>
              <p v-if="shortcutError" class="text-danger text-right small" v-html="shortcutError"></p>
              <p class="small text-right text-muted" v-if="shortcutEdit && !shortcutError">
                Type <code class="rounded">:{{ shortcutEdit }}</code> or <code class="rounded">/{{ shortcutEdit }}</code> (no space or tab after) anywhere to insert your canned response<span
                  v-if="!$root.isElectron()"
                >
                  (desktop app required)</span
                >.
              </p>
            </div>
            <div class="mt-5">
              <p class="m-0">
                Private note
                <br />
                <span class="text-muted">It will be displayed for you and your team only, but not sent to the clipboard.</span>
              </p>
              <b-form-textarea class="my-3 bg-background privatenote" id="textarea" v-model="privateNoteEdit" placeholder="Visible for you only" rows="3" max-rows="6"></b-form-textarea>
            </div>
            <div class="mt-5 d-flex align-items-center justify-content-between">
              <p class="m-0">
                Copy as plain text
                <br />
                <span class="text-muted">Select this option to copy simple text, like raw HTML, commands, URLs etc</span>
              </p>
              <Toggle v-model="plainTextEdit" />
            </div>
            <div class="mt-5 text-center text-default-lighter" v-if="layout && showLayout">
              {{ layout }}
            </div>
          </b-card-text>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import utils from "@/common/components/Editor/parse.js"
import Variable from "@/common/components/Editor/Variable"
import InsertVariableModal from "@/common/components/Editor/InteractiveVariableModal"
import Toggle from "@/common/components/Toggle"
import draggable from "vuedraggable"

export default {
  components: {
    Variable,
    InsertVariableModal,
    Toggle,
    draggable,
  },
  data() {
    return {
      showVariantInput: false,
      shortcutEdit: null,
      shortcutError: false,
      plainTextEdit: false,
      privateNoteEdit: null,
      variantsEdit: [],
      currentVariables: [],
      layout: null,
      showLayout: false,
    }
  },
  methods: {
    updateVariantName(e) {
      this.$emit("updateVariantName", e.target.value)
      this.showVariantInput = false
    },
  },
  props: {
    variants: Array,
    shortcut: String,
    privateNote: String,
    plainText: Boolean,
    uuid: String,
  },
  computed: {
    selectedVariant() {
      return this.variants.find(e => e.selected)
    },
  },
  created() {
    this.$root.$on("layout", layout => (this.layout = layout))
    this.$root.$emit("getLayout")

    this.$root.$on("insertEditorVariable", variable => {
      if (variable.name == "text" || variable.name == "select") {
        // Add to current variables if not already listed
        if (
          this.currentVariables.some(e => {
            return e.uuid == variable.uuid
          })
        ) {
          this.currentVariables.find(e => {
            return e.uuid == variable.uuid
          }).params = variable.params
        } else {
          this.currentVariables.push(variable)
        }
      }
    })
    if (this.shortcut) this.shortcutEdit = this.shortcut

    this.plainTextEdit = this.plainText
    this.privateNoteEdit = this.privateNote
    this.variantsEdit = this.variants

    let variables = []

    for (let variant of this.variants) {
      utils.getVariables({ children: JSON.parse(variant.content) }, variables)
    }

    this.currentVariables = variables
  },
  watch: {
    variants() {
      this.variantsEdit = this.variants
    },
    variantsEdit(newValue) {
      this.$emit("updateVariants", newValue)
    },
    privateNoteEdit(newValue) {
      this.$emit("setPrivateNote", newValue)
    },
    plainTextEdit(newValue) {
      this.$emit("setPlainText", newValue)
    },
    shortcutEdit(newValue) {
      this.shortcutError = false

      if (newValue !== newValue.toLowerCase().replace(/[^0-9a-z]/gi, "")) {
        this.shortcutError = "Your shortcut must be all lowercase letters only."
      }
      let dup = this.$store.state.responses.find(elm => {
        if (elm.shortcut) {
          return elm.shortcut == newValue && elm.uuid !== this.uuid
        }
      })
      if (dup) {
        this.shortcutError = "This shortcut is already used in your account. Please pick another shortcut."
        return
      }

      let similar = this.$store.state.responses.find(elm => {
        if (elm.shortcut) {
          return newValue.indexOf(elm.shortcut) === 0 && elm.uuid !== this.uuid
        }
      })
      if (similar) {
        this.shortcutError = "Another response has a similar shortcut <b>(" + similar.shortcut + ")</b>, which will create unexpected behaviours. Please pick a different shortcut."
        return
      }
      this.$emit("changeShortcut", newValue)
    },
  },
}
</script>

<style lang="scss" scoped>
.sidebar {
  overflow: scroll;
}
code {
  background: var(--color-yellow-light);
  color: var(--color-yellow-dark);
  padding: 2px;
}
// ::v-deep .nav-link {
//   color: var(--color-default) !important;
// }
// ::v-deep .nav-link.active {
//   background: var(--color-primary-light) !important;
//   color: var(--color-primary) !important;
// }
.btn.not-collapsed svg {
  transform: rotate(180deg);
}
::v-deep .editable,
.editable:focus {
  border: none;
  box-shadow: none;
  background: var(--color-background);
}

// ::v-deep .privatenote:focus {
//   box-shadow: 0 0 0 0.2rem var(--color-warning-light);
//   border-color: var(--color-warning-light);
// }
.form-control {
  height: 60px;
}
.variant a {
  height: 60px;
  color: var(--color-default) !important;
}
.variant.active a {
  background-color: var(--color-primary-light);
  color: var(--color-default) !important;
}
.variant a:focus {
  box-shadow: none;
}
// .variant:not(.active) a:hover {
//   background-color: var(--color-primary-light);
//   color: var(--color-default) !important;
// }

::v-deep .variable {
  margin: 0 1rem 1rem 0;
}
.grip {
  opacity: 0.5;
  cursor: move;
}

#existing .variable {
  margin: 5px;
}
</style>
