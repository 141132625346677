<template>
  <b-dropdown boundary="window" lazy id="colors" no-caret variant="hover" :toggle-class="{ active: isActive() }">
    <template #button-content>
      <b-icon icon="droplet"></b-icon>
    </template>
    <v-swatches :swatches="swatches" v-model="color" inline></v-swatches>
  </b-dropdown>
</template>

<script>
import VSwatches from "vue-swatches"
import "vue-swatches/dist/vue-swatches.css"
import { Transforms, Editor } from "slate"
import { SlateMixin } from "slate-vue"

export default {
  mixins: [SlateMixin],
  data() {
    return {
      color: null,
      swatches: ["#000000", "#232f3d", "#3e546e", "#2fcc70", "#3298db", "#2880b9", "#a463bf", "#8e43ad", "#f39c19", "#e94c3b", "#bf392b"],
    }
  },
  components: {
    VSwatches,
  },
  methods: {
    setColor(color) {
      Transforms.select(this.$editor, window.currentSelection)
      this.$editor.addMark("color", {
        value: color,
      })
    },
    isActive() {
      const [match] = Editor.nodes(this.$editor, {
        match: n => {
          return !!n.color
        },
      })

      return !!match
    },
  },
  watch: {
    color(newValue) {
      this.setColor(newValue)
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@/common/assets/css/components/_vueswatches.scss";

::v-deep {
  .dropdown-menu {
    max-width: 300px;
    padding: 1rem;
  }
}
</style>
