<template>
  <div class="responselist">
    <div class="p-3 d-flex align-items-center bg-background">
      <b-button @click="$root.$emit('showMenu')" class="menu px-1 p-0 mr-2" variant="hover">
        <b-icon icon="list"></b-icon>
      </b-button>

      <b-form-group class="search m-0 flex-grow-1">
        <b-icon v-if="search" class="icon close" @click="search = null" icon="x"></b-icon>
        <b-icon v-else class="icon" icon="search"></b-icon>
        <b-input
          ref="search"
          @keydown.up.prevent.stop
          @keydown.down.prevent.stop
          debounce="200"
          autofocus
          v-model="search"
          id="search"
          @keydown.esc="search = null"
          :placeholder="searchPlaceholder"
          class="px-3"
        >
        </b-input>
      </b-form-group>
      <b-dropdown lazy variant="hover" id="sort-dropdown" toggle-class="text-decoration-none px-1 p-0 ml-2" no-caret>
        <template #button-content>
          <b-icon icon="sort-down"></b-icon>
        </template>
        <b-dropdown-text class="text-muted">Sort by</b-dropdown-text>
        <b-dropdown-item @click="$store.dispatch('sort', 'alpha')"><span :class="{ 'text-primary': $store.state.sort == 'alpha' }">Name (A - Z)</span></b-dropdown-item>
        <b-dropdown-item @click="$store.dispatch('sort', 'date')"><span :class="{ 'text-primary': $store.state.sort == 'date' }">Newer first</span></b-dropdown-item>
        <b-dropdown-item @click="$store.dispatch('sort', 'lastuse')"><span :class="{ 'text-primary': $store.state.sort == 'lastuse' }">Last used first</span></b-dropdown-item>
        <b-dropdown-item @click="$store.dispatch('sort', 'popularity')"><span :class="{ 'text-primary': $store.state.sort == 'popularity' }">Most used first</span></b-dropdown-item>
      </b-dropdown>
      <Notifications
        v-if="($store.state.user && $store.state.user.notifications && $store.state.user.notifications.length) || ($store.state.user && $store.state.user.invitations_count > 0)"
        class="mr-1"
      />
    </div>
    <div v-if="type == 'archives'" class="p-3 bg-body-bg text-center text-muted d-none d-md-block">
      <b-icon class="mx-2" icon="archive"></b-icon>
      Your archives are kept 15 days
    </div>
    <div id="navigate" v-else class="p-3 bg-body-bg text-muted d-md-flex d-none flex-nowrap align-items-center justify-content-center" style="height: 50px">
      Select response with keys
      <b-icon class="mx-2" icon="arrow-up-square-fill"></b-icon>
      and
      <b-icon class="mx-2" icon="arrow-down-square-fill"></b-icon>
      <!-- <span style="text-overflow: ellipsis; overflow: hidden"> on your keyboard </span> -->
    </div>
    <div class="scroll-container responsescroll px-3 pb-3 pt-4 pt-lg-0 mt-md-0">
      <div class="scroll" v-if="responses.length">
        <ResponseCard
          @select="$emit('selectResponse', response)"
          @showPreview="$emit('showPreview')"
          :selected="currentResponse && response.uuid == currentResponse.uuid"
          :key="response.uuid"
          v-bind="response"
          v-for="response in responses"
          ref="response"
        />
        <div v-if="!$store.state.user.subscription && !$store.state.user.parent_subscription" class="bg-body-bg cursor-pointer text-warning text-center p-4" @click="$root.$emit('showPaywall')">
          <p class="text-center">
            <b-icon icon="lock-fill"></b-icon>
          </p>
          You are on our Free plan. Only your first 10 responses are available.<br />Click here to upgrade your account
        </div>
        <div class="d-flex align-items-center justify-content-center mt-4" v-else>
          <b-button @click="createResponse()" variant="hover" class="text-default-light py-2">Create response</b-button>
        </div>
      </div>
      <div class="d-flex align-items-center text-muted px-5 text-center justify-content-center" v-else-if="type == 'archives'">Deleted responses will be archived here for 15 days</div>
      <div class="d-flex align-items-center justify-content-center" v-else>
        <b-button @click="createResponse()" variant="hover" class="text-default-light py-2">Create response</b-button>
      </div>
    </div>
  </div>
</template>

<script>
import ResponseCard from "@/common/components/ResponseCard"
import Notifications from "@/common/components/Notifications"
// import { throttle } from "throttle-debounce"
// let isScrolling = false
export default {
  data() {
    return {
      search: null,
      parent,
      currentChunk: 1,
    }
  },
  components: {
    ResponseCard,
    Notifications,
  },
  computed: {
    // responsesChunked() {
    //   return this.responses.slice(0, this.currentChunk * 15)
    // },
  },
  methods: {
    createResponse() {
      if (this.$route.params.uuid) {
        this.$router.push({
          name: "CreateResponse",
          params: {
            folder: this.$route.params.uuid,
          },
        })
      } else {
        this.$router.push({ name: "CreateResponse" })
      }
    },
    hideDropdown(index) {
      if (this.$refs.response[index]) {
        this.$refs.response[index].hideDropdown()
      }
    },
    resetSearch() {
      this.search = null
    },
    focusSearch() {
      if (this.$refs.search) {
        this.$refs.search.focus()
      }
    },
    // scroll() {
    //   if (!isScrolling) {
    //     isScrolling = true
    //     document.querySelectorAll(".response").forEach(e => e.classList.add("scrolling"))
    //   }
    // },
    // unscroll() {
    //   if (document.querySelectorAll(".response.scrolling").length) {
    //     isScrolling = false
    //     document.querySelectorAll(".response").forEach(e => e.classList.remove("scrolling"))
    //   }
    // },
  },
  props: {
    type: String,
    responses: Array,
    shouldScroll: Boolean,
    currentResponse: Object,
    searchPlaceholder: {
      type: String,
      default: "Search in all responses...",
    },
  },
  mounted() {
    if (document.querySelector(".response.active")) {
      document.querySelector(".response.active").scrollIntoView(true)
    }
  },
  created() {
    this.$root.$on("focus", this.focusSearch)
    this.$root.$on("copyToClipboard", this.resetSearch)
  },
  beforeDestroy() {
    this.$root.$off("focus", this.focusSearch)
    this.$root.$off("copyToClipboard", this.resetSearch)
  },
  watch: {
    search(newValue) {
      this.$emit("search", newValue)
    },
    responses() {
      if (this.$store.state.sort == "lastuse") {
        // setTimeout(() => {
        if (document.querySelector(".response.active")) {
          document.querySelector(".response.active").scrollIntoView()
        }
        // }, 100)
      }
    },
    currentResponse: {
      immediate: true,
      handler() {
        // document.querySelectorAll(".response").forEach(e => e.classList.add("scrolling"))
        if (this.shouldScroll) {
          if (this.currentResponse) {
            let index = this.responses.findIndex(opt => opt.uuid === this.currentResponse.uuid)
            if (index) {
              if (Math.ceil(index / 15) > this.currentChunk) this.currentChunk = Math.ceil(index / 15)
            }
            // document.querySelector(".response.active").scrollIntoView(true)
          }
          this.$nextTick(() => {
            let active = document.querySelector(".response.active")
            if (active) {
              let parent = document.querySelector(".scroll")
              let minDisplayed = parent.scrollTop
              let maxDisplayed = parent.scrollTop + parent.clientHeight
              if (active.offsetTop + active.offsetHeight > maxDisplayed) {
                document.querySelector(".response.active").scrollIntoView(true)
              }
              if (active.offsetTop < minDisplayed) {
                document.querySelector(".scroll").scrollTop = active.offsetTop - parent.clientHeight + active.offsetHeight
              }
            }
          })
        }
      },
    },
  },
}
</script>

<style scoped lang="scss">
@import "@/common/assets/css/_variables.scss";
@import "@/common/assets/css/mixins.scss";

@include scroll;

#navigate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

::v-deep .search {
  position: relative;
  .form-control {
    border-radius: 5px;
    height: 42px;
    // background: var(--color-background-dark);
    user-select: none;
    // &:focus {
    //   // background: white;
    //   box-shadow: 0 0 0px 4px var(--color-primary-background);
    //   border: 1px solid var(--color-primary);
    // }
    // &:hover,
    // &:focus:hover {
    //   // background: white;
    //   border: 1px solid var(--color-primary);
    // }
  }
  .icon {
    position: absolute;
    right: $spacer;
    top: calc(50% - 8px);
  }
  .close {
    font-size: 150%;
    top: calc(50% - 12px);
  }
}
.scroll {
  visibility: visible;
  // overflow: auto;
  // position: relative; // Important for offsetTop value
  // height: 100%;
  width: 100%;
  // padding-bottom: 50px;
}
.responselist {
  height: 100vh;
  @include media-breakpoint-up(sm) {
    display: grid;
    grid-template-rows: min-content min-content auto;
    // grid-template-rows: min-content auto;
    overflow-y: hidden;
  }
}
.menu {
  display: none;
  @include media-breakpoint-down(md) {
    display: block;
  }
}
</style>
