<template>
  <div>
    <b-form-select :active="isMarkActive()" :options="fonts" v-model="font"></b-form-select>
  </div>
</template>

<script>
import { SlateMixin } from "slate-vue"
import { Editor } from "slate"

export default {
  mixins: [SlateMixin],
  data() {
    return {
      font: null,
    }
  },
  computed: {
    fonts() {
      return [
        {
          text: "Default",
          value: "",
        },
        {
          text: "Calibri",
          value: "Calibri,Arial,Helvetica,sans-serif",
        },
        {
          text: "Arial",
          value: "Arial,Helvetica,sans-serif",
        },
        {
          text: "Helvetica",
          value: "Helvetica,Arial,sans-serif",
        },
        {
          text: "Tahoma",
          value: "Tahoma,sans-serif",
        },
        {
          text: "Monaco",
          value: "Monaco,sans-serif",
        },
        {
          text: "Times",
          value: "Times,serif",
        },
        {
          text: "Courrier",
          value: "Courrier,sans-serif",
        },
      ]
    },
  },
  methods: {
    toggleMark() {
      this.$editor.addMark("font", this.font)
    },
    isMarkActive() {
      let marks = Editor.marks(this.$editor)

      if (marks && marks.font) {
        this.font = marks.font
      } else {
        this.font = ""
      }
      // return marks ? marks[format] === true : false
    },
  },
  watch: {
    font() {
      this.toggleMark()
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .custom-select {
  width: 150px;
  max-height: 40px;
  padding-top: 0;
  padding-bottom: 0;
  font-size: 90%;
}
</style>
