<template>
  <span contenteditable="false" @click="$root.$emit('showVariableModal', name, params, uuid)" class="editor-variable">
    <span>{{ label }}</span>
    <slot></slot>
  </span>
</template>

<script>
export default {
  props: {
    name: String,
    uuid: String,
    label: String,
    params: Array,
  },
}
</script>

<style lang="scss" scoped>
@import "@/common/assets/css/components/_variable";
</style>
