<template>
  <div
    v-if="folder"
    :title="folder.name"
    @drop="drop($event)"
    @dragover.prevent="setDragover(true)"
    @dragleave.prevent="setDragover(false)"
    @dragenter.prevent="setDragover(true)"
    :class="{ team: folder.team }"
  >
    <router-link
      @mouseleave.native="hideDropdowns()"
      :to="{ name: 'Folder', params: { uuid: folder.uuid } }"
      class="folder d-flex align-items-center btn text-left p-2"
      :class="{ active: dragover == true }"
      @contextmenu.prevent.native="showDropdown()"
    >
      <section class="w-100">
        <div v-if="editMode" class="d-flex align-items-center">
          <b-input
            v-model="name"
            @keydown.stop.prevent.esc="editMode = false"
            @blur="editMode = false"
            ref="folderinput"
            class="my-2"
            autofocus
            @keydown.enter.stop.prevent="updateName()"
            :placeholder="folder.name"
          ></b-input>
          <b-button variant="link text-decoration-none" class="px-0" @click="updateName()"><b-icon class="ml-3" icon="arrow-return-left"></b-icon></b-button>
        </div>
        <section v-else>
          <div class="d-flex align-items-center">
            <b-dropdown lazy v-if="isOwner" ref="colorDropdown" class="mr-2 d-flex align-items-center color-dropdown" id="colors" no-caret variant="link" toggle-class="p-0">
              <template #button-content>
                <span class="color d-inline-block" :style="'background-color: ' + folder.color"></span>
              </template>
              <div class="colorpicker">
                <div class="p-3">
                  <v-swatches :swatches="swatches" v-model="color" inline></v-swatches>
                </div>
                <hr class="m-0 border-border border-top" />
                <div class="row align-items-center p-3">
                  <div class="col pr-0">
                    <span class="customcolor" :style="'background-color: ' + color"></span>
                    Custom
                  </div>
                  <div class="col pl-0">
                    <b-input style="background-color: colorValidated" class="bg-background border-0" @click.stop.prevent="" v-model="color"></b-input>
                  </div>
                </div>
              </div>
            </b-dropdown>
            <span v-else class="color d-inline-block mr-2" :style="'background-color: ' + folder.color"></span>
            <span class="name">{{ folder.name }}</span>
            <b-badge pill class="mx-2" variant="background">{{ count }}</b-badge>

            <b-dropdown
              lazy
              v-if="isOwner"
              toggle-tag="span"
              ref="editDropdown"
              variant="hover"
              id="folder-dropdown"
              class="folder-dropdown ml-auto"
              toggle-class="text-decoration-none border-0 rounded p-0"
              no-caret
            >
              <template #button-content>
                <span class="d-block px-1 p-0">
                  <b-icon icon="three-dots"></b-icon>
                </span>
              </template>
              <b-dropdown-item @click="editFolder()"><span>Edit</span></b-dropdown-item>
              <b-dropdown-item @click="$root.$emit('showFolderModal', folder)"><span>Share</span></b-dropdown-item>
              <b-dropdown-divider></b-dropdown-divider>
              <b-dropdown-item @click="remove()"><span class="text-danger">Delete</span></b-dropdown-item>
            </b-dropdown>

            <b-dropdown
              lazy
              v-else
              toggle-tag="span"
              ref="editDropdown"
              variant="hover"
              id="editDropdown"
              class="folder-dropdown ml-auto"
              toggle-class="text-decoration-none border-0 rounded p-0"
              no-caret
            >
              <template #button-content>
                <span class="d-block px-1 p-0">
                  <b-icon icon="three-dots"></b-icon>
                </span>
              </template>
              <b-dropdown-item @click="leave(folder.uuid)"
                ><span class="text-danger">Leave{{ label }} folder</span></b-dropdown-item
              >
            </b-dropdown>
            <span class="ml-2" v-if="folder.users && folder.users.length > 1 && isOwner" v-b-tooltip.hover :title="'Shared with ' + usersString">
              <b-icon icon="share" class="text-muted" fontScale=".8"></b-icon>
            </span>
            <span class="ml-2" v-else-if="!isOwner && owner" v-b-tooltip.hover :title="'Shared by ' + owner.name || owner.email">
              <b-icon icon="share" class="text-muted" fontScale=".8"></b-icon>
            </span>
          </div>
        </section>
      </section>
    </router-link>
  </div>
</template>

<script>
import { post } from "@/common/api"
import VSwatches from "vue-swatches"
import "vue-swatches/dist/vue-swatches.css"

export default {
  data() {
    return {
      swatches: [
        "#1ABC9C",
        "#16A085",
        "#2ECC71",
        "#27AE60",
        "#34DDFF",
        "#3498DB",
        "#2980B9",
        "#9B59B6",
        "#8E44AD",
        "#666666",
        "#333333",
        "#04495E",
        "#2C3E50",
        "#F1C40F",
        "#F39C12",
        "#E67E22",
        "#D35400",
        "#E74C3C",
        "#C0392B",
        "#7F8C8D",
      ],
      color: null,
      colorValidated: null,
      editMode: false,
      name: this.folder.name ?? null,
      dragover: false,
    }
  },
  watch: {
    color(newValue, oldValue) {
      if (newValue.indexOf("#") !== 0) {
        this.color = "#" + newValue
      }
      if (/^#[0-9A-F]{6}$/i.test(newValue)) {
        this.colorValidated = newValue
        if (oldValue) {
          // Check old value or it sends an update request when initializing
          this.updateColor()
        }
        // this.$refs.colorDropdown.hide()
      }
    },
  },
  created() {
    this.color = this.folder.color
    this.colorValidated = this.folder.color
  },
  props: {
    folder: Object,
  },
  components: {
    VSwatches,
  },
  computed: {
    count() {
      if (this.$store.state.responses) {
        return this.$store.state.responses.filter(e => {
          return e.folder && e.folder.uuid == this.folder.uuid
        }).length
      }
      return 0
    },
    usersString() {
      let users = this.folder.users.filter(e => {
        return e.uuid !== this.$store.state.user.uuid
      })
      let ret = null
      if (users) {
        let names = []
        users.map(e => names.push(e.name))
        let firstNames = names.splice(0, 3)
        ret = firstNames.join(", ")
        if (names.length) {
          ret += " and " + names.length + " other(s)"
        }
      }
      return ret
    },
    owner() {
      if (this.folder.users) {
        return this.folder.users.find(e => {
          return e.pivot.role == "owner"
        })
      }
      return null
    },
    isOwner() {
      if (this.folder.users) {
        const currentUser = this.folder.users.find(e => {
          return e.uuid == this.$store.state.user.uuid
        })
        return (
          (currentUser && currentUser.pivot.role == "owner") ||
          (this.$store.state.user.parent_subscription && this.$store.state.user.parent_subscription.role == "admin") ||
          (this.folder.team && this.$store.state.user.subscription)
        )
      }
      return false
      // return this.$store.state.folders.owned.some(e => e.uuid == this.folder.uuid)
    },
    label() {
      if (this.isOwner) {
        return ""
      }
      if (this.folder.team) {
        return " team"
      }
      return " shared"
    },
  },

  methods: {
    setDragover(val) {
      if (this.isOwner) {
        this.dragover = val
      }
    },
    drop(e) {
      if (this.isOwner || this.folder.team == 1) {
        this.dragover = false
        let response = Object.assign(
          {},
          this.$store.state.responses.find(elm => {
            return elm.uuid == e.dataTransfer.getData("uuid")
          })
        )
        // if (!response.is_owned) {
        //   this.$root.$emit("error", "You don't have permissions to move this response.")
        //   return
        // }
        let oldResponse = Object.assign({}, response)
        let folder = Object.assign({}, this.folder)
        response.folder = folder
        this.$store.dispatch("response", response)
        post("response/folder/add", {
          response_id: response.uuid,
          folder_id: this.folder.uuid,
        }).catch(e => {
          this.$store.dispatch("response", oldResponse)
          // console.log(e)
          this.$root.$emit("error", e)
        })
      } else {
        this.$root.$emit("error", "This folder belongs to someone else, moving a response to that folder isn't allowed.")
      }
    },
    hideDropdowns() {
      // if (this.$refs.editDropdown) {
      //   this.$refs.editDropdown.hide()
      // }
      // if (this.$refs.colorDropdown) {
      //   this.$refs.colorDropdown.hide()
      // }
    },
    showDropdown() {
      if (this.$refs.editDropdown) {
        this.$refs.editDropdown.show()
      }
      // if (this.$refs.colorDropdown) {
      //   this.$refs.colorDropdown.hide()
      // }
    },
    editFolder() {
      this.name = this.folder.name
      this.editMode = true
    },
    updateColor() {
      let oldFolder = this.folder
      let newFolder = { ...this.folder }
      newFolder.color = this.color
      this.$store.dispatch("folder", newFolder)
      post("folder/update", {
        folder_id: this.folder.uuid,
        color: this.color,
      }).catch(e => {
        this.$store.dispatch("folder", oldFolder)
        this.$root.$emit("error", e)
      })
    },
    updateName() {
      if (this.name && this.name.length) {
        this.editMode = false
        let oldFolder = { ...this.folder }
        let newFolder = { ...this.folder }
        newFolder.name = this.name
        this.$store.dispatch("folder", newFolder)
        // let folders = JSON.parse(JSON.stringify(this.$store.state.folders.owned))
        // let oldName = null
        // folders.map(f => {
        //   if (f.uuid == this.folder.uuid) {
        //     oldName = f.name
        //     f.name = this.name
        //   }
        // })
        // this.$store.dispatch("folders", Object.assign(this.$store.state.folders, { owned: folders }))

        post("folder/update", {
          folder_id: this.folder.uuid,
          name: this.name,
        }).catch(e => {
          // folders.map(f => {
          //   if (f.uuid == this.folder.uuid) {
          //     f.name = oldName
          //   }
          // })
          this.$store.dispatch("folder", oldFolder)
          let error = e
          if (e.response.data.errors) {
            error = e.response.data.errors[Object.keys(e.response.data.errors)[0]][0]
          }
          this.$root.$emit("error", error)
        })
      }
    },
    leave() {
      let oldFolders = JSON.parse(JSON.stringify(this.$store.state.folders))
      let oldResponses = JSON.parse(JSON.stringify(this.$store.state.responses))
      let newFolders = JSON.parse(JSON.stringify(this.$store.state.folders))
      let newResponses = JSON.parse(JSON.stringify(this.$store.state.responses))
      newFolders.shared = newFolders.shared.filter(e => e.uuid !== this.folder.uuid)
      newFolders.team = newFolders.team.filter(e => e.uuid !== this.folder.uuid)
      newResponses = newResponses.filter(e => !e.folder || !this.folder || e.folder.uuid !== this.folder.uuid)
      this.$store.dispatch("folders", newFolders)
      this.$store.dispatch("responses", newResponses)
      post("folder/leave", {
        folder_id: this.folder.uuid,
      }).catch(e => {
        // console.log(e)
        this.$root.$emit("error", e)
        this.$store.dispatch("folders", oldFolders)
        this.$store.dispatch("responses", oldResponses)
      })
    },
    remove() {
      let folders = JSON.parse(JSON.stringify(this.$store.state.folders.owned))
      let folder = folders.find(f => f.uuid == this.folder.uuid)
      if (!folder) {
        folders = JSON.parse(JSON.stringify(this.$store.state.folders.team))
        folder = folders.find(f => f.uuid == this.folder.uuid)
      }
      let index = folders.findIndex(f => f.uuid == this.folder.uuid)

      folders = folders.filter(f => !Object.is(f, folder))
      if (folder.team) {
        this.$store.dispatch("folders", Object.assign(this.$store.state.folders, { team: folders }))
      } else {
        this.$store.dispatch("folders", Object.assign(this.$store.state.folders, { owned: folders }))
      }
      post("folder/delete", {
        folder_id: this.folder.uuid,
      })
        .then(() => {
          if (this.$route.name !== "Responses") {
            this.$router.push({ name: "Responses" })
          }
        })
        .catch(e => {
          folders.splice(index, 0, folder)
          if (folder.team) {
            this.$store.dispatch("folders", Object.assign(this.$store.state.folders, { team: folders }))
          } else {
            this.$store.dispatch("folders", Object.assign(this.$store.state.folders, { owned: folders }))
          }
          this.$root.$emit("error", e)
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.color {
  width: 14px;
  min-width: 14px;
  height: 14px;
  border-radius: 50px;
}
.team .color {
  width: 14px;
  height: 14px;
  border-radius: 3px;
}
// a {
//   cursor: default;
//   margin: 2px 0;
//   &:hover {
//     background: var(--color-primary-light);
//   }
// }

.active * {
  pointer-events: none;
}

// .router-link-exact-active,
// .active {
//   background: var(--color-primary-light);
//   // color: var(--color-primary);
//   .badge {
//     background: var(--white);
//     color: var(--color-primary);
//   }
//   &:hover {
//     // color: var(--color-primary);
//     background: var(--color-primary-light) !important;
//   }
// }
.name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
::v-deep .folder:hover .folder-dropdown {
  visibility: visible;
}
::v-deep #colors .dropdown-menu {
  padding: 0 !important;
}

// ::v-deep .folder:not(.router-link-active):not(:hover) .folder-dropdown {
//   visibility: hidden;
// }

::v-deep .vue-swatches__wrapper {
  justify-content: center !important;
}
::v-deep .colorpicker {
  width: 250px;
}
.customcolor {
  user-select: none;
  position: relative;
  display: inline-block;
  width: 12px !important;
  height: 12px !important;
  margin-right: 0.1rem;
  border-radius: 5px !important;
}
</style>
