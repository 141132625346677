import * as clipboard from "clipboard-polyfill";


export const copy = async (contentHTML, content, plainText) => {
  if (plainText) {
    document.querySelector("#clipboard").innerText = content
    // navigator.clipboard.writeText(content);
  } else {
    document.querySelector("#clipboard").innerHTML = contentHTML
    // navigator.clipboard.write(contentHTML);
  }
  let range = document.createRange()
  range.selectNodeContents(document.querySelector("#clipboard"))
  window.getSelection().removeAllRanges()
  window.getSelection().addRange(range)

  try {
    const item = new clipboard.ClipboardItem({
      "text/html": new Blob(
        [contentHTML],
        { type: "text/html" }
      ),
      "text/plain": new Blob(
        [content],
        { type: "text/plain" }
      ),
    });
    await clipboard.write([item]);
  } catch (e) {
    // Handle error with user feedback - "Copy failed!" kind of thing
    console.log(e);
  }
  // document.execCommand("copy");
}

export const getContents = () => {
  return new Promise((resolve, reject) => {
    navigator.clipboard.readText()
      .then(text => {
        console.log('Read clipboard: ' + text)
        resolve(text);
        return text;
      })
      .catch(err => {
        reject(err);
        console.error('Failed to read clipboard contents: ', err);
      });
  })
}
