import escapeHtml from "escape-html"
import { Text } from "slate"

let size = null,
  color = null,
  underline = null,
  font = null,
  italic = null,
  strikethrough = null,
  bold = null,
  isHTML = false

const serialize = (node, plainText = false, preview = false, root = true) => {
  if (!node) return

  isHTML = false;

  // if (node.type == "paragraph" && node.children.length == 1 && node.children[0] != null && node.children[0].text == "") {
  //   if (plainText && !preview) {
  //     return '\r\n'
  //   }
  //   return '<div><p style="margin: 0; min-height: 20px;">&nbsp;</p></div>'
  // }

  if (node.type !== "variable") {
    size = null
    color = null
    bold = null
    italic = null
    underline = null
    strikethrough = null
  }
  if (node.color) {
    color = node.color.value
  }
  if (node.size) {
    size = node.size
  }
  if (node.bold) {
    bold = true
  }
  if (node.italic) {
    italic = true
  }
  if (node.font) {
    font = node.font
  }
  if (node.strikethrough) {
    strikethrough = node.strikethrough
  }
  if (node.underline) {
    underline = node.underline
  }

  if (Text.isText(node)) {
    if (plainText || node.text == "") {
      return node.text
    }
    let ret = ""

    if (node.text.length) {
      ret = node.text
    }

    if ((plainText || node.code) && preview) {
      isHTML = true
      ret = `${ret.replaceAll("<", "&lt;").replaceAll(">", "&gt;")}`
      return ret
    }
    if (plainText) {
      return ret
    }

    if (color) {
      ret = `<span style="color: ${color}">${ret}</span>`
    }

    if (size) {
      ret = `<span style="font-size: ${size}px">${ret}</span>`
    }

    if (font) {
      ret = `<span style="font-family: ${font}">${ret}</span>`
    }

    if (italic) {
      ret = `<em>${ret}</em>`
    }

    if (underline) {
      ret = `<u>${ret}</u>`
    }

    if (strikethrough) {
      ret = `<strike>${ret}</strike>`
    }

    if (bold) {
      if (preview) {
        ret = `<b style="font-weight: 600">${ret}</b>`
      } else {
        ret = `<b><strong>${ret}</strong></b>`
      }
    }

    return ret
  }

  if (node && node.children) {
    let children = node.children.map(n => serialize(n, plainText, preview, false)).join("")

    let width = "auto"
    if (node.params && node.params.width) {
      width = node.params.width
    }
    let label = node.label
    if ((node.name == "text" || node.name == "select") && node.params && node.params.length) {
      label = node.params[0].value
    }
    switch (node.type) {
      case "quote":
        if (plainText) return children
        return `<blockquote><p>${children}</p></blockquote>`
      case "paragraph":
        if (plainText && !preview) return children + "\n"
        if (isHTML) return '<div style="margin: 0; min-height: 20px;">' + children + "</div>"
        return '<p style="margin: 0; min-height: 20px;">' + children + "&nbsp;</p>"
      case "bulleted-list":
        if (plainText && !preview) return children
        return '<ul style="margin: 0; padding-top: 0; padding-bottom: 0;">' + children + "</ul>"
      case "numbered-list":
        if (plainText && !preview) return children
        return `<ol style="margin: 0; padding-top: 0; padding-bottom: 0;">${children}</ol>`
      case "list-item":
        if (plainText && !preview) return "- " + children + "\n"
        return `<li style="margin: 0; padding-top: 0; padding-bottom: 0;">${children}</li>`
      case "h1":
        if (plainText && !preview) return children
        return `<h1>${children}</h1>`
      case "h2":
        if (plainText && !preview) return children
        return `<h2>${children}</h2>`
      case "h3":
        if (plainText && !preview) return children
        return `<h3>${children}</h3>`
      case "image":
        if (plainText && !preview) return node.params.url
        if (node.params.link && !preview) {
          return `<a href="${node.params.link}"><img width="${width}" src="${escapeHtml(node.params.url)}"/></a>`
        } else {
          return `<img width="${width}" src="${escapeHtml(node.params.url)}"/>`
        }
      case "span":
        if (plainText && !preview) return children
        return "<span>" + children + "</span>"
      case "link":
        // node.children = node.children.filter(e => e.type !== 'link')
        if (node.children.some(e => e.type == 'link')) {
          const correctNode = (node.children.find(e => {
            return e.type == 'link' && e.children.length > 0 && typeof e.children[0].text != undefined && e.children[0].text.length
          }))
          if (typeof correctNode != 'undefined') {
            return serialize(correctNode, plainText, preview);
          }
          // serialize(n, plainText, preview, false)).join("")
        }
        if (!children.length) return
        if (plainText && !preview) return children + " (" + node.to + ")"
        if (preview) return `<a href="#">${children}</a>`
        return `<a href="${escapeHtml(node.to)}">${children}</a>`

      // if (children.length) {
      //   children = children.filter(e => e.type !== 'link')
      // }
      case "variable":
        if (preview) {
          // <svg data-v-035f8a36="" viewBox="0 0 16 16" width="1em" height="1em" focusable="false" role="img" aria-label="file break" xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi-file-break mr-1 b-icon bi"><g data-v-035f8a36=""><path d="M0 10.5a.5.5 0 0 1 .5-.5h15a.5.5 0 0 1 0 1H.5a.5.5 0 0 1-.5-.5zM12 0H4a2 2 0 0 0-2 2v7h1V2a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v7h1V2a2 2 0 0 0-2-2zm2 12h-1v2a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1v-2H2v2a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2v-2z"></path></g></svg>
          return `<span class="editor-variable">
          <span>${label}</span>
          </span>`
        }
        return children
      case "blockvariable":
        if (preview) {
          return `<span class="blockvariable">
          <span>${label}</span>
          </span>`
        }
        return children
      default:
        if ((size || color || bold) && !root) {
          let ret = plainText && !preview ? children : `<div style="font-size: ${size}px; color: ${color ?? ""}; font-weight: ${bold ? "bold" : "normal"}">${children}</div>`
          return ret
        }
        return children
    }
  }
}

export default serialize
