<template>
  <div contenteditable="false" class="editable-image" @mouseenter="showControls = true" @mouseleave="showControls = false">
    <img :width="width" :src="url" alt="" />
    <div v-show="showControls" class="controls">
      <a href="#" @click.stop.prevent="setWidth(100)">Small</a>
      <a href="#" @click.stop.prevent="setWidth(250)">Medium</a>
      <a href="#" @click.stop.prevent="setWidth(500)">Large</a>
      <a href="#" @click.stop.prevent="setWidth(null)">Original</a>
      <a class="delete" href="#" @click.stop.prevent="removeImage()">Delete</a>
    </div>
    <slot></slot>
  </div>
</template>

<script>
import { Transforms } from "slate"

export default {
  data() {
    return {
      showControls: true,
      widthValue: null,
    }
  },
  props: {
    url: String,
    size: [String, Number],
    link: String,
  },
  methods: {
    removeImage() {
      Transforms.removeNodes(this.$editor, {
        at: [],
        match: node => node.type == "image" && node.params.url == this.url,
      })
    },
    setWidth(value) {
      this.widthValue = value
      Transforms.setNodes(
        this.$editor,
        {
          params: {
            url: this.url,
            link: this.link,
            width: value,
          },
        },
        {
          at: [],
          match: node => node.type == "image" && node.params.url == this.url,
        }
      )
    },
  },
  created() {
    if (this.size) {
      this.widthValue = this.size
    }
  },
  computed: {
    width() {
      if (this.widthValue) return this.widthValue
      return "auto"
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@/common/assets/css/_variables.scss";
// @import "@/common/assets/css/mixins.scss";
.editable-image {
  position: relative;
  display: block;
  margin-bottom: $spacer;
  &:hover:after {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(white, 0.5);
    z-index: 1;
  }
  .controls {
    position: absolute;
    left: 20px;
    top: 20px;
    background: var(--color-background);
    border: 1px solid var(--color-border);
    color: white;
    border-radius: 3px;
    overflow: hidden;
    height: 35px;
    display: flex;
    z-index: 2;
    align-items: center;
    > a {
      display: flex;
      cursor: pointer;
      color: var(--color-default);
      align-items: center;
      justify-content: center;
      padding: 0 10px;
      height: 100%;
      &:hover {
        text-decoration: none;
        background: var(--color-background-dark);
      }
      &.delete {
        color: white;
        background: var(--color-danger);
      }
    }
  }
}
</style>