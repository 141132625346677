// import store from "@/common/store/index"

const parse = async (node, questions, variant) => {


  return new Promise(resolve => {
    if (!node || node.text) {
      resolve()
      return
    }
    setTimeout(async () => {
      if (node.name == "select" && !questions.some(e => e.uuid == node.uuid)) {
        questions.push({
          name: node.name,
          uuid: node.uuid,
          multi: node.params[1].multi,
          title: node.params[0].value,
          options: node.params[1].options,
        })
        resolve()
      }
      if (node.name == "text" && !questions.some(e => e.uuid == node.uuid)) {
        // console.log("HERE " + node);

        let lastValue = null,
          defaultValue = null
        if (node.params[0].remember) {
          lastValue = typeof window.window.store.state.variable_values[node.uuid] == "undefined" ? null : window.window.store.state.variable_values[node.uuid]
        }
        if (node.params[0].default) {
          defaultValue = node.params[0].default
        }
        questions.push({
          name: node.name,
          uuid: node.uuid,
          title: node.params[0].value,
          remember: node.params[0].remember ?? false,
          lastValue,
          defaultValue,
        })
        resolve()
      }
      if (node.name == "date") {
        let date = new Date()
        // Offset
        date.setDate(date.getDate() + parseInt(node.params[1].value))
        // Format
        if (node.params[0].value == "day") {
          node.text = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"][date.getDay()]
        }
        if (node.params[0].value == "day_number") {
          node.text = date
            .getDate()
            .toString()
            .padStart(2, "0")
        }
        if (node.params[0].value == "month_number") {
          node.text = (date.getMonth() + 1).toString().padStart(2, "0")
        }
        if (node.params[0].value == "year") {
          node.text = date.getFullYear().toString()
        }
        if (node.params[0].value == "year_short") {
          node.text = date
            .getFullYear()
            .toString()
            .substr(2, 2)
        }
        if (node.params[0].value == "date") {
          node.text = date.formatDate()
        }
        if (node.params[0].value == "month") {
          node.text = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"][date.getMonth()]
        }
        if (node.params[0].value == "time12") {
          let hours = date.getHours()
          let minutes = date.getMinutes()
          let ampm = hours >= 12 ? "pm" : "am"
          hours = hours % 12
          hours = hours ? hours : 12 // the hour '0' should be '12'
          minutes = minutes < 10 ? "0" + minutes : minutes
          let strTime = hours + ":" + minutes + " " + ampm
          node.text = strTime
        }
        if (node.params[0].value == "time24") {
          let hours = date.getHours()
          let minutes = date.getMinutes()
          let strTime = hours + ":" + minutes
          node.text = strTime
        }
        resolve(node)
      }
      if (node.name == "response" && node.children.length == 1) {
        // let promises = [];
        let response = window.window.store.state.responses.find(e => e.uuid == node.params[0].value)
        if (response && response.variants.length) {
          // Find the matching variant
          if (response.variants.length == 1) {
            node.name = "";
            node.children = JSON.parse(response.variants[0].content)
          } else {
            const matchingVariant = response.variants.find(e => e.name == variant.name)
            if (matchingVariant) {
              node.name = "";
              node.children = JSON.parse(matchingVariant.content)
              resolve()
            } else {
              questions.push({
                title: "Select a variant",
                name: "select",
                id: "variant",
                response,
                options: response.variants.map(e => e.name),
              })
              resolve()
            }
          }
        } else {
          resolve()
        }
      }
      if (node.name == "clipboard") {
        const clipboard = require("@/clipboard")
        clipboard.getContents().then(content => {
          node.text = content
          resolve()
        })
      }
      if (node.name == "firstname") {
        node.text = window.window.store.state.user.first_name || ""
        node.children = null
        node.type = "text"
        // node.text = window.store.state.user.first_name || '';
        // node.type = "text"
      }
      if (node.name == "random") {
        let options = node.params[0].options
        let option = options[Math.floor(Math.random() * options.length)]
        node.text = option
        node.children = null
        node.type = "text"
        // node.text = window.store.state.user.first_name || '';
        // node.type = "text"
      }
      if (node.name == "lastname") {
        node.text = window.store.state.user.last_name || ""
      }
      if (node.name == "fullname") {
        node.text = window.store.state.user.name || ""
      }
      if (node.name == "role") {
        node.text = window.store.state.user.role || ""
      }
      if (node.name == "business") {
        node.text = window.store.state.user.business || ""
      }
      if (node.name == "email") {
        node.text = window.store.state.user.email || ""
      }
      if (node.name == "time") {
        let date = new Date()
        // AM/PM
        var hours = date.getHours()
        var minutes = date.getMinutes()
        var ampm = hours >= 12 ? "pm" : "am"
        hours = hours % 12
        hours = hours ? hours : 12 // the hour '0' should be '12'
        minutes = minutes < 10 ? "0" + minutes : minutes
        var strTime = hours + ":" + minutes + " " + ampm
        node.text = strTime
        resolve()
      }
      if (node.name == "genderconditional" && !questions.some(e => e.name == "genre")) {
        questions.unshift({
          name: "select",
          uuid: node.uuid,
          id: "gender",
          title: "Select a gender for the recipient",
          options: ["Male", "Female", "Neutral"],
        })
        resolve()
      }
      if (node.children) {

        for (const n of node.children) {
          await parse(n, questions, variant)
        }
        resolve()
      } else {
        resolve()
      }
    }, 0)
  })
}

const getVariables = (node, variables) => {
  if (!node) {
    return
  }

  if (node.name == "select" && !variables.some(e => e.uuid == node.uuid)) {
    variables.push(node)
  }
  if (node.name == "text" && !variables.some(e => e.uuid == node.uuid)) {
    variables.push(node)
  }

  if (node.children) {
    for (const n of node.children) {
      getVariables(n, variables)
    }
  }
}

export default { parse, getVariables }
