<template>
  <div class="bg-body-bg previewcontainer">
    <div class="d-flex flex-column justify-content-between vh-100 position-relative">
      <div class="actions d-flex align-items-center justify-content-between p-3">
        <div>
          <b-button @click="$emit('hidePreview')" class="d-block d-lg-none mr-auto" variant="hover">
            <b-icon icon="arrow-left"></b-icon>
          </b-button>
        </div>
        <div class="d-flex align-items-center justify-content-end">
          <b-button @click="$router.push({ name: 'CreateResponse', params: { uuid: response.uuid } })" v-if="response.can_edit" class="mr-1 d-md-flex align-items-center d-none" variant="hover">
            <b-icon icon="pencil"></b-icon>
          </b-button>
          <BookmarkButton class="mr-3" :is_bookmarked="response.is_bookmarked" :uuid="response.uuid" />
          <b-button class="px-4" variant="primary" @click="$root.$emit('selectResponse', response.uuid)">
            Copy
            <b-icon icon="arrow-return-left"></b-icon>
          </b-button>
        </div>
      </div>
      <div class="scroll-container">
        <div class="scroll-content h-100">
          <div class="preview pt-lg-4 pt-md-0">
            <div class="p-3 mb-5 bg-primary-background overflow-hidden d-flex align-items-start justify-content-start" v-if="response.private_note">
              <span class="badge badge-default mr-3">PRIVATE NOTE</span>
              {{ response.private_note }}
            </div>
            <div class="p-5 mx-lg-5 mx-2 rounded bg-background">
              <div class="flags flex-wrap d-flex justify-content-end" v-if="variants.length > 1">
                <VariantAvatar
                  :class="{ active: currentIndex == index }"
                  :variant="variant"
                  class="variantavatar mx-1 cursor-pointer"
                  :key="variant.uuid"
                  v-for="(variant, index) in variants"
                  @click.native="currentIndex = index"
                />
              </div>
              <h2 class="text-center mt-3 mb-4">{{ response.name }}</h2>
              <div class="editor-content">
                <div class="text-break" v-html="content"></div>
              </div>
            </div>
          </div>
          <div class="mt-auto logos p-5" v-if="!$store.state.user.subscription || ($store.state.user.subscription && $store.state.user.subscription.is_trial)">
            <img width="150" class="d-block mx-auto mb-5 visible-light" src="@/common/assets/img/logo.svg" alt="" />
            <img width="150" class="d-block mx-auto mb-5 visible-dark" src="@/common/assets/img/logo_dark.svg" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import serialize from "@/common/components/Editor/serialize.js"
import BookmarkButton from "@/common/components/BookmarkButton"
import VariantAvatar from "@/common/components/VariantAvatar"

export default {
  data() {
    return {
      variants: [],
      currentIndex: 0,
      plain_text: false,
      value: null,
      content: null,
    }
  },
  computed: {
    key() {
      return new Date().getTime()
    },
    borderColor() {
      return this.response.folder ? this.response.folder.color : "transparent"
    },
  },
  props: {
    response: Object,
  },
  components: {
    // Folder,
    BookmarkButton,
    VariantAvatar,
    // Avatar,
  },
  methods: {
    updatePreview() {
      let content = ""
      if (this.variants.length) {
        content = serialize({ children: JSON.parse(this.variants[this.currentIndex].content) }, this.response.plain_text, true)
      } else {
        content = "<div></div>"
      }
      // if (content.indexOf("<meta") >= 0 || content.indexOf("<script") >= 0) {
      //   // this.plain_text = true
      //   content = serialize({ children: JSON.parse(this.variants[this.currentIndex].content) }, true, true)
      //   // content = content.replaceAll("<", "&lt;").replaceAll(">", "&gt;")
      // }
      this.content = content
    },
  },
  watch: {
    currentIndex() {
      this.updatePreview()
    },
    response: {
      immediate: true,
      handler(newValue) {
        this.variants = newValue.variants
        this.currentIndex = 0
        this.plain_text = newValue.plain_text
        this.updatePreview()
      },
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@/common/assets/css/_variables.scss";
@import "@/common/assets/css/mixins";

.scroll-container {
  padding-top: 4.5rem;
}

@include scroll;

@include media-breakpoint-down(md) {
  .previewcontainer {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    transform: translateX(100vw);
    &.visible {
      transform: translateX(0);
    }
  }
}

.actions {
  position: absolute;
  width: 100%;
  background: rgba(var(--body-bg), 0.95);
  z-index: 4;
}
::v-deep .actions .btn {
  height: 40px;
}

.logos {
  // filter: saturate(0%);
  opacity: 0.5;
}

::v-deep .variantavatar {
  opacity: 0.4;
  transition: all 0.2s ease;
  transform: scale(1.3);
  border: 3px solid transparent;
}
::v-deep .variantavatar.active {
  border: 3px solid var(--color-primary);
  border-radius: 100px;
}
::v-deep .variantavatar.active,
::v-deep .variantavatar:hover {
  opacity: 1;
}
::v-deep .editor-content {
  height: 100%;
  width: 100%;
  overflow: hidden;

  p,
  ul,
  li,
  div {
    line-height: 25px;
    min-height: 25px;
    margin: 0;
  }
  p {
    line-height: 1.5;
    margin: 0;
  }
  img {
    max-width: 100%;
  }
}
.copybtn {
  font-size: 90%;
  font-weight: 500;
}
</style>
