<template>
  <div
    draggable="true"
    @dragend="stopDrag()"
    @dragstart="startDrag($event)"
    @dblclick="$root.$emit('selectResponse', uuid)"
    @contextmenu="$refs.dropdown.show()"
    @click="$emit('select')"
    :class="{ active: selected, dragging: drag }"
    class="response p-3"
  >
    <div class="d-flex flex-column flex-md-row align-items-center">
      <div class="overflow-hidden w-100">
        <span class="d-flex flex-nowrap align-items-center justify-content-start w-100 truncate">
          <!-- <span v-if="archived" class="bg-warning-light py-1 px-2 rounded mr-2">Archived</span> -->
          <div
            v-if="!archived && shortcut"
            class="shortcut text-yellow-dark bg-yellow-light pl-1 pr-2 rounded text-left d-flex align-items-center mr-2"
            v-b-tooltip.hover
            :title="shortcutTooltipTitle"
          >
            <b-icon icon="lightning-fill" scale=".8"></b-icon>
            {{ shortcut }}
          </div>
          <span class="truncate">
            {{ name }}
          </span>
        </span>
        <span class="d-block line text-muted truncate pr-1">
          {{ preview }}
        </span>
        <div v-if="folder && !archived" class="d-flex line align-items-center">
          <Folder size="small" v-if="folder" :uuid="folder.uuid" />
          <div class="d-flex align-items-center ml-auto" v-if="variants.length > 1 || variants.some(e => e.flag)">
            <div class="ml-2" :key="variant.uuid" v-for="variant in variants">
              <!-- <VariantAvatar v-b-tooltip="{ left: 0, top: 0, boundary: 'viewport' }" :title="variant.name" :variant="variant" /> -->
            </div>
          </div>
        </div>
      </div>
      <div class="ml-auto actions p-2 rounded bg-body-bg" v-if="selected">
        <div class="ml-auto d-flex align-items-center menu">
          <BookmarkButton v-if="!archived" :is_bookmarked="is_bookmarked" :uuid="uuid" />
          <b-dropdown
            lazy
            v-if="archived"
            right
            :class="{ 'ml-1': !archived }"
            class="d-none d-sm-block"
            ref="dropdown"
            variant="hover"
            id="user-dropdown"
            toggle-class="text-decoration-none px-1 py-0"
            no-caret
            @dblclick.native.stop.prevent
          >
            <template @dblclick.stop.prevent #button-content>
              <b-icon icon="three-dots"></b-icon>
            </template>
            <b-dropdown-item @click="restore()">Restore</b-dropdown-item>
          </b-dropdown>
          <b-dropdown
            @dblclick.native.stop.prevent
            lazy
            v-else-if="can_edit"
            right
            class="ml-1 d-none d-sm-block"
            ref="dropdown"
            variant="hover"
            id="user-dropdown"
            toggle-class="text-decoration-none px-1 py-0"
            no-caret
          >
            <template #button-content>
              <b-icon icon="three-dots"></b-icon>
            </template>
            <b-dropdown-item @click="$router.push({ name: 'CreateResponse', params: { uuid } })"><span>Edit response</span></b-dropdown-item>
            <b-dropdown-item @click="duplicate()"><span>Duplicate response</span></b-dropdown-item>
            <b-dropdown-divider />
            <b-dropdown-item v-if="is_owned" @click="remove()"><span class="text-danger">Delete response</span></b-dropdown-item>
          </b-dropdown>
          <b-button @click="$emit('showPreview')" variant="hover" class="d-block d-lg-none">
            <b-icon icon="box-arrow-in-up-right"></b-icon>
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { post } from "@/common/api"
import Folder from "@/common/components/Folder"
import BookmarkButton from "@/common/components/BookmarkButton"
// import { v4 as uuidv4 } from "uuid"

// import VariantAvatar from "@/common/components/VariantAvatar"
export default {
  props: {
    uuid: String,
    name: String,
    archived: Boolean,
    preview: String,
    flags: Array,
    folder: Object,
    variants: Array,
    owner: Object,
    is_owned: Boolean,
    can_edit: Boolean,
    is_bookmarked: Boolean,
    shortcut: String,
    selected: Boolean,
  },
  data() {
    return {
      drag: false,
    }
  },
  components: {
    // VariantAvatar,
    Folder,
    BookmarkButton,
  },
  computed: {
    shortcutTooltipTitle() {
      let str = "Type :" + this.shortcut + " anywhere to insert your canned response"
      if (!this.$root.isElectron()) {
        str += " (desktop app required)"
      }
      return str
    },
  },
  methods: {
    startDrag(e) {
      this.drag = true
      e.dataTransfer.dropEffect = "move"
      e.dataTransfer.effectAllowed = "move"
      e.dataTransfer.setData("uuid", this.uuid)
    },
    stopDrag() {
      this.drag = false
    },
    duplicate() {
      let response = this.$store.state.responses.find(response => {
        return response.uuid == this.uuid
      })
      post("response/copy", {
        response_id: response.uuid,
      }).then(ret => {
        let response = ret.data
        this.$store.dispatch("response", response)
      })
    },
    restore() {
      let response = this.$store.state.archives.find(response => {
        return response.uuid == this.uuid
      })
      response.archived = false
      let responses = [...this.$store.state.responses]
      responses.unshift(response)
      let archives = this.$store.state.archives.filter(response => {
        return response.uuid !== this.uuid
      })
      this.$store.dispatch("responses", responses)
      this.$store.dispatch("archives", archives)
      post("response/restore", {
        response_id: this.uuid,
      }).catch(e => {
        this.$root.$emit("error", e)
        console.log(e)
        let responses = this.$store.state.responses.filter(response => {
          return response.uuid !== this.uuid
        })
        this.$store.dispatch("responses", responses)
        let archives = [...this.$store.state.archives]
        response.archived = true
        archives.unshift(response)
        this.$store.dispatch("archives", archives)
      })
    },
    remove() {
      let response = this.$store.state.responses.find(response => {
        return response.uuid == this.uuid
      })
      response.archived = true
      let responses = this.$store.state.responses.filter(response => {
        return response.uuid !== this.uuid
      })
      let archives = [...this.$store.state.archives]
      archives.unshift(response)
      this.$store.dispatch("responses", responses)
      this.$store.dispatch("archives", archives)

      let user = this.$store.state.user
      user.responses_count--
      this.$store.dispatch("user", user)
      post("response/delete", {
        response_id: this.uuid,
      }).catch(e => {
        let response = this.$store.state.archives.find(response => {
          return response.uuid == this.uuid
        })
        response.archived = false
        let responses = [...this.$store.state.responses]
        responses.unshift(response)
        let archives = this.$store.state.archives.filter(response => {
          return response.uuid !== this.uuid
        })
        this.$store.dispatch("responses", responses)
        this.$store.dispatch("archives", archives)

        let user = this.$store.state.user
        user.responses_count++
        this.$store.dispatch("user", user)

        this.$root.$emit("error", e)
        console.log(e)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@/common/assets/css/_variables.scss";
@import "@/common/assets/css/mixins";

.response.active {
  // border: 1px solid var(--color-primary);
  background: var(--color-primary-background);
  &:hover {
    background: var(--color-primary-background) !important;
  }
}

// .actions {
//   background: var(--body-bg);
//   @extend .p-4;
// }
::v-deep .actions svg {
  @include media-breakpoint-down(md) {
    width: 18px !important;
    height: 18px !important;
  }
}

.response.dragging {
  cursor: move;
}
// .folder-badge {
//   width: 10px;
//   height: 10px;
// }
.response {
  //transition: background 0.2s ease;
  .menu {
    // visibility: hidden;
    opacity: 0.9;
    transition: opacity 0.2s ease;
  }
  // &:hover {
  //   .menu {
  //     // visibility: visible;
  //     opacity: 1;
  //   }
  // }
}
.response {
  // background: var(--white);
  // border: 1px solid transparent;
  // border-bottom-color: var(--color-border);
  border-radius: 8px;
  cursor: default;
  user-select: none;
  transition: background 0.2s ease;

  &.scrolling {
    pointer-events: none;
  }
  &:not(.scrolling):hover {
    background: var(--color-background);
  }
}
::v-deep .btn {
  width: 32px;
  height: 32px;
  @include media-breakpoint-up(md) {
    width: 24px;
    height: 24px;
  }
  // aspect-ratio: 1/1;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
