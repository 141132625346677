<template>
  <div>
    <!-- <b-dropdown id="colors" no-caret variant="background">
      <template #button-content>
        <b-icon icon="image"></b-icon>
      </template>
    </b-dropdown> -->
    <b-button class="formatbarbtn" v-b-modal.insertImage variant="hover">
      <img v-if="loading" class="loader" src="@/common/assets/img/loader_dark.svg" width="16" alt="" />
      <b-icon v-else icon="image"></b-icon>
    </b-button>
    <input class="d-none" @change="upload($event)" type="file" ref="input" />
    <b-modal size="lg" id="insertImage" hide-footer header-border-variant="0" ref="modal">
      <template #modal-header-close>
        <b-icon icon="x"></b-icon>
      </template>
      <div class="px-5">
        <p class="mb-4 text-muted ml-2">
          <b-icon icon="image" class="mr-3"></b-icon>
          Insert image
        </p>
        <b-tabs v-model="tab" pills>
          <b-tab active title="Stickers" class="pt-4">
            <b-card-text>
              <div class="row">
                <div class="col">
                  <div class="d-flex align-items-center">
                    <b-form-input v-model="search" debounce="400" placeholder="Search Stickers" type="text"></b-form-input>
                    <img width="120" class="ml-4" src="@/common/assets/img/giphy.png" alt="" />
                  </div>
                </div>
              </div>
              <div class="row my-4 px-3">
                <div class="col-3 px-0" v-for="(img, index) in results" :key="img.full + index">
                  <div :class="{ selected: imgUrl == img.full }" class="object-cover">
                    <img @click="selectImage(img.full)" width="100%" height="100" :src="img.preview" alt="" />
                  </div>
                </div>
              </div>
            </b-card-text>
          </b-tab>
          <b-tab title="Gif" class="pt-4">
            <b-card-text>
              <div class="row">
                <div class="col">
                  <div class="d-flex align-items-center">
                    <b-form-input v-model="search" debounce="400" placeholder="Search GIFs" type="text"></b-form-input>
                    <img width="120" class="ml-4" src="@/common/assets/img/giphy.png" alt="" />
                  </div>
                </div>
              </div>
              <div class="row my-4 px-3">
                <div class="col-3 px-0" v-for="(img, index) in results" :key="img.full + index">
                  <div :class="{ selected: imgUrl == img.full }" class="object-cover">
                    <img @click="selectImage(img.full)" width="100%" height="100" :src="img.preview" alt="" />
                  </div>
                </div>
              </div>
            </b-card-text>
          </b-tab>
          <b-tab title="Import from URL" class="pt-4">
            <div class="row">
              <div class="col">
                <div class="bg-warning-light rounded p-4 mb-4">The URL must end with .jpg, .png, .jpeg or .gif</div>
                <b-form-input v-model="imgUrl" placeholder="Insert image URL" type="text" class="mb-4"></b-form-input>
                <!-- <b-button @click="selectImage(url)" variant="primary" size="lg" block class="mb-4">Import</b-button> -->
              </div>
            </div>
          </b-tab>
          <b-tab title="Upload" class="pt-4">
            <b-card-text>
              <div v-if="imgUploadUrl" class="upload upload--done rounded text-center d-flex flex-column">
                <img class="w-auto h-100" :src="imgUploadUrl" alt="" />
                <b-button @click="imgUploadUrl = null" variant="background text-danger">Delete</b-button>
              </div>
              <div v-else class="upload p-5 text-center d-flex flex-column" @click="selectFile">
                Click here to upload a file from your computer
                <p class="small m-0 text-default-lighter">Max. 2500px x 2500px (2mb)</p>
              </div>
            </b-card-text>
          </b-tab>
        </b-tabs>
        <div class="mb-4">
          <!-- <b-button v-b-toggle="'link'" variant="background my-2">Add a link</b-button> -->
          <!-- <b-collapse id="link"> -->

          <div class="d-flex my-3 align-items-center">
            <span class="text-nowrap mr-3"> Add a link (optional): </span>
            <b-form-input v-model="link" placeholder="Add a link to your image (http://)" type="text"></b-form-input>
          </div>
          <b-button @click="insertImage()" variant="primary" size="lg" block class="mb-4">Insert image</b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { Transforms } from "slate"
import { post } from "@/common/api"
import Axios from "axios"
export default {
  data() {
    return {
      tab: 0,
      url: null,
      color: null,
      selection: null,
      loading: false,
      results: [],
      link: null,
      imgUrl: null,
      imgUploadUrl: null,
      resultsStickers: [],
      search: null,
    }
  },
  beforeDestroy() {
    this.$root.$off("bv::modal::show", this.displayModal)
  },
  created() {
    this.$root.$on("bv::modal::show", this.displayModal)
  },
  methods: {
    displayModal(bvEvent, modalId) {
      if (modalId == "insertImage") {
        this.imgUrl = null
        this.imgUploadUrl = null

        if (!this.results.length) {
          let q = ["yes", "happy"][this.tab]
          this.searchGif(q)
        }
      }
    },
    searchGif(q) {
      if (!q) {
        q = this.search
      }

      let section = "stickers"
      if (this.tab == 1) {
        section = "gifs"
      }
      Axios.get("https://api.giphy.com/v1/" + section + "/search?api_key=" + process.env.VUE_APP_GIPHY_KEY + "&q=" + q + "&limit=12&offset=0&rating=g&lang=en").then(res => {
        this.results = []
        res.data.data.forEach(img => {
          this.results.push({
            full: img.images.downsized_large.url,
            preview: img.images.preview_gif.url,
          })
        })
      })
    },
    selectFile() {
      this.$refs.input.click()
    },
    selectImage(url) {
      this.imgUrl = url
    },
    insertImage() {
      let node = [
        {
          type: "paragraph",
          children: [
            {
              text: "",
            },
          ],
        },
        {
          type: "image",
          params: {
            url: this.imgUrl,
            link: this.link,
          },
          children: [
            {
              text: "",
            },
          ],
        },
        {
          type: "paragraph",
          children: [
            {
              text: "",
            },
          ],
        },
      ]

      if (window.currentSelection) {
        Transforms.insertNodes(this.$editor, node, {
          at: window.currentSelection,
        })
        Transforms.removeNodes(this.$editor, {
          at: window.currentSelection,
        })
      } else {
        Transforms.insertNodes(this.$editor, node)
      }
      this.$refs.modal.hide()
    },
    upload(event) {
      this.loading = true
      let data = new FormData()
      data.append("file", event.target.files[0])
      post("response/upload", data)
        .then(ret => {
          this.loading = false
          let url = ret.data.url
          this.$refs.input.value = null
          // this.insertImage(url)
          //this.selectImage(url)
          this.imgUrl = url
          this.imgUploadUrl = url
        })
        .catch(e => {
          this.loading = false
          if (e.response && e.response.data && e.response.data.message) {
            if (e.response.data.errors) {
              this.$root.$emit("error", Object.values(e.response.data.errors).join(" "))
            } else {
              this.$root.$emit("error", e.response.data.message)
            }
          } else {
            this.$root.$emit("error", "We haven't been able to upload your image: " + e)
          }
          this.$refs.input.value = null
        })
    },
  },
  watch: {
    search() {
      this.searchGif()
    },
    tab() {
      let q = this.search
      if (!this.search) {
        q = ["yes", "happy"][this.tab]
      }
      this.imgUrl = null
      this.imgUploadUrl = null
      this.searchGif(q)
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@/common/assets/css/_variables.scss";

.object-cover {
  background: white;
  z-index: 50;
  &:hover,
  &.selected {
    z-index: 100;
    img {
      outline: 3px solid #000000;
      transform: scale(1.1);
      position: absolute;
      opacity: 1;
      background: white;
      z-index: 100;
    }
  }
  &:hover,
  &:hover img {
    z-index: 150;
  }
  img {
    object-fit: cover;
    // border: 3px solid white;
    opacity: 0.7;
    // scale: 1;
    // transition: transform 0.1s ease-in-out;
    z-index: 50;
  }
  // img.selected {
  //   outline: 3px solid #000000;
  //   transform: scale(1.1);
  //   &:hover {
  //     z-index: 100;
  //     outline: 3px solid #000000;
  //   }
  // }
  // position: relative;
  // &:hover img {
  //   transform: scale(1.1);
  //   outline: 3px solid #000000;
  // }
}
.upload {
  width: 100%;
  display: block;
  background: var(--color-background);
  border-radius: 8px;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-default);
  cursor: default;
  &:hover {
    background: var(--color-primary-light);
  }
  &--done {
    background: none;
    &:hover {
      background: none;
    }
  }
}
</style>
