<template>
  <div>
    <b-modal id="shortcuts" hide-footer header-border-variant="0">
      <template #modal-header-close>
        <b-icon icon="x"></b-icon>
      </template>
      <div class="px-4">
        <div class="mb-5">
          <!-- <div class="text-center">
            <b-icon fontScale="2" icon="keyboard"></b-icon>
            <h3 class="text-center mt-2 mb-1">Keyboard Shortcuts</h3>
          </div> -->
          <div>
            <div class="d-flex align-items-center my-2">
              <div class="w-100">Search response</div>
              <div class="ml-auto">
                <code>{{ $store.getters.shortcutString }}</code>
              </div>
            </div>
            <div class="d-flex align-items-center my-2">
              <div class="w-100">Create response</div>
              <div class="ml-auto">
                <code>{{ $store.getters.shortcutNewResponseString }}</code>
              </div>
            </div>
            <div class="d-flex align-items-center my-2">
              <div class="w-100">Choose response</div>
              <div class="ml-auto">
                <code>Up/Down arrows</code>
              </div>
            </div>
            <div class="d-flex align-items-center my-2">
              <div class="w-100">Send to clipboard</div>
              <div class="ml-auto">
                <code>Enter</code>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
code {
  white-space: nowrap;
  display: block;
  background: var(--color-primary-light);
  padding: 5px 10px;
  font-family: inherit;
  border-radius: var(--border-radius);
}
</style>
