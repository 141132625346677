<template>
  <div class="position-relative bookmarkbtn">
    <b-button title="Add to bookmarks" v-show="!is_bookmarked" variant="hover" ref="out" @click.stop.prevent="toggleBookmark()">
      <b-icon icon="bookmark-plus"></b-icon>
    </b-button>
    <!-- <a href="#" v-show="!is_bookmarked" class="btn btn-hover text-dark" ref="out" @click.stop.prevent="toggleBookmark()">
    </a> -->
    <b-button title="Remove from bookmarks" v-show="is_bookmarked" variant="hover" ref="in" @click.stop.prevent="toggleBookmark()">
      <b-icon icon="bookmark-check-fill"></b-icon>
    </b-button>
    <!-- <a href="#"title="Add to bookmarks" v-show="is_bookmarked" class="text-warning" ref="in" @click.stop.prevent="toggleBookmark()">
      <b-icon icon="bookmark-check-fill"></b-icon>
    </a> -->
  </div>
</template>

<script>
import { post } from "@/common/api"

export default {
  props: {
    uuid: String,
    is_bookmarked: Boolean,
  },
  methods: {
    toggleBookmark() {
      let response = this.$store.state.responses.find(response => {
        return response.uuid == this.uuid
      })
      response.is_bookmarked = !response.is_bookmarked
      if (response.is_bookmarked) {
        this.$refs.out.classList.add("popout")
        this.$refs.in.classList.add("popin")
        window.setTimeout(() => {
          this.$refs.out.classList.remove("popout")
          this.$refs.in.classList.remove("popin")
        }, 1000)
        this.$bvToast.show("bookmark")
      } else {
        this.$refs.out.classList.remove("popout")
        this.$refs.in.classList.remove("popin")
      }
      this.$store.dispatch("response", response)
      post("response/bookmark", {
        response_id: this.uuid,
      }).catch(() => {
        // console.log(e)
        this.$root.$emit("error", "Unable to save bookmark. Please try again.")
        let response = this.$store.state.responses.find(response => {
          return response.uuid == this.uuid
        })
        response.is_bookmarked = !response.is_bookmarked
        this.$store.dispatch("response", response)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.popout {
  animation: popout 0.5s;
  position: absolute;
  left: 0;
  top: 0;
}
.bookmarkbtn {
  // width: 16px;
  // height: 16px;
  svg {
    display: block;
  }
}
.popin {
  svg {
    animation: popin 0.5s;
    transition-delay: 0.3s;
    // position: absolute;
    // left: 0;
    // top: 0;
  }
  &:after {
    content: "";
    display: block;
    transition-delay: -100ms;
    width: 70px;
    height: 70px;
    border-radius: 50px;
    border: 2px solid var(--color-warning);
    position: absolute;
    left: calc(50% - 35px);
    top: calc(50% - 35px);
    animation: inflate 0.8s linear forwards;
  }
}

@keyframes popout {
  to {
    opacity: 0;
    transform: scale(2);
  }
}
@keyframes inflate {
  from {
    opacity: 0;
    transform: scale(0);
  }
  10% {
    opacity: 1;
    transform: scale(0.5);
  }
  50% {
    opacity: 0;
    transform: scale(1);
  }
  to {
    opacity: 0;
  }
}
@keyframes popin {
  from {
    opacity: 0;
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: scale(1.2);
  }
  75% {
    transform: scale(0.9);
  }
}
</style>