<template>
  <b-button :class="{ active: isBlockActive(format) }" @click="toggleBlock(format)" variant="hover">
    <b-icon :icon="icon"></b-icon>
  </b-button>
</template>

<script>
import { Editor, Transforms } from "slate"
import { SlateMixin } from "slate-vue"

export default {
  mixins: [SlateMixin],
  props: {
    format: String,
    icon: String,
    active: Boolean,
  },
  methods: {
    toggleBlock(format) {
      const LIST_TYPES = ["numbered-list", "bulleted-list"]
      const isList = LIST_TYPES.includes(format)
      const isActive = this.isBlockActive(format)

      let editor = this.$editor
      Transforms.unwrapNodes(editor, {
        match: n => LIST_TYPES.includes(n.type),
        split: true,
      })

      Transforms.setNodes(editor, {
        type: isActive ? "paragraph" : isList ? "list-item" : format,
      })

      if (!isActive && isList) {
        const block = { type: format, children: [] }
        Transforms.wrapNodes(editor, block)
      }

      // if (this.isBlockActive(format)) {
      //   Transforms.setNodes(this.$editor, {
      //     type: "p",
      //   })
      // } else {
      //   Transforms.setNodes(this.$editor, {
      //     type: format,
      //   })
      // }
    },
    isBlockActive(format) {
      const [match] = Editor.nodes(this.$editor, {
        match: n => n.type === format,
      })
      return !!match
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../../assets/css/mixins";

.btn {
  @extend .px-2;
  .b-icon {
    font-size: 110% !important;
  }
  &.active {
    background: var(--color-primary-light) !important;
    border-color: var(--color-primary) !important;
    color: var(--color-primary) !important;
  }
}
</style>
