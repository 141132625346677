<template>
  <div>
    <b-modal id="foldersharemodal" hide-footer header-border-variant="0">
      <template #modal-header-close>
        <b-icon icon="x"></b-icon>
      </template>
      <div v-if="folder" class="px-5 pb-4">
        <!-- <transition mode="out-in" name="fade" duration="200"> -->
        <div key="done" v-if="done">
          <b-icon icon="arrow-left" class="cursor-pointer" fontScale="1.2" @click="done = false"></b-icon>
          <div class="d-flex flex-column align-items-center justify-content-center py-5">
            <b-icon icon="mailbox" fontScale="3" class="mb-3"></b-icon>
            <p v-if="sentEmails.length >= 2" class="text-center">
              Your invitations have been sent by email to {{ sentEmailsString }}.
              <b>Once they accept the invitation, they'll be able to access this folder.</b>
            </p>
            <p v-else class="text-center">
              Your invitation has been sent by email to {{ sentEmailsString }}.
              <b>Once he/she accepts the invitation, he/she'll be able to access this folder.</b>
            </p>
          </div>
        </div>
        <div key="form" v-else>
          <div class="text-center mb-5">
            <b-icon fontScale="1.2" icon="folder-plus"></b-icon>
            <h3 class="text-center mt-2 mb-1">Share "{{ folder.name }}"</h3>
            <p class="mt-0">Share the folder and its responses. Enter an email address below.</p>
          </div>
          <section class="mb-5">
            <b-form @submit.stop.prevent="">
              <p class="text-danger text-center my-4" v-if="error">{{ error }}</p>
              <!-- <b-form-tags
                  invalid-tag-text="You must enter a valid email address"
                  input-id="tags-remove-on-delete"
                  v-model="emails"
                  :tag-validator="validateEmail"
                  separator=" "
                  placeholder="Enter emails separated by space"
                  remove-on-delete
                  class="mb-3"
                >
                </b-form-tags> -->
              <!-- <b-form-tags v-model="emails" :tag-validator="validateEmail" separator=" " class="mb-2" add-on-change>
                  <template v-slot="{ tags, inputAttrs, inputHandlers, addTag, removeTag }">
                    <div class="d-flex flex-wrap align-items-center">
                      <span class="tag d-flex small flex-nowrap align-items-center bg-primary-light py-2 px-3 mr-2 my-1 rounded" v-for="tag in tags" :key="tag">
                        {{ tag }}
                        <b-button @click="removeTag(tag)" size="sm" class="p-0 ml-2">
                          <b-icon icon="x"></b-icon>
                        </b-button>
                      </span>
                      <input v-bind="inputAttrs" v-on="inputHandlers" placeholder="Add email" class="form-control border-0 p-0" />
                    </div>
                  </template>
                </b-form-tags> -->
              <!-- <b-input required type="email" v-model="email" class="mb-4" placeholder="john@doe.com"></b-input> -->
              <section class="row" v-show="suggestedUsers.length">
                <div :key="user.email" v-for="user in suggestedUsers" class="col-6 px-1">
                  <b-button
                    :title="user.email"
                    @click="quickInvite(user.email)"
                    class="d-flex small flex-nowrap align-items-center bg-primary-light py-1 px-2 my-1 mr-1 rounded mw-100 text-truncate"
                    :disabled="hasBeenInvited(user.email)"
                  >
                    <span class="d-block text-truncate">
                      {{ user.email }}
                    </span>
                    <b-icon icon="plus" class="ml-2"></b-icon>
                  </b-button>
                </div>
                <div class="col-12 my-3 text-center">
                  <p class="m-0 text-center text-default-lighter"><em>or</em></p>
                </div>
              </section>
              <b-form-input type="email" class="mb-3" v-model="email" @blur="quickInvite(email)" placeholder="Enter email and press Enter" @change="quickInvite"></b-form-input>

              <!-- <datalist id="suggestions">
                  <option :key="user.email" v-for="user in suggestedUsers">{{ user.email }}</option>
                </datalist> -->

              <section v-show="emails.length">
                <div class="border p-4 rounded mb-4">
                  <div class="d-flex flex-wrap mb-2">
                    <div v-for="email in emails" :key="email">
                      <b-button @click="removeEmail(email)" class="d-flex small flex-nowrap align-items-center bg-primary-light py-1 px-2 my-1 mr-1 rounded mw-100 text-truncate" :title="email">
                        <span class="d-block text-truncate">
                          {{ email }}
                        </span>
                        <b-icon icon="x" class="ml-2"></b-icon>
                      </b-button>
                    </div>
                  </div>
                  <section>
                    <hr class="border-top border-border" />

                    <Toggle v-if="!folder.team" v-model="edit">Can edit responses within that folder</Toggle>
                    <Toggle disabled v-else :value="true">Can edit responses within that folder</Toggle>
                  </section>
                </div>
                <b-button @click="onSubmit" :disabled="loading || !emails || emails.length == 0" variant="primary" size="lg" block>
                  <img v-show="loading" width="16" src="@/common/assets/img/loader_white.svg" alt="" />
                  <span v-show="!loading" v-text="buttonCaption">Send invitation</span>
                </b-button>
              </section>
              <hr class="border-top my-4" />
              <p><b>Current Users</b></p>
              <section v-if="(folder.users && folder.users.length) || (folder.invitations && folder.invitations.length)">
                <UserLine :user="user" type="user" :key="user.uuid" v-for="user in folder.users">
                  <div class="col-auto d-flex justify-content-end">
                    <b-dropdown v-if="user.pivot.role !== 'owner'" class="ml-100" toggle-tag="span" variant="hover" toggle-class="text-decoration-none border-0 rounded p-0" no-caret>
                      <template #button-content>
                        <span class="d-block px-1 p-0">
                          <b-icon icon="three-dots"></b-icon>
                        </span>
                      </template>
                      <b-dropdown-item @click="editRole(user, 'guest')" v-if="user.pivot.role == 'editor' && folder.team == false"><span>Remove edit permissions</span></b-dropdown-item>
                      <b-dropdown-item @click="editRole(user, 'editor')" v-else-if="folder.team == false"><span>Give edit permissions</span></b-dropdown-item>
                      <b-dropdown-item @click="revokeAccess(user)"><span class="text-danger">Revoke access</span></b-dropdown-item>
                    </b-dropdown>
                  </div>
                </UserLine>
                <UserLine :key="invitation.uuid" :user="invitation" type="invitation" v-for="invitation in folder.invitations">
                  <div class="col d-flex justify-content-end">
                    <b-dropdown class="ml-100" toggle-tag="span" variant="hover" toggle-class="text-decoration-none border-0 rounded p-0" no-caret>
                      <template #button-content>
                        <span class="d-block px-1 p-0">
                          <b-icon icon="three-dots"></b-icon>
                        </span>
                      </template>
                      <b-dropdown-item @click="deleteInvitation(invitation)"><span class="text-danger">Cancel invitation</span></b-dropdown-item>
                    </b-dropdown>
                  </div>
                </UserLine>
              </section>
              <section class="text-center py-5 text-muted" v-else>You're not sharing this folder with anyone.</section>
            </b-form>
          </section>
        </div>
        <!-- </transition> -->
      </div>
    </b-modal>
  </div>
</template>

<script>
import Toggle from "@/common/components/Toggle"
import { post, get } from "@/common/api"
import UserLine from "@/common/components/UserLine"

export default {
  data() {
    return {
      folder: null,
      loading: false,
      email: null,
      emails: [],
      sentEmails: [],
      edit: false,
      error: null,
      done: false,
    }
  },
  components: {
    Toggle,
    UserLine,
  },
  computed: {
    sentEmailsString() {
      let emails = this.sentEmails
      let str = ""
      while (emails.length > 2) {
        str += emails.pop() + ", "
      }
      while (emails.length > 1) {
        str += emails.pop() + " & "
      }
      while (emails.length > 0) {
        str += emails.pop()
      }
      return str
    },
    buttonCaption() {
      let str = "Send " + this.emails.length + " invitation"
      if (this.emails && this.emails.length > 1) str += "s"
      return str
    },
    suggestedUsers() {
      let currentEmails = []
      let users = []
      this.folder.users.map(e => currentEmails.push(e.email))
      this.folder.invitations.map(e => currentEmails.push(e.email))
      if (this.$store.state.user.subscription) {
        users = this.$store.state.user.subscription.users.map(u => u.user)
        users = users.filter(e => e.email !== this.$store.state.user.email)
        // users = users.concat(this.$store.state.user.subscription.pending_invitations.filter(e => e.uuid !== this.$store.state.user.uuid))
        let uniqueUsers = []
        users.forEach(u => {
          if (!uniqueUsers.some(e => e.email == u.email)) {
            uniqueUsers.push(u)
          }
        })
        users = uniqueUsers
      }
      if (this.$store.state.user.parent_subscription) {
        users = this.$store.state.user.parent_subscription.subscription.users.map(u => u.user)
        users = users.filter(e => e.uuid !== this.$store.state.user.uuid)
      }
      return users
      // return users.filter(e => {
      //   return currentEmails.indexOf(e.email) < 0 && this.emails && this.emails.indexOf(e.email) < 0
      // })
    },
  },
  methods: {
    hasBeenInvited(e) {
      return Boolean(this.emails.find(row => row == e)) || Boolean(this.folder.invitations.find(row => row.email == e))
    },
    quickInvite(email) {
      if (this.emails.indexOf(email) < 0) {
        if (email && email.length) {
          this.emails.push(email)
          this.email = null
        }
      }
    },
    removeEmail(email) {
      this.emails = this.emails.filter(e => {
        return e !== email
      })
    },
    revokeAccess(user) {
      let folder = this.folder
      folder.users = folder.users.filter(e => {
        return !Object.is(e, user)
      })
      this.$store.dispatch("folder", folder)
      post("folder/revoke", {
        folder_id: this.folder.uuid,
        uuid: user.uuid,
      })
        .then(() => {
          this.error = null
        })
        .catch(e => {
          if (folder.users) {
            folder.users.unshift(user)
          } else {
            folder.users = [user]
          }
          this.$store.dispatch("folder", folder)
          this.error = e.response.data.message || e
        })
    },
    editRole(user, role) {
      let oldRole = this.folder.users.find(e => {
        return Object.is(e, user)
      }).pivot.role
      this.folder.users.find(e => {
        return Object.is(e, user)
      }).pivot.role = role
      this.$store.dispatch("folder", this.folder)
      post("folder/editrole", {
        folder_id: this.folder.uuid,
        uuid: user.uuid,
        role,
      })
        .then(() => {
          this.error = null
        })
        .catch(e => {
          this.folder.users.find(e => {
            return Object.is(e, user)
          }).pivot.role = oldRole
          this.$store.dispatch("folder", this.folder)
          this.error = e.response.data.message || e
        })
    },
    deleteInvitation(invitation) {
      let folder = this.folder
      folder.invitations = folder.invitations.filter(e => {
        return !Object.is(e, invitation)
      })
      this.$store.dispatch("folder", folder)
      post("folder/invitation/cancel", {
        folder_id: this.folder.uuid,
        uuid: invitation.uuid,
      })
        .then(() => {
          this.error = null
        })
        .catch(e => {
          if (folder.invitations) {
            folder.invitations.unshift(invitation)
          } else {
            folder.invitations = [invitation]
          }
          this.$store.dispatch("folder", folder)
          // console.log(e.response)
          this.error = e.response.data.message || e
        })
    },
    validateEmail(val) {
      return val.indexOf("@") >= 0 && val.indexOf(".") >= 0
    },
    onSubmit() {
      this.error = null
      const { emails, edit } = this
      emails.forEach(e => {
        if (this.folder.invitations.find(row => row.email == e)) {
          this.error = e + " is already in this folder. Please remove this email to send invitations."
          return false
        }
      })
      if (!this.error) {
        this.loading = true
        // this.$gtm.trackEvent({
        //   event: "sendInvitation", // Event type [default = 'interaction'] (Optional)
        //   client_id: process.env.VUE_APP_CLIENT_ID,
        //   user: this.$store.state.user.uuid,
        // })
        post("folder/invitation/send", {
          folder_id: this.folder.uuid,
          emails,
          edit,
        })
          .then(ret => {
            get("me").then(ret => {
              let user = this.$store.state.user
              this.$store.dispatch("user", Object.assign(user, ret.data))
            })
            let folder = this.folder
            if (folder.invitations) {
              ret.data.map(i => folder.invitations.unshift(i))
            } else {
              folder.invitations = ret.data
            }
            this.$store.dispatch("folder", folder)
            this.done = true
            this.sentEmails = emails
            this.emails = []
            this.edit = false
            this.error = null
            this.loading = false
          })
          .catch(e => {
            this.loading = false
            // console.log(e)
            if (e.response.data && e.response.data.errors) {
              this.error = e.response.data.errors[Object.keys(e.response.data.errors)[0]][0]
            } else if (e.response.data) {
              this.error = e.response.data.message
            } else {
              this.error = e
            }
          })
      }
    },
    showFolder(folder) {
      this.folder = folder
      this.$bvModal.show("foldersharemodal")
    },
    isDone(bvEvent, modalId) {
      if (modalId == "foldersharemodal") {
        this.done = false
      }
    },
  },
  created() {
    this.$root.$on("showFolderModal", this.showFolder)
    this.$root.$on("bv::modal::hidden", this.isDone)
  },
  beforeDestroy() {
    this.$root.$off("showFolderModal", this.showFolder)
    this.$root.$off("bv::modal::hidden", this.isDone)
  },
}
</script>
<style lang="scss" scoped>
::v-deep .b-form-tags input:focus {
  outline: none;
  height: auto;
  box-shadow: none;
}
::v-deep .tag:last-of-type {
  margin-bottom: 10px !important;
}
::v-deep .b-form-tags input {
  outline: none;
  height: auto;
  box-shadow: none;
  width: 100%;
}
</style>
